import { SvgIcon } from '@mui/material';
import React from 'react';

export function IncognitoMale() {
  return (
    <SvgIcon
      sx={{ width: '100%', height: '100%' }}
      viewBox="0 0 160 160"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="160" height="160" rx="18" fill="#381F7D" />
      <mask
        id="mask0_2443_5173"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="160"
        height="160"
      >
        <rect width="160" height="160" rx="18" fill="white" />
      </mask>
      <g mask="url(#mask0_2443_5173)">
        <g clipPath="url(#clip0_2443_5173)">
          <path
            d="M152 176.948C148.17 180.122 144.09 183.001 139.805 185.585C137.935 186.718 136.044 187.807 134.106 188.827C133.559 189.121 133.012 189.393 132.442 189.665C118.058 196.92 101.76 201 84.5268 201C78.6457 201 72.8786 200.524 67.2482 199.594C63.2135 198.96 59.27 198.076 55.3948 196.988C42.5612 193.36 30.7306 187.399 20.3817 179.6C17.897 177.719 15.5036 175.724 13.1785 173.638C9.28054 170.147 5.63334 166.339 2.30527 162.304C1.16552 160.921 0.0485697 159.493 -1 158.042C6.06645 147.274 17.9882 133.537 35.8139 126.419C40.7604 124.469 46.0717 123.018 51.8616 122.27C52.2035 122.225 52.5226 122.179 52.8646 122.157C53.3889 122.089 53.9131 122.043 54.4146 121.998C54.4602 121.975 54.483 121.975 54.5286 121.975C54.9845 121.93 55.4176 121.907 55.8507 121.885C56.5346 121.839 57.1956 121.794 57.7883 121.771C59.2244 121.658 60.6833 121.545 62.0737 121.499C101.646 119.981 122.708 135.6 123.939 136.529C124.053 136.597 124.122 136.688 124.19 136.778C124.578 137.209 125.535 138.229 126.925 139.839C132.1 145.778 143.11 159.402 152 176.948Z"
            fill="#8464DD"
          />
          <path
            d="M69.3686 68.2949C69.3686 68.2949 78.327 66.096 82.5441 60.9501C86.7384 55.8269 87.445 43.1322 87.445 43.1322C87.445 43.1322 103.675 51.5197 107.938 50.5903C112.2 49.6609 120.566 30.6415 114.936 22.4353C109.305 14.2291 97.9079 24.3848 97.9079 24.3848C97.9079 24.3848 95.5601 12.6649 83.114 15.3852C68.4796 18.6042 64.6956 33.3618 64.6956 33.3618C64.6956 33.3618 63.7155 17.3347 53.5261 24.3622C43.3595 31.3896 47.0067 49.7742 47.0067 49.7742C47.0067 49.7742 38.8689 53.2879 40.4874 60.9047C42.1058 68.5216 53.1158 75.8664 53.1158 75.8664L69.3686 68.2949Z"
            fill="#8464DD"
          />
          <path
            d="M67.9318 91.3042L65.7891 115.469C65.7891 115.469 69.7098 122.792 84.3442 125.127C95.2402 126.872 99.2977 124.809 99.2977 124.809L98.8418 112.454L98.3859 100.395L72.3084 92.619L67.9318 91.3042Z"
            fill="#E2E5FF"
          />
          <path
            d="M72.3086 92.6191C72.3086 93.2765 72.5137 94.0926 72.8329 95.0221C77.3919 107.558 92.5733 111.412 98.842 112.477L98.3861 100.417L72.3086 92.6191Z"
            fill="#4520AB"
          />
          <path
            d="M108.871 45.7618C108.871 45.7618 119.403 77.1359 112.724 93.5483C106.022 109.961 90.2935 105.767 81.0387 101.573C71.784 97.3794 67.9316 91.304 67.9316 91.304C67.9316 91.304 58.6768 93.3669 53.5252 84.7753C48.3735 76.2064 53.0237 67.3655 58.3577 65.756C63.6917 64.1465 69.3905 68.2722 69.3905 68.2722L65.5381 59.0686C65.5153 59.0912 90.2023 16.632 108.871 45.7618Z"
            fill="#E2E5FF"
          />
          <path
            d="M67.3845 81.4432C67.1338 81.4432 66.8602 81.3525 66.6551 81.1938L56.0098 72.4435C55.5311 72.0355 55.4628 71.3327 55.8503 70.8567C56.2378 70.3806 56.9672 70.3126 57.4459 70.698L68.0912 79.4483C68.5699 79.8563 68.6383 80.5591 68.2507 81.0351C68.0228 81.2845 67.7037 81.4432 67.3845 81.4432Z"
            fill="#4520AB"
          />
          <path
            d="M86.7381 56.9605C86.4646 56.9605 86.1683 56.8472 85.9631 56.6431C85.5072 56.2124 85.4844 55.487 85.9175 55.0336C89.7471 51.0212 94.3061 52.1773 96.1753 53.3561C96.6996 53.6961 96.8591 54.3989 96.5172 54.9203C96.1753 55.4417 95.4686 55.6004 94.9443 55.283C94.7848 55.1923 91.0464 52.9481 87.5588 56.5978C87.3536 56.8472 87.0345 56.9605 86.7381 56.9605Z"
            fill="#4520AB"
          />
          <path
            d="M111.243 57.8218C110.969 57.8218 110.673 57.7085 110.468 57.5045C108.074 55.2376 105.202 56.4844 105.088 56.5297C104.518 56.7791 103.834 56.5297 103.584 55.963C103.333 55.3963 103.561 54.7389 104.131 54.4668C104.313 54.3761 108.484 52.5173 112.04 55.8496C112.496 56.2804 112.519 57.0058 112.086 57.4591C111.858 57.6858 111.562 57.8218 111.243 57.8218Z"
            fill="#4520AB"
          />
          <path
            d="M104.974 80.4684C104.837 80.4684 104.677 80.4458 104.541 80.3778C103.948 80.1511 103.674 79.471 103.925 78.9043C107.185 70.9021 101.577 68.0004 101.349 67.8644C100.779 67.5924 100.552 66.9123 100.848 66.3456C101.121 65.7789 101.805 65.5522 102.375 65.8242C102.443 65.8695 110.148 69.746 106.068 79.743C105.863 80.1964 105.43 80.4684 104.974 80.4684Z"
            fill="#4520AB"
          />
          <path
            d="M110.103 69.406C109.556 69.406 109.1 69.0206 108.986 68.4766L107.892 62.8093C107.778 62.1972 108.188 61.6078 108.804 61.4945C109.442 61.3811 110.012 61.7892 110.126 62.4013L111.22 68.0685C111.334 68.6806 110.924 69.27 110.308 69.3833C110.263 69.406 110.194 69.406 110.103 69.406Z"
            fill="#4520AB"
          />
          <path
            d="M91.8893 68.4082C91.3422 68.4082 90.8863 68.0228 90.7724 67.4787L89.6782 61.8115C89.5642 61.1994 89.9745 60.61 90.59 60.474C91.2283 60.3606 91.7981 60.7687 91.9121 61.3808L93.0063 67.048C93.1202 67.6601 92.7099 68.2495 92.0945 68.3628C92.0261 68.4082 91.9577 68.4082 91.8893 68.4082Z"
            fill="#4520AB"
          />
          <path
            d="M97.8846 89.5816C97.3603 89.5816 91.16 89.4456 88.9489 83.6876C88.721 83.0982 89.0173 82.4408 89.61 82.2141C90.2027 81.9874 90.8637 82.2821 91.0917 82.8715C92.7785 87.2693 97.7022 87.2693 97.9074 87.3147C98.5456 87.3147 99.0471 87.8134 99.0471 88.4481C99.0471 89.0829 98.5456 89.5816 97.9302 89.5816C97.9074 89.5816 97.8846 89.5816 97.8846 89.5816Z"
            fill="#4520AB"
          />
          <path
            d="M69.3685 68.295C69.3685 68.295 78.988 66.6402 82.544 58.8193C86.1001 50.9985 85.5758 43.9711 85.5758 43.9711C85.5758 43.9711 90.4311 49.253 98.8425 48.3689C107.254 47.4848 109.579 38.8932 109.579 38.8932L101.874 29.8936C101.874 29.8936 70.1892 36.5356 69.3913 35.5608C68.5935 34.5861 57.082 55.0336 57.082 55.0336L69.3685 68.295Z"
            fill="#8464DD"
          />
          <path
            d="M132.442 189.665C118.058 196.919 101.76 201 84.5269 201C78.6458 201 72.8787 200.524 67.2483 199.594C63.2136 198.96 59.2701 198.075 55.3949 196.987C42.5614 193.36 30.7308 187.398 20.3818 179.6C24.8269 152.692 29.5226 130.408 33.1698 127.937C33.9904 127.393 34.8794 126.872 35.814 126.418C40.6922 124.038 46.7784 122.859 51.8617 122.27C52.2036 122.225 52.5228 122.179 52.8647 122.157C53.389 122.089 53.9133 122.043 54.4148 121.998C54.8935 121.953 55.3949 121.93 55.8508 121.885C56.5347 121.839 57.1957 121.794 57.7884 121.771C60.387 121.635 62.0511 121.658 62.0511 121.658C62.0511 121.658 62.0511 121.613 62.0739 121.499C62.1879 120.003 63.2136 108.306 66.4049 108.555C69.8014 108.85 78.0304 114.472 86.7381 115.175C95.4457 115.877 100.119 115.107 101.851 115.107C103.606 115.107 103.971 124.378 103.971 124.378C103.971 124.378 115.642 128.323 123.939 136.529C124.053 136.597 124.122 136.688 124.19 136.778C124.578 137.209 125.535 138.229 126.926 139.839C127.974 141.153 128.886 142.536 129.661 144.032C132.1 148.748 132.693 167.472 132.442 189.665Z"
            fill="#8464DD"
          />
          <path
            d="M80.5152 126.645C72.4457 126.645 62.1652 122.905 61.6637 122.724C61.071 122.497 60.7747 121.862 60.9799 121.273C61.2078 120.683 61.8461 120.389 62.4387 120.593C62.5755 120.638 76.64 125.739 84.0712 124.016C84.6867 123.88 85.3021 124.243 85.4389 124.855C85.5757 125.467 85.2109 126.079 84.5955 126.215C83.3645 126.532 81.974 126.645 80.5152 126.645Z"
            fill="#E2E5FF"
          />
          <path
            d="M57.059 81.2164C56.6487 81.2164 56.2611 80.9897 56.056 80.627C55.7597 80.0829 55.9648 79.3802 56.5347 79.0855L62.6437 75.8438C63.1908 75.5491 63.8975 75.7531 64.1938 76.3199C64.4901 76.8639 64.285 77.5667 63.7151 77.8614L57.606 81.103C57.4237 81.1711 57.2413 81.2164 57.059 81.2164Z"
            fill="#4520AB"
          />
          <path
            d="M93.1893 74.869H87.7641C84.6868 74.869 82.2021 72.3981 82.2021 69.3377V63.7385C82.2021 62.605 83.114 61.6982 84.2537 61.6982H96.6998C97.8395 61.6982 98.7513 62.605 98.7513 63.7385V69.3377C98.7741 72.3981 96.2667 74.869 93.1893 74.869Z"
            fill="#4520AB"
          />
          <path
            d="M116.439 75.141H111.014C107.937 75.141 105.452 72.67 105.452 69.6097V64.0104C105.452 62.877 106.364 61.9702 107.504 61.9702H119.95C121.09 61.9702 122.001 62.877 122.001 64.0104V69.6097C122.024 72.67 119.517 75.141 116.439 75.141Z"
            fill="#4520AB"
          />
          <path
            d="M98.7734 70.4714C98.477 70.4714 98.1807 70.3581 97.9528 70.1314C97.5197 69.678 97.5197 68.9753 97.9528 68.5446C98.1123 68.3859 101.828 64.8041 108.165 66.187C108.78 66.323 109.168 66.935 109.031 67.5471C108.894 68.1592 108.279 68.5445 107.663 68.4085C102.58 67.2977 99.6624 70.0407 99.5484 70.1541C99.3433 70.3581 99.0469 70.4714 98.7734 70.4714Z"
            fill="#4520AB"
          />
          <path
            d="M62.0507 65.6654C61.5264 65.6654 61.0477 65.3027 60.9337 64.7813C60.7969 64.1693 61.1844 63.5572 61.7999 63.4212C75.8188 60.2929 84.367 63.2398 84.7089 63.3758C85.3015 63.5799 85.5979 64.2373 85.3927 64.8267C85.1876 65.4161 84.5265 65.7334 83.9338 65.5067C83.8427 65.4841 75.5909 62.6504 62.2786 65.6428C62.2102 65.6654 62.1418 65.6654 62.0507 65.6654Z"
            fill="#4520AB"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_2443_5173">
          <rect
            width="153"
            height="186"
            fill="white"
            transform="translate(-1 15)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export function IncognitoFemale() {
  return (
    <SvgIcon
      sx={{ width: '100%', height: '100%' }}
      // width="160"
      // height="160"
      viewBox="0 0 160 160"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="160" height="160" rx="18" fill="#381F7D" />
      <mask
        id="mask0_2443_5174"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="160"
        height="160"
      >
        <rect width="160" height="160" rx="18" fill="white" />
      </mask>
      <g mask="url(#mask0_2443_5174)">
        <g clipPath="url(#clip0_2443_5174)">
          <path
            d="M52.8955 29.8642C52.8955 29.8642 79.8838 5.62652 105.253 19.0845C130.645 32.5424 123.438 64.9039 123.438 64.9039C123.438 64.9039 154.24 71.1084 161.248 109.451C168.256 147.794 132.264 157.659 132.264 157.659L77.6662 130.029L52.8955 29.8642Z"
            fill="#8464DD"
          />
          <path
            d="M104.145 190.578L103.169 202.831C97.0484 204.393 90.7061 205.397 84.2085 205.799C83.6762 205.844 83.1218 205.866 82.5896 205.888C80.9929 205.955 79.3741 206 77.7552 206C76.9569 206 76.1364 206 75.3158 205.978C63.1855 205.687 51.5652 203.322 40.832 199.17C37.3725 197.854 34.0018 196.336 30.7197 194.64C26.5949 192.52 22.6254 190.132 18.8555 187.476C23.335 162.725 32.4938 120.699 44.2915 113.825C45.2007 113.29 46.0877 112.955 46.997 112.866C65.3366 110.924 81.5252 113.379 81.5252 113.379L104.145 190.578Z"
            fill="#8464DD"
          />
          <path
            d="M146.257 179.843C143.241 182.566 140.048 185.088 136.699 187.431C131.843 190.823 126.676 193.836 121.243 196.358C119.934 196.983 118.626 197.563 117.295 198.121C112.727 200.018 108.026 201.603 103.169 202.831C97.0486 204.393 90.7062 205.397 84.2086 205.799C82.5454 156.743 78.5537 114.852 78.5537 114.852L46.9971 112.866C51.5653 111.281 54.6257 110.388 54.6257 110.388L74.1185 110.656C74.1185 110.656 105.187 114.584 124.458 125.788C124.547 125.855 124.658 125.899 124.791 125.989C127.674 127.663 130.246 129.493 132.442 131.524C132.464 131.546 132.508 131.568 132.53 131.613C137.01 135.786 141.844 154.266 146.257 179.843Z"
            fill="#6041B4"
          />
          <path
            d="M46.9522 112.888C46.3978 113.625 37.3943 126.101 31.806 184.597C31.5177 187.565 31.1407 190.913 30.7193 194.64C26.5946 192.52 22.625 190.132 18.8551 187.476C17.6576 186.65 16.4823 185.802 15.3513 184.909C13.5772 183.57 11.8475 182.164 10.1842 180.713C9.7629 180.356 9.34155 179.977 8.94238 179.598C7.30135 178.125 5.70467 176.607 4.15234 175.045C5.6825 153.574 7.16829 138.443 8.47669 135.385C9.89596 132.127 13.4663 128.913 18.0346 125.967C18.0789 125.922 18.1233 125.9 18.1676 125.877C25.9293 120.878 36.5295 116.593 44.2911 113.826C45.2447 113.491 46.1317 113.178 46.9522 112.888Z"
            fill="#6041B4"
          />
          <path
            d="M32.9147 76.3752C32.9147 76.3752 23.8446 67.3363 25.4857 52.4499C26.6167 42.3621 30.8523 38.8804 30.8523 38.8804C30.8523 38.8804 23.3789 25.601 43.4483 25.3109C63.5399 25.0208 75.8698 34.2605 75.8698 34.2605C75.8698 34.2605 84.7846 29.2166 89.4859 39.7508C94.1873 50.2628 90.9496 66.3542 90.9496 66.3542L32.9147 76.3752Z"
            fill="#8464DD"
          />
          <path
            d="M77.822 90.5474L78.4429 117.285C78.4429 117.285 74.3403 122.418 60.0589 124.025C49.4144 125.23 45.8662 119.807 45.8662 119.807L47.7955 98.1579L73.4976 91.641L77.822 90.5474Z"
            fill="#E2E5FF"
          />
          <path
            d="M77.7331 89.0522C77.6887 89.7218 77.467 90.5252 77.09 91.4626C71.9673 103.939 52.8515 109.652 46.6865 110.411L47.7953 98.1804L77.7331 89.0522Z"
            fill="#4520AB"
          />
          <path
            d="M40.6547 42.1388C40.6547 42.1388 28.7239 73.4514 34.3123 90.4579C39.9007 107.464 55.3796 104.05 64.5605 100.278C73.7414 96.5062 77.8218 90.5472 77.8218 90.5472C77.8218 90.5472 86.6701 93.1138 92.1476 84.6998C97.6029 76.2635 93.5891 67.046 88.5107 65.1267C83.4324 63.2073 77.6666 67.0907 77.6666 67.0907L81.9023 57.9625C81.9244 57.9848 60.3249 13.5491 40.6547 42.1388Z"
            fill="#E2E5FF"
          />
          <path
            d="M77.6884 67.1133C77.6884 67.1133 64.7376 70.5056 54.8249 56.3335C44.9122 42.1614 45.4222 36.0908 45.4222 36.0908L37.1949 51.7583C37.1949 51.7583 34.5781 41.3579 38.3924 34.4393C42.2067 27.4983 46.6197 28.6588 46.6197 28.6588L75.9144 34.3054L84.807 57.2039L77.6884 67.1133Z"
            fill="#8464DD"
          />
          <path
            d="M43.7815 81.196C43.338 81.196 42.9166 80.9282 42.7392 80.4595C40.3664 73.9426 44.6464 66.9792 44.8238 66.6891C45.1564 66.1758 45.8217 66.0196 46.3539 66.332C46.864 66.6668 47.0192 67.3587 46.7087 67.872C46.6644 67.9389 42.8279 74.2104 44.8238 79.7007C45.0234 80.281 44.7351 80.9282 44.1585 81.129C44.0476 81.1737 43.9146 81.196 43.7815 81.196Z"
            fill="#4520AB"
          />
          <path
            d="M79.6624 81.5753C79.4185 81.5753 79.1524 81.4861 78.9528 81.3075C78.4871 80.9058 78.4206 80.2139 78.8197 79.7452L86.6479 70.4831C87.0471 70.0145 87.7345 69.9475 88.2002 70.3492C88.6659 70.751 88.7324 71.4428 88.3333 71.9338L80.5051 81.1959C80.3055 81.4414 79.995 81.5753 79.6624 81.5753Z"
            fill="#4520AB"
          />
          <path
            d="M88.3106 78.741C88.1997 78.741 82.4339 78.1607 82.4339 78.1607C81.8352 78.0937 81.3695 77.5581 81.436 76.9332C81.5026 76.3306 82.057 75.8842 82.6557 75.9289L88.4215 76.5091C89.0203 76.5761 89.486 77.1117 89.4194 77.7366C89.3529 78.3169 88.8872 78.741 88.3106 78.741Z"
            fill="#4520AB"
          />
          <path
            d="M36.5294 56.4003C36.0193 56.4003 35.5758 56.0432 35.4427 55.5299C35.3097 54.9273 35.6867 54.3247 36.2854 54.1908L41.652 52.941C42.2508 52.8071 42.8495 53.1865 42.9826 53.7668C43.1157 54.3694 42.7387 54.972 42.1399 55.1059L36.7733 56.3557C36.7068 56.4003 36.6181 56.4003 36.5294 56.4003Z"
            fill="#4520AB"
          />
          <path
            d="M59.2377 58.7438C59.0825 58.7438 58.9272 58.7214 58.7942 58.6545L49.6576 54.6149C49.1032 54.3694 48.8371 53.6998 49.081 53.1419C49.325 52.5839 49.9903 52.3161 50.5447 52.5616L59.659 56.6012C60.2134 56.8467 60.4796 57.5163 60.2356 58.0742C60.0582 58.4759 59.659 58.7438 59.2377 58.7438Z"
            fill="#4520AB"
          />
          <path
            d="M163 160.828C159.718 165.649 156.037 170.157 152 174.353C150.736 175.669 149.45 176.942 148.12 178.169C147.499 178.749 146.878 179.307 146.257 179.843C143.241 182.566 140.048 185.088 136.699 187.431C131.842 190.824 126.675 193.837 121.242 196.359L124.458 125.788C124.546 125.855 124.657 125.9 124.79 125.989C125.7 126.614 128.494 128.489 132.441 131.524C132.463 131.546 132.508 131.569 132.53 131.613C140.048 137.394 151.734 147.348 163 160.828Z"
            fill="#6041B4"
          />
          <path
            d="M57.2197 126.034C47.9944 126.034 44.6902 120.432 44.5571 120.164C44.2466 119.628 44.4462 118.937 44.9563 118.646C45.4885 118.334 46.1538 118.512 46.4643 119.048C46.5973 119.271 49.8572 124.561 59.282 123.69C68.3299 122.842 77.7326 116.437 77.8213 116.37C78.3313 116.013 79.0188 116.147 79.3736 116.66C79.7284 117.173 79.5954 117.865 79.0853 118.2C78.6861 118.468 69.1282 125.029 59.5038 125.922C58.7055 125.989 57.9515 126.034 57.2197 126.034Z"
            fill="#8464DD"
          />
          <path
            d="M15.3514 184.909C13.5774 183.57 11.8476 182.164 10.1844 180.714C9.76307 180.357 9.34172 179.977 8.94255 179.598C7.30152 178.125 5.70484 176.607 4.15251 175.045C3.26547 174.13 2.37842 173.192 1.51355 172.233C0.67086 171.295 -0.171832 170.358 -0.970172 169.398C-3.47607 166.452 -5.82674 163.35 -8 160.114C-2.89949 147.415 5.4609 132.506 18.0348 125.967C18.0791 125.922 18.1235 125.9 18.1678 125.878C18.2343 125.855 18.2787 125.811 18.3452 125.788C29.4333 120.142 21.4942 159.444 15.3514 184.909Z"
            fill="#6041B4"
          />
          <path
            d="M53.8269 86.5079C53.4499 86.5079 53.0951 86.3294 52.8955 85.9946C52.5628 85.4813 52.7181 84.7894 53.2503 84.4546C55.1131 83.2718 57.0646 81.397 57.0424 80.482C57.0202 79.8571 57.5081 79.3661 58.129 79.3438C58.75 79.3438 59.2378 79.8348 59.26 80.4373C59.3265 83.1155 55.2461 85.8384 54.4256 86.3517C54.226 86.4633 54.0265 86.5079 53.8269 86.5079Z"
            fill="#4520AB"
          />
          <path
            d="M54.492 74.7462H59.7699C62.7637 74.7462 65.1809 72.3135 65.1809 69.3006V63.7879C65.1809 62.672 64.2939 61.7793 63.1851 61.7793H51.0769C49.9681 61.7793 49.0811 62.672 49.0811 63.7879V69.3006C49.0811 72.3135 51.4983 74.7462 54.492 74.7462Z"
            fill="#4520AB"
          />
          <path
            d="M31.8729 75.0363H37.1508C40.1446 75.0363 42.5618 72.6036 42.5618 69.5906V64.078C42.5618 62.9621 41.6747 62.0693 40.5659 62.0693H28.4578C27.349 62.0693 26.4619 62.9621 26.4619 64.078V69.5906C26.4619 72.5813 28.8791 75.0363 31.8729 75.0363Z"
            fill="#4520AB"
          />
          <path
            d="M49.0812 70.4163C49.3694 70.4163 49.6577 70.3047 49.8795 70.0816C50.3008 69.6352 50.3008 68.9433 49.8795 68.5193C49.7243 68.363 46.1096 64.8368 39.9446 66.1982C39.3458 66.3321 38.9688 66.9347 39.1019 67.5373C39.235 68.1399 39.8337 68.5193 40.4325 68.3854C45.3777 67.2918 48.2163 69.9923 48.3272 70.1039C48.5046 70.3047 48.7929 70.4163 49.0812 70.4163Z"
            fill="#4520AB"
          />
          <path
            d="M84.7851 65.7072C85.2951 65.7072 85.7608 65.3501 85.8717 64.8367C86.0048 64.2341 85.6278 63.6315 85.029 63.4976C71.3907 60.4177 63.0747 63.3191 62.742 63.453C62.1654 63.6539 61.8771 64.3011 62.0767 64.8814C62.2763 65.4617 62.9194 65.7741 63.496 65.5509C63.5847 65.5286 71.6125 62.7388 84.5633 65.6848C84.6298 65.6848 84.7185 65.7072 84.7851 65.7072Z"
            fill="#4520AB"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_2443_5174">
          <rect
            width="171"
            height="191"
            fill="white"
            transform="translate(-8 15)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
