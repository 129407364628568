import { Box, Typography } from '@mui/material';
import React from 'react';

export default function PlatesListOffline({ list }) {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          padding: { xs: 2, lg: 3 },
          borderRadius: 6,
          background: '#E2E5FF',
        }}
      >
        {list?.slice(0, list.length - 1).map((item, index) => {
          return (
            <Box
              key={index}
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                gap: 2,
                flexShrink: '0',
                borderRadius: 6,
                background: '#E2E5FF',
              }}
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.2965 0L9.70346 0L9.15542 7.96103L3.13861 2.71925L2.71924 3.13858L7.96097 9.15543L0 9.70346L0 10.2965L7.96098 10.8446L2.71925 16.8614L3.13862 17.2807L9.15542 12.039L9.70346 20H10.2965L10.8446 12.039L16.8614 17.2807L17.2808 16.8614L12.039 10.8446L20 10.2965V9.70346L12.039 9.15536L17.2808 3.13858L16.8614 2.71925L10.8446 7.96103L10.2965 0Z"
                  fill="#4520AB"
                />
              </svg>

              <Typography
                variant="desktopP2"
                color={'kit.text.main'}
                fontSize={16}
              >
                {item}
              </Typography>
            </Box>
            // </Grid>
          );
        })}
      </Box>

      <Box
        sx={{
          display: 'flex',
          padding: { xs: 2, lg: 3 },
          alignItems: 'center',
          gap: 2,
          flexShrink: '0',
          borderRadius: 6,
          background: '#FFBBF0',
        }}
      >
        <Box height={44}>
          <svg
            width="44"
            height="44"
            viewBox="0 0 44 44"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              width="44"
              height="44"
              rx="14"
              fill="white"
              fillOpacity="0.8"
            />
            <path
              d="M21.9998 7.61536C14.0555 7.61536 7.61523 14.0556 7.61523 22C7.61523 29.9443 14.0555 36.3846 21.9998 36.3846C29.9442 36.3846 36.3845 29.9443 36.3845 22C36.3845 14.0556 29.9442 7.61536 21.9998 7.61536ZM21.9998 29.3543C21.2055 29.3543 20.5614 28.7101 20.5614 27.9158C20.5614 27.1215 21.2055 26.4773 21.9998 26.4773C22.7942 26.4773 23.4383 27.1215 23.4383 27.9158C23.4383 28.7101 22.7942 29.3543 21.9998 29.3543ZM23.4383 24.2535H20.5614V14.646H23.4383V24.2535Z"
              fill="#4520AB"
            />
          </svg>
        </Box>
        <Typography variant="desktopP2" color={'kit.text.main'} fontSize={16}>
          {list?.at(-1)}
        </Typography>
      </Box>
    </>
  );
}
