import React, { useRef } from 'react';
import EventMkOnlineInfoBlock from '../../../widgets/EventMkOnlineInfoBlock/EventMkOnlineInfoBlock';
import { CanBeInterest, EventStatistic } from '../../../widgets';
import EventMkOnlineSubscribe from '../../../widgets/EventMkOnlineSubscribe';
import { mkOnlineData as data } from '../model';
import { useInView } from 'react-intersection-observer';

export default function MkOnline({
  eventData,
  greetingsImgs,
  statistic,
  suggestEvents,
  setCurrentEvent,
}) {
  const [hideBlockRef, hideInView] = useInView({
    threshold: 0.1,
    delay: 1,
  });

  return (
    <>
      <EventMkOnlineInfoBlock
        externalHide={hideInView}
        eventData={eventData}
        greetingsImgs={greetingsImgs}
        data={data.mkOnlineIntroduce}
      />
      <EventStatistic {...statistic} />
      <EventMkOnlineSubscribe
        outsideRef={hideBlockRef}
        {...eventData}
        showSubscribe
        eventData={eventData}
      />
      <CanBeInterest
        leadData={data.canBeInterest.leadData}
        title={data.canBeInterest.title}
        description={data.canBeInterest.description}
        events={suggestEvents}
        setCurrentEvent={setCurrentEvent}
      />
    </>
  );
}
