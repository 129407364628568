import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import CalendarIcon from '../../kit/icons/CalendarIcon';
import ClockIcon from '../../kit/icons/ClockIcon';
import LocateIcon from '../../kit/icons/LocateIcon';

const styles = {
  infoItemWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  main: {
    display: 'flex',
    alignItems: {
      xs: 'flex-end',
      md: 'center',
    },
    marginRight: '16px',
  },
  infoItem: {
    marginRight: 0,
    marginTop: {
      xs: '5px',
      xl: '16px',
    },
  },
  icon: {
    flexShrink: 0,
    width: {
      xs: '24px',
      md: '28px',
    },
    height: {
      xs: '24px',
      md: '28px',
    },
    marginRight: {
      xs: '12px',
      md: '14px',
    },
    svg: {
      width: '100%',
      height: '100%',
    },
  },
};

const createIcon = (iconType, color) => {
  switch (iconType) {
    case 'calendar': {
      return <CalendarIcon color={color} />;
    }
    case 'clock': {
      return <ClockIcon color={color} />;
    }
    case 'location': {
      return <LocateIcon color={color} />;
    }
    default: {
      return <CalendarIcon color={color} />;
    }
  }
};

function DetailItem({ icon, item, description, colorTheme = {} }) {
  const theme = useTheme();

  return (
    <Box sx={styles.infoItemWrapper}>
      {item && (
        <>
          <Box sx={styles.main}>
            <Box sx={styles.icon}>
              {createIcon(icon, theme.getColor(colorTheme.iconColor))}
            </Box>
            <Typography
              component="span"
              variant="desktopH3"
              color={colorTheme.titleColor || 'kit.text.main'}
            >
              {item}
            </Typography>
          </Box>
          <Typography
            component="span"
            variant="desktopP1"
            color={colorTheme.subTitleColor || 'kit.text.secondary'}
            sx={styles.infoItem}
          >
            {description}
          </Typography>
        </>
      )}
    </Box>
  );
}

export default DetailItem;
