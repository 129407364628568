import React from 'react';
import { SvgIcon } from '@mui/material';

export default function ArrowDownIcon(props) {
  return (
    <SvgIcon sx={{ width: 16, height: 26 }} viewBox="0 0 16 26" {...props}>
      <path
        d="M7.29289 25.704C7.68342 26.0945 8.31658 26.0945 8.70711 25.704L15.0711 19.34C15.4616 18.9495 15.4616 18.3163 15.0711 17.9258C14.6805 17.5353 14.0474 17.5353 13.6569 17.9258L8 23.5827L2.34315 17.9258C1.95262 17.5353 1.31946 17.5353 0.928933 17.9258C0.538409 18.3163 0.538409 18.9495 0.928933 19.34L7.29289 25.704ZM7 0.996887L7 24.9969L9 24.9969L9 0.996887L7 0.996887Z"
        fill="#4520AB"
      />
    </SvgIcon>
  );
}
