import { capitalizeText } from '../../../helpers/lib';
const EVENT_TYPE = {
  MEETING: 'Dod',
  MASTER_KLASS: 'Mk',
  PRESENTATION_OF_PROJECTS: 'Presentation',
  INTENSIVE: 'Интенсив',
};

export const getMetrik = (program, eventType, city, target) =>
  `${program}${EVENT_TYPE[eventType]}${capitalizeText(city)}${target}`;
