import React, { useEffect, useState } from 'react';
import { Box, Chip, Grid, Typography } from '@mui/material';
import ContactsModal from '../../shared/Modals/ContactsModal';
import {
  parseDate,
  generateTags,
  generateProgram,
  getProgram,
} from '../../helpers/lib';
import { EVENT_FORMAT_AMO } from '../../app/constants/events';
import styles from './styles';
import TrainingLevelChip from '../../shared/TrainingLevelChip/TrainingLevelChip';
import EventDetailItem from '../../shared/DetailItem/EventDetailItem';
import { Button } from 'gatsby-theme-material-ui';
import { useInView } from 'react-intersection-observer';
import ModalBlock from '../../shared/ModalBlock/ModalBlock';
import EventRequestModal from '../../shared/Modals/EventRequestModal';
import PlatesList from '../../shared/PlatesList/PlatesList';
import Section from '../../shared/Section/Section';
import Markdown from '../../shared/Markdown/Markdown';
import { getMetrik } from '../../pages-fsd/EventsPage/model/metriks';

const formData = {
  title: 'Запись на мероприятие',
  sentContent: {
    title: 'Вы успешно записались на мероприятие!',
    message: 'Скоро вам на почту придет ссылка для подключения в Zoom',
  },
};

function EventMkOnlineInfoBlock({
  externalHide,
  eventData,
  data,
  loading,
  extraLeadData,
}) {
  const {
    city,
    amoCity,
    title,
    date,
    startTime,
    duration,
    location,
    subTitleEnum,
    titleEnum,
    tags,
    description,
    mainSection,
  } = eventData || {};
  const program = getProgram(titleEnum);
  const {
    requestLeadData,
    beginners: { [program]: beginners },
    advanced: { [program]: advanced },
  } = data;

  const isAdvanced = titleEnum.includes('ADVANCED');
  const ymSubmitEventTypes = getMetrik(
    program,
    subTitleEnum,
    city,
    `${isAdvanced ? 'Pro' : 'Beg'}TopSignUp`,
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [leadData, setLeadData] = useState({});
  const [showButton, setShowButton] = useState(false);
  const [hideBlockRef, hideInView] = useInView({
    threshold: 1,
    delay: 1,
  });
  useEffect(() => {
    setShowButton(!hideInView && !externalHide);
  }, [hideInView, externalHide]);
  useEffect(() => {
    if (date) {
      setStartDate(() => parseDate(date));
      setLeadData((prev) => ({
        ...prev,
        date,
      }));
    }
  }, [date]);
  useEffect(() => {
    setStartDate(() => parseDate(date));
    setLeadData((prev) => ({
      ...prev,
      eventName: title || '',
      program: generateProgram(title),
    }));
  }, [title]);

  useEffect(() => {
    setLeadData((prev) => ({
      ...prev,
      eventName: title,
      tags: generateTags(city, title, tags),
      format: EVENT_FORMAT_AMO[city],
    }));
  }, [city]);

  const applyButton = (
    <Button
      onClick={() => {
        setIsModalOpen(true);
      }}
      variant="kitPrimary"
      size="medium"
      sx={{ width: '100%', maxWidth: 330, mx: 'auto' }}
    >
      Записаться
    </Button>
  );
  return (
    <Section
      bg={'mk-info'}
      theme={'default'}
      customStyles={{
        paddingTop: { xs: 4, lg: 10 },
      }}
    >
      <Grid container color={'kit.text.main'} justifyContent="space-between">
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            maxWidth: {
              xs: '100%',
              md: '688px',
            },
          }}
        >
          <Box sx={styles.top}>
            <Chip size="small" variant="orange" label="ОНЛАЙН" />
            <Chip size="small" variant="orange" label="бесплатно" />
            <Chip size="small" variant="orange" label="ЖИВОЕ ОБЩЕНИЕ" />
          </Box>
          <Typography
            component="h2"
            variant="desktopH1"
            sx={{
              ...styles.title,
              maxWidth: 632,
            }}
          >
            {Array.isArray(title)
              ? title.map((item, index) => (
                  <Typography
                    key={index}
                    component="span"
                    sx={{ display: 'block' }}
                  >
                    {item}
                  </Typography>
                ))
              : title}
          </Typography>
          <Typography
            variant="desktopH4"
            sx={{
              mt: { xs: 1.75, lg: 3 },
              fontFamily: 'RF Rostin',
              fontSize: 18,
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: 'normal',
              letterSpacing: '-1.26px',
              maxWidth: 550,
            }}
          >
            {description}
          </Typography>
          <Box sx={{ mt: { xs: 3, lg: 4 } }}>
            <TrainingLevelChip chip={isAdvanced ? 'advanced' : 'beginners'} />
          </Box>
          <Box
            sx={{
              mt: { xs: 3, lg: 4 },
              display: 'flex',
              gap: { xs: 1.5, lg: 4 },
              alignItems: 'center',
              flexWrap: 'wrap',
            }}
          >
            <Box sx={styles.infoItemWrapper}>
              {!!startDate && !!startDate[1] && !!startDate[2] && (
                <EventDetailItem
                  icon="calendar"
                  item={`${startDate[1]} ${startDate[2]}`}
                  description={startDate[0]}
                />
              )}
            </Box>

            <Box sx={styles.infoItemWrapper}>
              {!!startTime && !!duration && (
                <EventDetailItem
                  icon="clock"
                  item={startTime}
                  description={duration}
                />
              )}
            </Box>

            <Box sx={styles.infoItemWrapper}>
              {!!location && (
                <EventDetailItem
                  icon="location"
                  item={'Онлайн'}
                  description={'в Zoom'}
                />
              )}
            </Box>
          </Box>
          <Box
            ref={hideBlockRef}
            sx={{ mt: 6, display: { xs: 'flex', lg: 'none' } }}
          >
            {applyButton}
          </Box>
        </Grid>
        <Box
          sx={{
            zIndex: 1,
            width: '100%',
            position: 'fixed',
            left: 0,
            bottom: 0,
            p: 1,
            backgroundColor: 'white',
            display: { xs: 'flex', md: 'none' },
            opacity: showButton ? 1 : 0,
            visibility: showButton ? 'visible' : 'hidden',
            transition: 'opacity 0.5s ease-out, visibility 0.15s ease-out',
          }}
        >
          {applyButton}
        </Box>

        <Grid item xs={12} md={6} lg={5}>
          {!loading && (
            <Box
              sx={{
                backgroundColor: 'kit.background.gray',
                borderRadius: '16px',
                display: { xs: 'none', lg: 'block' },
                width: { xs: '100%', lg: 422 },
                p: 4,
              }}
            >
              <ContactsModal
                leadData={{
                  ...extraLeadData,
                  ...leadData,
                  ...requestLeadData,
                  titleEnum,
                  subTitleEnum,
                }}
                formData={formData}
                buttonLabel="Записаться"
                ymSubmitEventTypes={[ymSubmitEventTypes]}
              />
            </Box>
          )}
        </Grid>
      </Grid>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          mt: { xs: 6.25, lg: 21.5 },
        }}
      >
        <Box sx={{ width: { xs: '100%', lg: 432 } }}>
          <Typography variant="desktopH2" color={'kit.text.main'}>
            Что будет на занятии
          </Typography>
          <Typography
            variant="desktopP2"
            sx={{ mt: 2, width: 336, fontSize: 16 }}
          >
            <Markdown>{mainSection}</Markdown>
          </Typography>
        </Box>
        <Box sx={{ width: { xs: '100%', lg: 872 } }}>
          <PlatesList list={isAdvanced ? advanced.list : beginners.list} />
        </Box>
      </Box>
      <ModalBlock
        isOpen={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
      >
        <EventRequestModal
          extraLeadData={{
            ...requestLeadData,
            eventName: title,
            titleEnum,
            subTitleEnum,
            eventLocation: location,
            format: amoCity,
            date,
            tags: Array.isArray(tags) ? [...tags] : [tags],
            subTitleEnum: subTitleEnum,
            titleEnum: titleEnum,
            city: city,
          }}
          location={location}
          startTime={startTime}
          date={parseDate(date)}
          formData={{
            title: 'Запись на мероприятие',
            sentContent: {
              title: 'Вы успешно записались на мероприятие!',
              message:
                'Скоро вам на почту придет ссылка для подключения в Zoom',
            },
            // subtitle: 'EVENT_TYPE[subTitleEnum]',
          }}
          // ymSubmitEventType={data.ymEvents.ymAnyEventsRegistration}
          ymSubmitEventTypes={[ymSubmitEventTypes]}
          // ymSpecialEventType={data.ymEvents[currentEvent.filterKey]}
        />
      </ModalBlock>
    </Section>
  );
}

export default EventMkOnlineInfoBlock;
