import { Box, Typography } from '@mui/material';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

export default function TrainingLevelChip({ chip }) {
  switch (chip) {
    case 'beginners':
      return (
        <Box
          sx={{
            p: (theme) => ({
              xs: theme.spacing(1, 1.5, 1, 1),
              lg: theme.spacing(1.5, 2, 1.5, 1.5),
            }),
            borderRadius: 4,
            display: 'inline-flex',
            alignItems: 'center',
            background:
              'var(--Border-Gradient, linear-gradient(270deg, #956FFF 0%, #29EDFF 100%))',
          }}
        >
          {' '}
          <Box
            sx={{
              p: '7px',
              borderRadius: 3.5,
              display: 'inline-block',
              background: 'white',
            }}
          >
            <StaticImage
              alt="emoji"
              src="./rocket.png"
              style={{ width: 30, height: 30 }}
            />
          </Box>
          <Typography
            variant="desktopH4"
            sx={{
              color: 'kit.text.mainInverse',
              fontSize: 16,
              fontWeight: 700,
              ml: 1,
            }}
          >
            С нуля
          </Typography>
        </Box>
      );
    case 'advanced':
      return (
        <Box
          sx={{
            p: (theme) => ({
              xs: theme.spacing(1, 1.5, 1, 1),
              lg: theme.spacing(1.5, 2, 1.5, 1.5),
            }),
            borderRadius: 4,
            display: 'inline-flex',
            alignItems: 'center',
            background: 'linear-gradient(90deg, #000 0%, #956FFF 100%)',
          }}
        >
          {' '}
          <Box
            sx={{
              p: '7px',
              borderRadius: 3.5,
              display: 'inline-block',
              background: 'white',
            }}
          >
            <StaticImage
              alt="emoji"
              src="./sunglassesface.png"
              style={{ width: 30, height: 30 }}
            />
          </Box>
          <Typography
            variant="desktopP1"
            sx={{
              color: 'kit.text.mainInverse',
              fontSize: '16px !important',
              fontWeight: 700,
              ml: 1,
            }}
          >
            PRO-уровень
          </Typography>
        </Box>
      );
    default:
      return null;
  }
}
