import React from 'react';
import { dodOnlineData as data } from '../model';
import { useInView } from 'react-intersection-observer';
import EventDodOnlineInfoBlock from '../../../widgets/EventDodOnlineInfoBlock/EventDodOnlineInfoBlock';
import DodOnlineSlidingGroup from '../../../widgets/DodOnlineSlidingGroup/DodOnlineSlidingGroup';

export default function DodOnline({
  eventData,
  greetingsImgs,
  statistic,
  suggestEvents,
  leadData = { leadData },
  setCurrentEvent,
  commonLeadData,
}) {
  const [hideBlockRef, hideInView] = useInView({
    threshold: 0.1,
    delay: 1,
  });

  return (
    <>
      <EventDodOnlineInfoBlock
        externalHide={hideInView}
        eventData={eventData}
        greetingsImgs={greetingsImgs}
        data={data.dodOnlineIntroduce[eventData.program]}
      />
      <DodOnlineSlidingGroup
        dodOnlineIntroduceData={data.dodOnlineIntroduce[eventData.program]}
        canBeInterestData={data.canBeInterest}
        outsideRef={hideBlockRef}
        greetingsImgs={greetingsImgs}
        data={data}
        eventData={eventData}
        statistic={statistic}
        leadData={leadData}
        events={suggestEvents}
        setCurrentEvent={setCurrentEvent}
        ymEvents={data.ymEvents[eventData.program]}
        commonLeadData={commonLeadData}
      />
    </>
  );
}
