import React from 'react';
import { Box } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import styles from './styles';

function Steps({ markdown }) {
  return (
    <Box sx={styles.container}>
      {markdown && <ReactMarkdown children={markdown} />}
    </Box>
  );
}

export default Steps;
