import React from 'react';
import { Box } from '@mui/material';
import Marquee from 'react-fast-marquee';
import styles from './styles';

function RunningLine({ text }) {
  return (
    <Marquee gradient={false} speed="30">
      {[...Array(8)].map((el, index) => (
        <Box key={index} sx={styles.runningLineText}>{`${text}\u00A0`}</Box>
      ))}
    </Marquee>
  );
}

export default RunningLine;
