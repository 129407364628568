import { Link } from 'gatsby-theme-material-ui';
import React from 'react';
import MotivateChat from '../../shared/MotivateChat/MotivateChat';
import Section from '../../shared/Section/Section';

export default function Motivate({ link, bg, motivate }) {
  return (
    <Section
      title={motivate.title}
      subtitle={motivate.subtitle}
      bg={'motivate'}
    >
      <MotivateChat messages={motivate.chat} />
      <Link
        variant="kitPrimary"
        to={motivate.research?.link.url}
        target="_blank"
        sx={{
          textDecoration: 'none',
        }}
      >
        *{motivate.research?.title}
      </Link>
    </Section>
  );
}
