const styles = {
  container: (theme) => ({
    h1: {
      fontFamily: 'RF Rostin',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: {
        xs: '22px',
        md: '34px',
      },
      lineHeight: '130%',
      letterSpacing: '-0.06em',
      color: 'kit.text.h',
      marginBottom: {
        xs: '12px',
        md: '24px',
      },
    },
    h2: {
      marginBottom: {
        xs: '12px',
        md: '24px',
      },
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: {
        xs: '14px',
        md: '18px',
      },
      lineHeight: 1.4,
      color: 'kit.text.main',
    },
    ol: {
      listStyle: 'none',
      overflowX: 'hidden',
      paddingTop: {
        xs: 0,
        lg: '23px',
      },
      paddingLeft: {
        xs: '6px',
        lg: 0,
      },
      display: 'flex',
      flexDirection: {
        xs: 'column',
        lg: 'row',
      },
    },
    li: {
      fontFamily: 'RF Rostin',
      color: 'kit.text.main',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '18px',
      lineHeight: '1.4',
      letterSpacing: '-0.02em',
      textTransform: 'uppercase',
      position: 'relative',
      display: 'block',
      flexShrink: 0,
      width: {
        xs: '100%',
        lg: 'calc(100% / 12 * 2)',
      },
      paddingRight: {
        lg: '16px',
      },
      paddingLeft: {
        xs: '15px',
        lg: 0,
      },
      paddingTop: {
        lg: '23px',
      },
      paddingBottom: {
        xs: '32px',
        lg: 0,
      },
      '&:last-child': {
        marginBottom: 0,
      },
      '&::before': {
        content: '""',
        position: 'absolute',
        backgroundColor: 'kit.background.middlepurple',
        top: 0,
        left: 0,
        width: {
          xs: '2px',
          lg: '100%',
        },
        height: {
          xs: '100%',
          lg: '2px',
        },
      },
      '&::after': {
        content: '""',
        position: 'absolute',
        backgroundColor: 'kit.background.purple',
        left: 0,
        top: 0,
        borderRadius: '50%',
        width: {
          xs: '12px',
          lg: '16px',
        },
        height: {
          xs: '12px',
          lg: '16px',
        },
        transform: {
          xs: 'translateX(-42%)',
          lg: 'translateY(-50%)',
        },
      },
      '&:last-child::before': {
        display: 'none',
      },
      '&:nth-child(6)::before': {
        display: {
          lg: 'none',
        },
      },
      a: {
        display: 'block',
        color: 'kit.text.h',
        overflowX: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
      width: {
        xs: '100%',
        lg: 'calc(100% / 12 * 2)',
      },
    },
  }),
};

export default styles;
