const eventTypes = {
  JS: 'linear-gradient(89deg, #FFF 29.5%, #DBDCFF 92.06%), linear-gradient(0deg, #FFF 0%, #FFF 100%), linear-gradient(89deg, #FFF 29.5%, #DBDCFF 92.06%)',
  DESIGN:
    'linear-gradient(89deg, #FFF 35.5%, #FFDFCC 100%), linear-gradient(89deg, #FFF 29.5%, #DBDCFF 92.06%), linear-gradient(0deg, #FFF 0%, #FFF 100%), linear-gradient(89deg, #FFF 29.5%, #DBDCFF 92.06%)',
  DS: 'linear-gradient(89deg, #FFF 34%, #D3EBF8 92%), linear-gradient(89deg, #FFF 29.5%, #DBDCFF 92.06%), linear-gradient(0deg, #FFF 0%, #FFF 100%), linear-gradient(89deg, #FFF 29.5%, #DBDCFF 92.06%)',
};

export const styles = {
  backgroundGenerate: (type) => eventTypes[type],
  root: (theme) => ({
    boxSizing: 'border-box',
    width: '100%',
    height: '100%',
    minHeight: 421,
    borderRadius: 6,
    // padding: theme.spacing(4, 2),
    color: 'kit.text.main',
    [theme.breakpoints.down('sm')]: {
      minHeight: 348,
      padding: theme.spacing(3, 2),
    },
    boxShadow:
      '-2px -2px 7px rgba(227, 225, 237, 0.68), 1px 1px 10px rgba(223, 221, 231, 0.76);',
  }),
  title: {
    textTransform: 'uppercase',
    letterSpacing: '-0.03em',
    marginBottom: {
      xs: '16px',
      xl: '12px',
    },
  },
  date: {
    lineHeight: {
      xl: 1,
    },
    marginBottom: {
      xs: '4px',
      xl: '14px',
    },
  },
  month: {
    marginBottom: 3,
  },
  titleBlock: {
    marginBottom: '12px',
  },
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  moreGrid: {
    marginTop: 'auto',
  },
  subscriptGrid: {
    marginTop: 'auto',
  },
  mainContent: {
    paddingRight: {
      xs: '19px',
      xl: '36px',
    },
  },
  content: {
    color: '#0f0f0f',
    opacity: 0.6,
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box !important',
    WebkitLineClamp: '5',
    lineClamp: '5',
    maxLines: '5',
    WebkitBoxOrient: 'vertical',
    lineHeight: {
      xs: '15px',
      xl: '20px',
    },
  },

  subscriptBlock: (theme) => ({
    marginBottom: '8px',
  }),
  link: {
    display: 'inline-block',
    textDecoration: 'none',
  },
  chip: {
    fontSize: { xs: '12px', xl: '16px' },
    padding: '16px 8px',
    height: 22,
    borderRadius: {
      xs: 1,
      lg: 2,
    },
    marginBottom: {
      xs: '16px',
      xl: '14px',
    },
  },
};
