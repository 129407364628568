import React from 'react';
import { SvgIcon } from '@mui/material';

export default function PointB(props) {
  return (
    <SvgIcon sx={{ width: 32, height: 44 }} viewBox="0 0 32 44" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.0312 25.2859C30.9003 22.6676 32 19.4622 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 19.4586 1.09735 22.6608 2.96287 25.2776L14.0819 42.6105C14.9785 44.0081 17.0209 44.0081 17.9175 42.6105L29.0312 25.2859Z"
        fill="#FFBC5B"
      />
      <path
        d="M19.2239 16.74C19.7706 17.0467 20.1906 17.4667 20.4839 18C20.7772 18.52 20.9239 19.14 20.9239 19.86C20.9239 20.66 20.7439 21.3733 20.3839 22C20.0372 22.6267 19.5439 23.12 18.9039 23.48C18.2639 23.8267 17.5372 24 16.7239 24H11.7239V10H16.4239C17.2372 10 17.9639 10.1733 18.6039 10.52C19.2439 10.8667 19.7372 11.3467 20.0839 11.96C20.4439 12.56 20.6239 13.2333 20.6239 13.98C20.6239 14.5933 20.5039 15.1333 20.2639 15.6C20.0239 16.0667 19.6772 16.4467 19.2239 16.74ZM14.0839 12.24V15.74H16.0839C16.8039 15.74 17.3439 15.58 17.7039 15.26C18.0772 14.94 18.2639 14.5133 18.2639 13.98C18.2639 13.46 18.0772 13.04 17.7039 12.72C17.3439 12.4 16.8039 12.24 16.0839 12.24H14.0839ZM16.3839 21.76C17.1172 21.76 17.6639 21.5933 18.0239 21.26C18.3839 20.9267 18.5639 20.46 18.5639 19.86C18.5639 19.2733 18.3839 18.8133 18.0239 18.48C17.6639 18.1467 17.1172 17.98 16.3839 17.98H14.0839V21.76H16.3839Z"
        fill="#4520AB"
      />
    </SvgIcon>
  );
}
