import React from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { styles } from './styles';
import { useStickersQuery } from '../../app/queries/stickers.query';

export default function MotivateChatMessage({ message }) {
  const isSm = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const isLg = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const stickerImgs = useStickersQuery();

  return (
    <Box sx={styles.root(message.isAnswer)}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
        }}
      >
        <GatsbyImage
          style={{
            width: isSm ? 60 : isLg ? 108 : 86,
          }}
          image={getImage(stickerImgs[message.sticker])}
          alt={'sticker'}
          loading="lazy"
        />
      </Box>
      <Box sx={styles.message(message.isAnswer)}>
        <Typography
          sx={styles.messageText}
          variant="desktopP2"
          color={message.isAnswer ? 'kit.text.mainInverse' : 'kit.text.main'}
        >
          {message.text}
        </Typography>
      </Box>
    </Box>
  );
}
