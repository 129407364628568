import React, { useRef } from 'react';
import { CanBeInterest, EventStatistic } from '../../../widgets';
import { mkOnlineData as data } from '../model';
import { useInView } from 'react-intersection-observer';
import ProjectsInfoBlock from '../../../widgets/ProjectsInfoBlock/ProjectsInfoBlock';

export default function ProjectsPresentation({
  eventData,
  greetingsImgs,
  statistic,
  suggestEvents,
  setCurrentEvent,
}) {
  const [hideBlockRef, hideInView] = useInView({
    threshold: 0.1,
    delay: 1,
  });

  return (
    <>
      <ProjectsInfoBlock
        externalHide={hideInView}
        eventData={eventData}
        greetingsImgs={greetingsImgs}
        data={data.mkOnlineIntroduce}
      />
      <CanBeInterest
        leadData={data.canBeInterest.leadData}
        title={data.canBeInterest.title}
        description={data.canBeInterest.description}
        events={suggestEvents}
        setCurrentEvent={setCurrentEvent}
      />
    </>
  );
}
