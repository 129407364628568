import React from 'react';
import { Box, Grid, Paper, Typography } from '@mui/material';
import styles from './styles';
import SpeakerCard from '../../shared/Cards/SpeakerCard';

function Speakers({ list }) {
  return (
    <Box sx={styles.container}>
      <Typography variant="desktopH2" sx={styles.title}>
        Спикеры
      </Typography>
      <Grid container spacing={{ xs: '16px', lg: '24px' }}>
        {list &&
          list
            .filter((item) => item.name)
            .map((item) => (
              <Grid item xs={12} md={6} lg={5} key={item.name}>
                <SpeakerCard {...item} />
              </Grid>
            ))}
      </Grid>
    </Box>
  );
}

export default Speakers;
