import React from 'react';
import { Paper, Box, Typography } from '@mui/material';
import styles from './styles/speakerStyles';

function SpeakerCard({ description, name, photo, title }) {
  return (
    <Paper sx={styles.container}>
      <Box sx={styles.top}>
        {photo && (
          <Box sx={styles.avatar}>
            <img src={photo} />
          </Box>
        )}
        <Box sx={styles.info}>
          <Typography variant="desktopH3" sx={styles.name}>
            {name}
          </Typography>
          <Typography variant="desktopP3" sx={styles.title}>
            {title}
          </Typography>
        </Box>
      </Box>
      <Typography variant="desktopP2" color="kit.text.black">
        {description}
      </Typography>
    </Paper>
  );
}

export default SpeakerCard;
