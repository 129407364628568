import React from 'react';
import { Typography } from '@mui/material';

import styles from './styles';
import Section from '../../shared/Section/Section';
import SubscribeFeature from './SubscribeFeature';

export function SubscribeModule({ leadData, ymSubmitEventType }) {
  return (
    <>
      <Typography color="kit.text.main" variant="desktopH2">
        Анонсы событий
      </Typography>
      <br />
      <Typography variant="desktopP2" sx={styles.description}>
        Чтобы не пропустить то, что нужно именно вам, подпишитесь на нашу
        рассылку
      </Typography>
      <SubscribeFeature
        leadData={leadData}
        ymSubmitEventType={ymSubmitEventType}
      />
    </>
  );
}

export default function Subscribe(props) {
  return (
    <Section>
      <SubscribeModule {...props} />
    </Section>
  );
}
