import { useStaticQuery, graphql } from 'gatsby';
import { getImageDataMapByFileEdges } from '../../helpers/lib';

export const useEventsQuery = () => {
  const { allFile } = useStaticQuery(graphql`
    query eventssQuery {
      allFile(filter: { relativeDirectory: { regex: "/events/" } }) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData
            }
            relativeDirectory
            name
            absolutePath
          }
        }
      }
    }
  `);

  return getImageDataMapByFileEdges(allFile.edges);
};
