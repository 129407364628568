export function recommendEvents(currentEvent, events) {
  const city = currentEvent.city;
  const titleEnum = currentEvent.titleEnum;
  const subTitleEnum = currentEvent.subTitleEnum;
  const eventProgram = titleEnum.includes('_UX')
    ? 'UX'
    : titleEnum.includes('_DS')
      ? 'DS'
      : 'JS';
  const recommendations = [];
  const onlineEvents = events.filter((event) => event.city === 'ONLINE');
  const programFilteredEvents = events.filter((event) =>
    event.titleEnum.includes(`_${eventProgram}`),
  );
  const projectEvent = programFilteredEvents.filter(
    (event) => event.subTitleEnum === 'PRESENTATION_OF_PROJECTS',
  )[0];

  const mkBeginnersEvent = programFilteredEvents.filter(
    (event) => event.titleEnum === `MK_${eventProgram}_BEGINNERS`,
  )[0];

  const mkAdvancedEvent = programFilteredEvents.filter(
    (event) => event.titleEnum === `MK_${eventProgram}_ADVANCED`,
  )[0];

  const dodEvent = programFilteredEvents.filter(
    (event) => event.subTitleEnum === `MEETING` && event.city === 'ONLINE',
  )[0];

  const addRecommendation = (event) => {
    if (event && !recommendations.find((rec) => rec === event)) {
      recommendations.push(event);
    }
  };

  if (subTitleEnum === 'MEETING') {
    addRecommendation(mkBeginnersEvent);
    addRecommendation(projectEvent);
  }

  if (titleEnum === `MK_${eventProgram}_BEGINNERS`) {
    addRecommendation(dodEvent);
    addRecommendation(mkAdvancedEvent);
  }

  if (titleEnum === `MK_${eventProgram}_ADVANCED`) {
    addRecommendation(dodEvent);
    addRecommendation(projectEvent);
  }

  if (subTitleEnum === 'PRESENTATION_OF_PROJECTS') {
    addRecommendation(dodEvent);
    addRecommendation(mkBeginnersEvent);
  }

  while (recommendations.length < 2 && onlineEvents.length > 0) {
    const onlineEvent = onlineEvents.splice(0, 1)[0];
    addRecommendation(onlineEvent);
  }

  recommendations.forEach((event, index) => {
    const position = index === 0 ? 'First' : 'Second';
    if (event) {
      event.ymMetriks = {
        signUp: `EventRecomendationSignUp${position}`,
        clickMore: `EventRecomendationMore${position}`,
      };
    }
  });
  return recommendations;
}
