import React from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import CalendarIcon from '../../kit/icons/CalendarIcon';
import ClockIcon from '../../kit/icons/ClockIcon';
import LocateIcon from '../../kit/icons/LocateIcon';

const styles = {
  infoItemWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  main: {
    display: 'flex',
    alignItems: {
      xs: 'flex-end',
      md: 'center',
    },
  },
  infoItem: {
    marginRight: 0,
    marginTop: {
      xs: '5px',
      xl: '16px',
    },
  },
  icon: {
    flexShrink: 0,
    width: {
      xs: '24px',
      md: '28px',
    },
    height: {
      xs: '24px',
      md: '28px',
    },
    marginRight: {
      xs: '12px',
      md: '14px',
    },
    svg: {
      width: '100%',
      height: '100%',
    },
  },
};

const createIcon = (iconType, color) => {
  switch (iconType) {
    case 'calendar': {
      return <CalendarIcon color={color} sx={{ width: 28, height: 28 }} />;
    }
    case 'clock': {
      return <ClockIcon color={color} sx={{ width: 28, height: 28 }} />;
    }
    case 'location': {
      return <LocateIcon color={color} sx={{ width: 28, height: 28 }} />;
    }
    default: {
      return <CalendarIcon color={color} sx={{ width: 28, height: 28 }} />;
    }
  }
};

function MkDetailItem({ icon, item, description, colorTheme = {} }) {
  const theme = useTheme();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  if (isMobile) {
    return (
      <Box sx={{ display: 'flex', gap: 1 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {createIcon(icon, theme.getColor(colorTheme.iconColor))}
        </Box>
        <Box sx={{}}>
          <Typography
            variant="desktopP2"
            color={colorTheme.titleColor || 'kit.text.main'}
            sx={{
              fontFamily: 'RF Rostin',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 700,
              lineHeight: 'normal',
              letterSpacing: '-1.12px',
            }}
          >
            {item}
          </Typography>
          <Typography
            variant="desktopP3"
            color={colorTheme.subTitleColor || 'kit.text.secondary'}
            fontSize={16}
          >
            {description}
          </Typography>
        </Box>
      </Box>
    );
  }
  return (
    <Box sx={styles.infoItemWrapper}>
      {item && (
        <>
          <Box sx={styles.main}>
            <Box sx={styles.icon}>
              {createIcon(icon, theme.getColor(colorTheme.iconColor))}
            </Box>
            <Typography
              component="span"
              variant="desktopP1"
              fontFamily={'RF Rostin'}
              letterSpacing={-1.26}
              color={colorTheme.titleColor || 'kit.text.main'}
            >
              {`${item} ${description ? ` / ${description}` : ''}`}
            </Typography>
          </Box>
        </>
      )}
    </Box>
  );
}

export default MkDetailItem;
