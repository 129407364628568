import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import Section from '../../shared/Section/Section';
import LocationLabel from '../../shared/AnimatedLabel/LocationLabel';
import ContactsModal from '../../shared/Modals/ContactsModal';
import DetailItem from '../../shared/DetailItem/DetailItem';
import { parseDate, generateTags, generateProgram } from '../../helpers/lib';
import { EVENT_FORMAT_AMO } from '../../app/constants/events';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import ModalBlock from '../../shared/ModalBlock/ModalBlock';
import EventRequestModal from '../../shared/Modals/EventRequestModal';
import { Button } from 'gatsby-theme-material-ui';
import { data } from '../../pages-fsd/EventsPage/ui/AllEvents';
import styles from './styles';

const colorThemes = {
  dark: {
    mainColor: 'kit.text.accent',
    titleColor: 'kit.text.mainInverse',
    subTitleColor: 'kit.text.secondaryInverse',
    iconColor: 'kit.text.accent',
  },
  default: {
    mainColor: 'kit.text.main',
    titleColor: 'kit.text.main',
    subTitleColor: 'kit.text.secondary',
    iconColor: 'kit.background.purple',
  },
  main: {
    mainColor: 'kit.text.main',
    titleColor: 'kit.text.h',
    subTitleColor: 'kit.text.secondary',
    iconColor: 'kit.background.main',
  },
};

const formData = {
  title: 'Успейте записаться на мероприятие',
  sentContent: {
    title: 'Успешно записаны на мероприятие!',
  },
  titleStyle: {
    textAlign: 'center',
    lineHeight: '32px',
    marginTop: {
      xs: '24px',
      md: '10px',
    },
  },
  // contentWrapperStyle: {
  //   paddingTop: {
  //     xs: '0px',
  //     md: '20px',
  //   },
  // },
};

const cities = {
  ONLINE: 'Онлайн',
  MSK: 'Москва',
  SPB: 'Санкт-Петербург',
};

function EventIntroduce({
  showSubscribe,
  theme = 'default',
  city,
  amoCity,
  title,
  subTitle,
  subTitleEnum,
  titleEnum,
  bg,
  image,
  labelTheme,
  date,
  startTime,
  duration,
  fullLocation,
  location,
  tags,
  extraLeadData,
  requestLeadData,
  customStyles,
  ymSubmitEventTypes = [],
  eventType,
  myTargetEvent,
}) {
  const isSm = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [colorTheme, setColorTheme] = useState({});
  const [startDate, setStartDate] = useState(null);
  const [locationType, setLocationType] = useState({});
  const [leadData, setLeadData] = useState({});
  const [subscribeModalIsOpen, setSubscribeModalIsOpen] = useState(false);
  useEffect(() => {
    if (date) {
      setStartDate(() => parseDate(date));
      setLeadData((prev) => ({
        ...prev,
        date,
      }));
    }
  }, [date]);
  useEffect(() => {
    setStartDate(() => parseDate(date));
    setLeadData((prev) => ({
      ...prev,
      eventName: title || '',
      program: generateProgram(title),
    }));
  }, [title]);

  useEffect(() => {
    setColorTheme(() => colorThemes[theme] || colorThemes.default);
  }, [theme]);

  useEffect(() => {
    setLocationType(() => ({
      type: city === 'ONLINE' ? 'online' : 'offline',
      title: cities[city],
    }));
    setLeadData((prev) => ({
      ...prev,
      eventName: title,
      tags: generateTags(city, title, tags),
      format: EVENT_FORMAT_AMO[city],
    }));
  }, [city]);

  return (
    <Section
      bg={bg}
      withoutBg={!bg}
      theme={theme || 'default'}
      customStyles={{
        padding: {
          xs: '32px 0 32px',
          lg: '52px 0 0px',
        },
        ...customStyles,
      }}
    >
      <Grid
        container
        color={colorTheme.mainColor}
        justifyContent="space-between"
      >
        <Grid item xs={12} md={4} sx={styles.firstBlock}>
          <Box sx={styles.top}>
            <LocationLabel
              theme={labelTheme}
              type={locationType?.type}
              title={locationType?.title}
            />
          </Box>
          <Typography
            component="h2"
            variant="desktopH4"
            sx={{ ...styles.title, color: colorTheme.titleColor }}
          >
            {Array.isArray(title)
              ? title.map((item, index) =>
                  index === 0 ? (
                    <Typography
                      key={index}
                      component="span"
                      sx={{
                        display: 'block',
                        fontWeight: { xs: 700, md: 400 },
                      }}
                    >
                      {item}
                    </Typography>
                  ) : (
                    <Typography
                      key={index}
                      component="span"
                      sx={{
                        display: 'block',
                        fontSize: { xs: 16, lg: 22 },
                        lineHeight: 1,
                        mt: { xs: 3, lg: 4 },
                      }}
                    >
                      {item}
                    </Typography>
                  ),
                )
              : title}
          </Typography>
          {subTitle && (
            <Typography
              component="h4"
              variant={isSm ? 'mobileP1' : 'desktopH4'}
              sx={{ ...styles.subTitle }}
            >
              {subTitle}
            </Typography>
          )}
          <Box>
            <Box sx={styles.infoItemWrapper}>
              {!!startDate && !!startDate[1] && !!startDate[2] && (
                <DetailItem
                  colorTheme={colorTheme}
                  icon="calendar"
                  item={`${startDate[1]} ${startDate[2]}`}
                  description={startDate[0]}
                />
              )}
            </Box>

            <Box sx={styles.infoItemWrapper}>
              {!!startTime && !!duration && (
                <DetailItem
                  colorTheme={colorTheme}
                  icon="clock"
                  item={startTime}
                  description={duration}
                />
              )}
            </Box>

            <Box sx={styles.infoItemWrapper}>
              {!!startTime && !!duration && (
                <DetailItem
                  colorTheme={colorTheme}
                  icon="location"
                  item={cities[city]}
                />
              )}
            </Box>
            <Box sx={styles.infoItemWrapper}>
              {!!location && fullLocation !== 'Online' && (
                <DetailItem
                  colorTheme={colorTheme}
                  icon="location"
                  item={location === 'Online' ? 'Онлайн' : 'Кампус'}
                  description={fullLocation}
                />
              )}
            </Box>
          </Box>
          {!showSubscribe && (
            <Box sx={styles.subscribeButtonBlock}>
              <Button
                variant="kitPrimary"
                size="large"
                onClick={() => setSubscribeModalIsOpen(true)}
                sx={{
                  marginRight: '10px',
                  width: { xs: '100%', sm: 'auto' },
                  mb: { xs: 0, sm: 4 },
                }}
              >
                Зарегистрироваться
              </Button>
            </Box>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          lg={6}
          sx={{ display: 'flex', justifyContent: 'flex-end' }}
        >
          {showSubscribe && (
            <Box sx={styles.form}>
              <Box sx={styles.subscribeImageBlock}>
                <GatsbyImage image={getImage(image)} alt="phone" />
              </Box>
              <ContactsModal
                leadData={{ ...extraLeadData, ...leadData }}
                formData={formData}
                buttonLabel="Зарегистрироваться"
                ymSubmitEventTypes={ymSubmitEventTypes}
                myTargetMetric={[myTargetEvent]}
              />
            </Box>
          )}
          {!showSubscribe && (
            <Box sx={styles.imageBlock}>
              <GatsbyImage image={getImage(image)} alt="event" />
            </Box>
          )}
        </Grid>
      </Grid>
      <ModalBlock
        isOpen={subscribeModalIsOpen}
        handleClose={() => setSubscribeModalIsOpen(false)}
      >
        <EventRequestModal
          extraLeadData={{
            eventName: title,
            event: eventType,
            eventLocation: location,
            subTitleEnum,
            titleEnum,
            format: amoCity,
            date,
            tags: Array.isArray(tags) ? [...tags] : [tags],
            ...requestLeadData,
            ...extraLeadData,
          }}
          location={location}
          startTime={startTime}
          date={parseDate(date)}
          formData={{
            title: title,
            subtitle: eventType,
          }}
          ymSpecialEventType="eventsRegistrationDod"
          ymSubmitEventTypes={[
            ...ymSubmitEventTypes,
            data.ymEvents.ymAnyEventsRegistration,
          ]}
          myTargetEventTypes={[myTargetEvent]}
        />
      </ModalBlock>
    </Section>
  );
}

export default EventIntroduce;
