import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import CalendarIcon from '../../kit/icons/CalendarIcon';
import ClockIcon from '../../kit/icons/ClockIcon';
import LocateIcon from '../../kit/icons/LocateIcon';

const createIcon = (iconType, color) => {
  switch (iconType) {
    case 'calendar': {
      return <CalendarIcon color={color} sx={{ width: 28, height: 28 }} />;
    }
    case 'clock': {
      return <ClockIcon color={color} sx={{ width: 28, height: 28 }} />;
    }
    case 'location': {
      return <LocateIcon color={color} sx={{ width: 28, height: 28 }} />;
    }
    default: {
      return <CalendarIcon color={color} sx={{ width: 28, height: 28 }} />;
    }
  }
};

function OfflineDetailItem({ icon, item, description, colorTheme = {} }) {
  const theme = useTheme();

  return (
    <Box sx={{ display: 'flex', gap: 1 }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {createIcon(icon, theme.getColor(colorTheme.iconColor))}
      </Box>
      <Box sx={{ maxWidth: 274, lineHeight: 1.2 }}>
        <Typography component={'span'} sx={{ fontSize: 14, fontWeight: 700 }}>
          {item}
        </Typography>
        <Typography component={'span'} sx={{ fontSize: 14, lineHeight: 1 }}>
          {description}
        </Typography>
        {/* <Typography
          component={'span'}
          variant="desktopP2"
          color={colorTheme.titleColor || 'kit.text.main'}
          sx={{
            fontFamily: 'RF Rostin',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: 'normal',
            letterSpacing: '-1.12px',
            textAlign: 'start',
          }}
        >
          {item}
        </Typography>
        <Typography
          component={'span'}
          variant="desktopP3"
          color={colorTheme.subTitleColor || 'kit.text.secondary'}
        >
          {description}
        </Typography> */}
      </Box>
    </Box>
  );
}

export default OfflineDetailItem;
