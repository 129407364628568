import React from 'react';
import { SvgIcon } from '@mui/material';

export default function ArrowIcon(props) {
  return (
    <SvgIcon sx={{ width: 59, height: 16 }} viewBox="0 0 59 16" {...props}>
      <path
        d="M58.7071 8.70711C59.0976 8.31658 59.0976 7.68342 58.7071 7.29289L52.3431 0.928932C51.9526 0.538408 51.3195 0.538408 50.9289 0.928932C50.5384 1.31946 50.5384 1.95262 50.9289 2.34315L56.5858 8L50.9289 13.6569C50.5384 14.0474 50.5384 14.6805 50.9289 15.0711C51.3195 15.4616 51.9526 15.4616 52.3431 15.0711L58.7071 8.70711ZM0 9H58V7H0V9Z"
        fill="#4520AB"
      />
    </SvgIcon>
  );
}
