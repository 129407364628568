import React, { useState, useEffect } from 'react';
import { intervalToDuration } from 'date-fns';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';

function EventCountdownTimer({ timestamp, overTimerHandler }) {
  const [days, setDays] = useState('');
  const [hours, setHours] = useState('');
  const [minutes, setMinutes] = useState('');
  const [seconds, setSeconds] = useState('');

  const calculateTimeLeft = () => {
    const now = new Date();
    const endTime = new Date(timestamp);
    if (now >= endTime) {
      overTimerHandler(false);
      return { days: '0', hours: '00', minutes: '00', seconds: '00' };
    }
    const duration = intervalToDuration({ start: now, end: endTime });
    const formatNumber = (number) => String(number).padStart(2, '0');

    return {
      days: String(duration.days),
      hours: String(duration.hours),
      minutes: formatNumber(duration.minutes),
      seconds: formatNumber(duration.seconds),
    };
  };

  useEffect(() => {
    const timer = setInterval(() => {
      const timeLeft = calculateTimeLeft();
      setDays(timeLeft.days);
      setHours(timeLeft.hours);
      setMinutes(timeLeft.minutes);
      setSeconds(timeLeft.seconds);
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  if (Date.now() > timestamp) {
    return <></>;
  }

  return (
    <Typography
      component={'div'}
      sx={{
        mt: 3,
        display: 'flex',
        alignItems: 'center',
        gap: { xs: 0.5, md: 1.5 },
        fontSize: { xs: 16, lg: 26 },
        fontWeight: 700,
        lineHeight: 1.5,
        color: 'kit.text.main',
        textWrap: 'nowrap',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          p: 1.5,
          borderRadius: { xs: 3, lg: 4 },
          backgroundColor: 'white',
          // minWidth: { xs: 64, lg: 92 },
        }}
      >
        {days} дн
      </Box>
      :
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          p: 1.5,
          borderRadius: { xs: 3, lg: 4 },
          backgroundColor: 'white',
          // minWidth: { xs: 64, lg: 92 },
        }}
      >
        {hours} ч
      </Box>
      :
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          p: 1.5,
          borderRadius: { xs: 3, lg: 4 },
          backgroundColor: 'white',
          // minWidth: { xs: 80, lg: 112 },
        }}
      >
        {minutes} мин
      </Box>
      :
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          p: 1.5,
          borderRadius: { xs: 3, lg: 4 },
          backgroundColor: 'white',
          minWidth: { xs: 78, lg: 112 },
        }}
      >
        {seconds} сек
      </Box>
    </Typography>
  );
}

export default EventCountdownTimer;
