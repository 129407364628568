import React from 'react';
import { Box, Grid } from '@mui/material';
import Markdown from '../../Markdown/Markdown';
import { styles } from './styles';

function CareerDescription({ markdown, image }) {
  return (
    <Box sx={styles.mainContainer}>
      <Grid container sx={styles.container}>
        {markdown && (
          <Grid item xs={12} xl={6} sx={styles.content}>
            <Markdown children={markdown} />
          </Grid>
        )}
        <Grid item xs={12} xl={6} sx={styles.imgContainer}>
          {image && (
            <Box sx={styles.imgBox}>
              <img alt="Section img" src={image} />
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}

export default CareerDescription;
