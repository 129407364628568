export default {
  background: {
    height: 'fit-content',
    backgroundColor: 'kit.background.lightpurple',
    position: 'relative',
    overflow: 'hidden',
    margin: 0,
  },
  page: {
    width: '100vw',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    mt: 7.5,
    mb: 7.5,
  },
  wrapper: {
    zIndex: 1,
    display: 'flex',
    alignItems: { xs: 'center', lg: 'flex-start' },
    flexDirection: { xs: 'column', lg: 'row' },
    gap: { xs: 0, lg: 6 },
  },
  image: {
    mt: { xs: 8, lg: -3 },
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: { xs: 199, lg: '100%' },
  },

  message: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: { xs: 360, sm: 300, md: 400, lg: 500, xl: 572 },
  },
  text: {
    fontWeight: 400,
    letterSpacing: '-0.07em',
    fontSize: { xs: 42, lg: 80 },
  },
  button: {
    width: { xs: 326, lg: 382 },
    mt: { xs: 4, md: 6 },
  },
};
