import React, { useState } from 'react';

import { Box, Button, useMediaQuery } from '@mui/material';
import { parseDate } from '../../helpers/lib';
import ModalBlock from '../../shared/ModalBlock/ModalBlock';
import EventRequestModal from '../../shared/Modals/EventRequestModal';
import Section from '../../shared/Section/Section';
import ProgramElement from '../../shared/ProgramElement/ProgramElement';

import styles from './styles';

export function ProvideInformation({
  blockTitle,
  list,
  extraLeadData,
  location,
  startTime,
  date,
  city,
  amoCity,
  tags,
  title,
  eventType,
  ymSubmitEventTypes = [],
  ymSpecialEventType,
  myTargetEventTypes,
}) {
  const [subscribeModalIsOpen, setSubscribeModalIsOpen] = useState(false);
  const [weekDay, day, month] = parseDate(date);

  const isLg = useMediaQuery((theme) => theme.breakpoints.down('lg'));
  const isSm = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const openSubscribeModel = () => {
    setSubscribeModalIsOpen(true);
  };
  const closwSubscribeModel = () => {
    setSubscribeModalIsOpen(false);
  };

  return (
    <Section title={blockTitle} bg="dod-you-will-know">
      <Box sx={styles.programElementWrapper(isSm, isLg)}>
        {list.map((card, index) => (
          <ProgramElement zeroElevation {...card} key={index} />
        ))}
      </Box>
      <Button
        variant="kitPrimary"
        size="large"
        type="submit"
        onClick={openSubscribeModel}
      >
        Зарегистрироваться на {day} {month}
      </Button>
      <ModalBlock
        isOpen={subscribeModalIsOpen}
        handleClose={closwSubscribeModel}
      >
        <EventRequestModal
          extraLeadData={{
            eventName: title,
            eventLocation: location,
            format: amoCity,
            date,
            tags: Array.isArray(tags) ? [...tags] : [tags],
            ...extraLeadData,
          }}
          location={location}
          startTime={startTime}
          date={[weekDay, day, month]}
          formData={{
            title,
            subtitle: eventType,
          }}
          ymSubmitEventTypes={ymSubmitEventTypes}
          ymSpecialEventType={ymSpecialEventType}
          myTargetEventTypes={myTargetEventTypes}
        />
      </ModalBlock>
    </Section>
  );
}
