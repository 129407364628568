import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import Section from '../../shared/Section/Section';
import LocationLabel from '../../shared/AnimatedLabel/LocationLabel';
import ContactsModal from '../../shared/Modals/ContactsModal';
import DetailItem from '../../shared/DetailItem/DetailItem';
import { parseDate, generateTags, generateProgram } from '../../helpers/lib';
import { EVENT_FORMAT_AMO } from '../../app/constants/events';
import styles from './styles';

const colorThemes = {
  dark: {
    mainColor: 'kit.text.accent',
    titleColor: 'kit.text.accent',
    iconColor: 'kit.text.accent',
  },
  default: {
    mainColor: 'kit.background.black',
    titleColor: 'kit.background.black',
    iconColor: 'kit.background.black',
  },
  main: {
    mainColor: 'kit.text.main',
    titleColor: 'kit.text.h',
    iconColor: 'kit.background.main',
  },
};

const formData = {
  title: 'Запись на мероприятие',
  sentContent: {
    title: 'Успешно записаны на мероприятие!',
  },
};

const cities = {
  ONLINE: 'онлайн',
  MSK: 'Москва',
  SPB: 'Санкт-Петербург',
};

function EventInfoBlock({
  theme = 'default',
  loading,
  city,
  type,
  title,
  bg,
  subTitleEnum,
  titleEnum,
  labelTheme,
  date,
  startTime,
  duration,
  fullLocation,
  location,
  tags,
  extraLeadData,
  customStyles,
  ymSubmitEventTypes = [],
  myTargetMetric,
}) {
  const [colorTheme, setColorTheme] = useState({});
  const [startDate, setStartDate] = useState(null);
  const [locationType, setLocationType] = useState({});
  const [leadData, setLeadData] = useState({});

  useEffect(() => {
    if (date) {
      setStartDate(() => parseDate(date));
      setLeadData((prev) => ({
        ...prev,
        date,
      }));
    }
  }, [date]);
  useEffect(() => {
    setStartDate(() => parseDate(date));
    setLeadData((prev) => ({
      ...prev,
      eventName: title || '',
      program: generateProgram(title),
    }));
  }, [title]);

  useEffect(() => {
    setColorTheme(() => colorThemes[theme] || colorThemes.default);
  }, [theme]);

  useEffect(() => {
    setLocationType(() => ({
      type: city === 'ONLINE' ? 'online' : 'offline',
      title: cities[city],
    }));
    setLeadData((prev) => ({
      ...prev,
      eventName: title,
      tags: generateTags(city, title, tags),
      format: EVENT_FORMAT_AMO[city],
    }));
  }, [city]);

  return (
    <Section
      bg={bg}
      withoutBg={!bg}
      theme={theme || 'default'}
      customStyles={{
        padding: {
          xs: '32px 0 0 0',
          lg: '40px 0 56px 0',
        },
        ...customStyles,
      }}
    >
      <Grid
        container
        color={colorTheme.mainColor}
        justifyContent="space-between"
      >
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            maxWidth: {
              xs: '100%',
              md: '688px',
            },
          }}
        >
          <Box sx={styles.top}>
            <LocationLabel
              theme={labelTheme}
              type={locationType?.type}
              title={locationType?.title}
            />
            <Typography variant="desktopP2" sx={styles.type}>
              {type}
            </Typography>
          </Box>
          <Typography
            component="h2"
            variant="desktopH2"
            sx={{ ...styles.title, color: colorTheme.titleColor }}
          >
            {Array.isArray(title)
              ? title.map((item, index) => (
                  <Typography
                    key={index}
                    component="span"
                    sx={{ display: 'block' }}
                  >
                    {item}
                  </Typography>
                ))
              : title}
          </Typography>

          <Box>
            <Box sx={styles.infoItemWrapper}>
              {!!startDate && !!startDate[1] && !!startDate[2] && (
                <DetailItem
                  icon="calendar"
                  item={`${startDate[1]} ${startDate[2]}`}
                  description={startDate[0]}
                />
              )}
            </Box>

            <Box sx={styles.infoItemWrapper}>
              {!!startTime && !!duration && (
                <DetailItem
                  icon="clock"
                  item={startTime}
                  description={duration}
                />
              )}
            </Box>

            <Box sx={styles.infoItemWrapper}>
              {!!location && fullLocation !== 'Online' && (
                <DetailItem
                  icon="location"
                  item={location === 'Online' ? 'Онлайн' : 'Кампус'}
                  description={fullLocation}
                />
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={5}>
          {!loading && (
            <Box sx={styles.form}>
              <ContactsModal
                leadData={{
                  ...extraLeadData,
                  ...leadData,
                  titleEnum,
                  subTitleEnum,
                }}
                formData={formData}
                buttonLabel="Зарегистрироваться"
                ymSubmitEventTypes={ymSubmitEventTypes}
                myTargetMetric={myTargetMetric}
              />
            </Box>
          )}
        </Grid>
      </Grid>
    </Section>
  );
}

export default EventInfoBlock;
