import { Grid, Paper } from '@mui/material';
import React from 'react';
import MotivateChatMessage from '../MotivateChatMessage/MotivateChatMessage';
import { styles } from './styles';

export default function MotivateChat({ messages }) {
  return (
    <Paper sx={styles.root} elevation={0}>
      <Grid container spacing={0}>
        {messages?.map((message) => (
          <Grid item xs={12} md={6} key={message.text}>
            <MotivateChatMessage message={message} />
          </Grid>
        ))}
      </Grid>
    </Paper>
  );
}
