const styles = {
  message: {
    marginBottom: '8px',
    fontSize: {
      xl: '14px',
      lineHeight: '20px',
    },
  },
  textFieldBlock: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: {
      xs: 'center',
      md: 'flex-start',
    },
    alignItems: 'center',
    m: {
      xs: '16px 0',
      md: '16px 0 0',
    },
    flexDirection: {
      xs: 'column',
      md: 'row',
    },
    '& .MuiFormHelperText-root': {
      position: 'absolute',
      bottom: 0,
      transform: 'translateY(100%)',
      paddingTop: '4px',
      fontSize: '13px',
      lineHeight: '21px',
      color: 'kit.text.mainInverse',
    },
    '& .MuiFormHelperText-root.Mui-error': {
      color: 'kit.text.mainInverse',
    },
  },
  textFieldWrapper: {
    fontSize: 12,
    width: {
      xs: '100%',
      md: '30%',
    },
    mr: {
      xs: 0,
      md: 3,
    },
    mb: { xs: 4, md: 1 },
  },
  input: {
    width: '100%',
  },
  subscribed: {
    borderRadius: {
      md: '16px !important',
    },
    width: 64,
    height: 40,
  },
  agreement: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: {
      xs: 'center',
      md: 'flex-start',
    },
    mt: 1,
  },
  agreementLink: (theme) => ({
    fontSize: '12px',
    lineHeight: '20px',
    textDecoration: 'none',
    fontSize: '14px',
    lineHeight: '20px',
    textTransform: 'lowercase',
  }),
  subscribeMessage: {
    display: 'flex',
    flexDirection: 'column',
    mb: { xs: 3, md: 0 },
  },
  successButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    mb: { xs: 2, md: 0 },
  },
  description: {
    fontSize: {
      xs: '14px',
      md: '16px',
    },
  },
};

export default styles;
