import React from 'react';
import { Box, Card, CardMedia, Grid, Typography } from '@mui/material';
import styles from './styles';
import { IncognitoFemale, IncognitoMale } from './IncognitoAvatars';
export default function DodSpeakers({ speakers }) {
  const { customTitle, description, speakersList: list } = speakers || {};
  if (!speakers) {
    return null;
  }
  return (
    <>
      <Typography variant="desktopH2" color={'kit.text.main'}>
        {customTitle || 'Спикеры'}
      </Typography>
      <Typography variant="desktopP2" mt={{ xs: 1, lg: 2 }}>
        {description}
      </Typography>
      <Grid container columnSpacing={2} rowSpacing={2} mt={{ xs: 1, lg: 2 }}>
        {list?.map((speaker, index) => (
          <Grid item xs={12} lg={6} key={index}>
            <SpeakerBlock {...speaker} />
          </Grid>
        ))}
      </Grid>
    </>
  );
}

function SpeakerBlock({ speakerPhoto, speakerName, speakerTitle, gender }) {
  const avatar = gender === 'female' ? <IncognitoFemale /> : <IncognitoMale />;
  return (
    <Box
      sx={{
        width: { xs: '100%', lg: 368 },
        maxWidth: 368,
        height: { xs: 126, lg: 305 },
        borderRadius: { xs: 4, lg: 6 },
        background: '#FFF3E2',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          right: 0,
          left: 0,
          zIndex: 0,
        }}
      >
        <svg
          width="368"
          height="273"
          viewBox="0 0 368 273"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M280.424 114.944L275.792 116.347L290.336 179.825L230.945 153.11L228.661 157.377L283.831 191.979L222.945 215.084L224.347 219.716L287.825 205.173L261.11 264.563L265.377 266.847L299.979 211.677L323.084 272.563L327.716 271.161L313.172 207.683L372.563 234.398L374.847 230.131L319.678 195.529L380.564 172.424L379.161 167.792L315.683 182.335L342.398 122.945L338.131 120.661L303.53 175.831L280.424 114.944Z"
            fill="#FFEACC"
          />
          <path
            d="M170.809 -37.8041L164.659 -41.1562L113.981 38.2974L81.2163 -50.0636L74.4977 -48.0857L94.8442 43.9317L9.1962 4.61912L5.84411 10.769L85.2969 61.4478L-3.06355 94.2121L-1.08496 100.931L90.9322 80.5833L51.6194 166.232L57.7693 169.584L108.448 90.1305L141.212 178.492L147.931 176.514L127.584 84.4963L213.232 123.809L216.584 117.659L137.131 66.98L225.492 34.2158L223.513 27.4974L131.496 47.8446L170.809 -37.8041Z"
            fill="#FFEACC"
          />
        </svg>
      </Box>
      <Box
        sx={{
          position: 'relative',
          zIndex: 1,
          display: 'flex',
          flexDirection: { xs: 'row', lg: 'column' },
          gap: { xs: 1, lg: 0 },
          alignItems: 'center',
          py: { xs: 2, lg: 4 },
          px: { xs: 2, lg: 2 },
          height: '100%',
        }}
      >
        <Card sx={styles.speakerPhoto}>
          {speakerPhoto ? (
            <CardMedia
              component="img"
              sx={styles.image}
              image={speakerPhoto?.url}
              alt={speakerName}
              loading="lazy"
            />
          ) : (
            avatar
          )}
        </Card>
        <Box container sx={styles.speakerInfo}>
          <Typography variant="desktopH4" sx={styles.speakerName}>
            {speakerName}
          </Typography>
          <Typography variant="desktopP2" sx={styles.speakerDescription}>
            {speakerTitle}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
