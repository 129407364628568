import { Box } from '@mui/material';
import React from 'react';

export default function MailIcon({ bgColor = 'white', size = 46 }) {
  return (
    <Box
      sx={{
        p: 1,
        borderRadius: 3,
        backgroundColor: bgColor,
        width: size,
        height: size,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <svg
        width="28"
        height="20"
        viewBox="0 0 28 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="1.99219" width="24.2857" height="18.5714" fill="#E9E9FF" />
        <path
          d="M3.375 0C1.83309 0 0.5625 1.27059 0.5625 2.8125V17.1875C0.5625 18.7294 1.83309 20 3.375 20H24.625C26.1669 20 27.4375 18.7294 27.4375 17.1875V2.8125C27.4375 1.27059 26.1669 0 24.625 0H3.375ZM3.375 1.875H24.625C25.1606 1.875 25.5625 2.2769 25.5625 2.8125V17.1875C25.5625 17.7231 25.1606 18.125 24.625 18.125H3.375C2.8394 18.125 2.4375 17.7231 2.4375 17.1875V2.8125C2.4375 2.2769 2.8394 1.875 3.375 1.875ZM23.2871 3.71093C23.0491 3.72556 22.8256 3.83032 22.6621 4.0039L14.6152 11.6113C14.2299 11.9758 13.7589 11.9753 13.375 11.6113L5.33787 4.0039C5.23742 3.90471 5.11587 3.82949 4.98229 3.78383C4.84871 3.73818 4.70655 3.72328 4.5664 3.74025C4.38887 3.76247 4.2214 3.83502 4.08377 3.94933C3.94613 4.06365 3.84408 4.21497 3.78966 4.38541C3.73524 4.55585 3.73072 4.7383 3.77664 4.91123C3.82256 5.08415 3.917 5.24033 4.04881 5.36132L8.76562 9.84376L4.02931 14.6484C3.8545 14.8258 3.75732 15.0654 3.75915 15.3145C3.76099 15.5636 3.86168 15.8017 4.03909 15.9765C4.21649 16.1513 4.45608 16.2485 4.70514 16.2467C4.95421 16.2449 5.19235 16.1442 5.36718 15.9668L10.1328 11.1328L12.0762 12.9785C13.1387 13.9858 14.8415 13.9836 15.9043 12.9785L17.8574 11.1231L22.6328 15.9668C22.8076 16.1442 23.0458 16.2449 23.2948 16.2467C23.5439 16.2485 23.7835 16.1513 23.9609 15.9765C24.1383 15.8017 24.239 15.5636 24.2408 15.3145C24.2427 15.0654 24.1455 14.8258 23.9707 14.6484L19.2246 9.83401L23.9512 5.36132C24.1011 5.23391 24.2072 5.06253 24.2544 4.87148C24.3015 4.68043 24.2874 4.47938 24.2139 4.29681C24.1404 4.11425 24.0114 3.95944 23.8451 3.85429C23.6787 3.74914 23.4835 3.69898 23.2871 3.71093Z"
          fill="#4520AB"
        />
      </svg>
    </Box>
  );
}
