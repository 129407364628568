import React, { useState, useEffect } from 'react';
import { Redirect } from '@reach/router';
import { getEventInfo } from '../../../api/strapi';
import {
  capitalizeText,
  generateProgram,
  generateTags,
} from '../../../helpers/lib';
import { sendYandexMetrikaEvent } from '../../../helpers/yandexMetrika';
import { oneEvent } from '../../../app/constants/urls';
import {
  EVENT_FORMAT_AMO,
  EVENT_TITLE_DOD,
  EVENT_TITLE_MK,
  EVENT_TYPE,
} from '../../../app/constants/events';
import SpecificEvent from './SpecificEvent';
import EventNotFound from '../../../shared/EventNotFound/EventNotFound';
import EventSkeleton from './EventSkeleton';
import BasicEvent from './BasicEvent';
import MkOnline from './MkOnline';
import MkOffline from './MkOffline';
import DodOnline from './DodOnline';
import { recommendEvents } from '../model/suggestions';
import ModalBlock from '../../../shared/ModalBlock/ModalBlock';
import EventRequestModal from '../../../shared/Modals/EventRequestModal';
import ProjectsPresentation from './ProjectsPresentation';

const TIMEOUT_30_SECONDS = 30 * 1000;
const TIMEOUT_60_SECONDS = 60 * 1000;

const data = {
  description: {
    title: 'Что такое Bootcamp?',
    subtitle: 'Формат',
    data: {
      title:
        'Это интенсивный формат обучения программированию (ежедневно, с утра до вечера) на реальных проектах с трудоустройством после обучения',
      subtitle:
        'В интервью с ИТ-Бородой рассказываем о формате образования в школе программирования Bootcamp',
      video: 'https://www.youtube.com/embed/Kh_tvgwOtog',
      description: '1 час 12 минут',
    },
  },
  questions: {
    message: 'Остались вопросы?',
    actions: {
      first: {
        title: 'Получить консультацию',
      },
      second: {
        title: 'Посмотреть Программы',
        link: '/#program',
      },
    },
  },
  ymEvents: {
    ymAnyEventsRegistration: 'eventsRegistration',
    MEETING: 'eventsRegistrationDod',
    MASTER_KLASS: 'eventsRegistrationMk',
    ymEventAnnouncements: 'eventsSubscribe',
    consultationFormSubmit: 'eventsBottomConsultation',
  },
};

export const getInfo = async (data, ac) => {
  const isOldUrl = !isNaN(+data);
  let url;
  if (isOldUrl) {
    url = `${oneEvent}?id=${data}`;
  } else {
    url = `${oneEvent}?url_slug=${data}`;
  }
  const response = await fetch(url, { signal: ac.signal });
  const [event] = await response.json();
  return event;
};

const extraLeadData = {
  leadAction: 'Регистрация на мероприятие',
  leadPage: 'Мероприятие',
};

const mainData = {
  leadPage: 'Мероприятие',
};

const leadData = {
  questions: {
    ...mainData,
    eventName: 'Остались вопросы',
    leadAction: 'Получить консультацию',
    leadBlock: 'Остались вопросы',
  },
  subscribe: {
    ...mainData,
    eventName: 'Анонсы событий',
    leadAction: 'Подписка новости',
    leadBlock: 'Анонсы событий',
  },
};

function EventDetail({
  id,
  stats,
  greetingsImgs,
  requestLeadData,
  events,
  ...props
}) {
  const [loading, setLoading] = useState(true);
  const [eventData, setEventData] = useState();
  const [currentEvent, setCurrentEvent] = useState(null);
  const [commonLeadData, setCommonLeadData] = useState({});

  const [noUrl, setNoUrl] = useState(false);
  const { titleEnum, subTitleEnum, city, description, eventType } =
    eventData || {};
  const [urlSlug, setUrlSlug] = useState(false);
  const isOldUrl = !isNaN(+id);

  useEffect(() => {
    const AC = new AbortController();
    sendYandexMetrikaEvent('newEventsPageView');
    if (!isOldUrl) {
      getEventInfo(id, AC).then((data) => {
        setLoading(false);
        if (!data) {
          setNoUrl(true);
        } else {
          setEventData(data);
        }
      });
    } else {
      getInfo(id, AC).then((data) => {
        setUrlSlug(data.url_slug);
      });
    }
    return () => {
      AC.abort();
    };
  }, [id]);

  useEffect(() => {
    let timeout30, timeout60;
    if (!noUrl) {
      timeout30 = setTimeout(() => {
        sendYandexMetrikaEvent(`event_30_seconds_${eventData.urlSlug}`);
      }, TIMEOUT_30_SECONDS);
      timeout60 = setTimeout(() => {
        sendYandexMetrikaEvent(`event_60_seconds_${eventData.urlSlug}`);
      }, TIMEOUT_60_SECONDS);
    }
    return () => {
      clearTimeout(timeout30);
      clearTimeout(timeout60);
    };
  }, [eventData, noUrl]);

  useEffect(() => {
    if (eventData) {
      const { date, title, city, tags } = eventData;

      setCommonLeadData((prev) => {
        const updatedData = { ...prev };

        if (date) {
          updatedData.date = date;
        }

        if (title) {
          updatedData.eventName = title || '';
          updatedData.program = generateProgram(title);
        }

        if (city) {
          updatedData.tags = generateTags(city, title, tags);
          updatedData.format = EVENT_FORMAT_AMO[city];
        }

        return updatedData;
      });
    }
  }, [eventData]);

  if (isOldUrl) {
    if (urlSlug) {
      return <Redirect to={`/events/${urlSlug}`} noThrow />;
    }
    return null;
  }

  if (noUrl) {
    return <EventNotFound />;
  }
  if (loading || !eventData) {
    return <EventSkeleton />;
  }
  const suggestEvents = recommendEvents(
    { city, titleEnum, subTitleEnum },
    events.filter((event) => event.url_slug !== eventData.urlSlug),
  );
  const metricsPrefix = `${eventData.titleEnum
    .split('_')
    .map((item) => capitalizeText(item))
    .join('')}${capitalizeText(eventData.city)}`;

  const myTargetMetrics = {
    TopFormSent: `${metricsPrefix}TopFormSent`,
    Interview: `${metricsPrefix}Interview`,
    SignUpFormSent: `${metricsPrefix}SignUpFormSent`,
    BottomConsultation: `${metricsPrefix}BottomConsultation`,
    BottomProgram: `${metricsPrefix}BottomProgram`,
    AboutFormSent: `${metricsPrefix}AboutFormSent`,
  };

  const ymSubmitEventTypesExtended = [
    'lead',
    'eventRegistration',
    `event_lead_${eventData.urlSlug}`,
  ];
  if (titleEnum in EVENT_TITLE_DOD) {
    ymSubmitEventTypesExtended.push('eventRegistrationDod');
  }
  if (titleEnum in EVENT_TITLE_MK) {
    ymSubmitEventTypesExtended.push('eventRegistrationMk');
  }

  const applyModal = (
    <ModalBlock
      isOpen={!!currentEvent}
      handleClose={() => setCurrentEvent(null)}
    >
      {!!currentEvent && (
        <EventRequestModal
          extraLeadData={{
            ...requestLeadData,
            eventName: currentEvent.title,
            event: currentEvent.eventType,
            eventLocation: currentEvent.location,
            format: currentEvent.amoCity,
            date: currentEvent.amoDate,
            tags: Array.isArray(currentEvent.tags)
              ? [...currentEvent.tags]
              : [currentEvent.tags],
            subTitleEnum: currentEvent.subTitleEnum,
            titleEnum: currentEvent.titleEnum,
            city: currentEvent.city,
          }}
          location={currentEvent.location}
          startTime={currentEvent.startTime}
          date={currentEvent.date}
          formData={{
            title: 'Запись на мероприятие',
            sentContent: {
              title: 'Вы успешно записались на мероприятие!',
              message:
                'Скоро вам на почту придет ссылка для подключения в Zoom',
            },
          }}
          ymSubmitEventType={data.ymEvents.ymAnyEventsRegistration}
          ymSpecialEventType={currentEvent.ymMetriks.signUp}
        />
      )}
    </ModalBlock>
  );

  if (subTitleEnum === 'PRESENTATION_OF_PROJECTS') {
    return (
      <>
        <ProjectsPresentation
          eventData={eventData}
          requestLeadData={requestLeadData}
          greetingsImgs={greetingsImgs}
          statistic={props.statisticBlock}
          suggestEvents={suggestEvents}
          setCurrentEvent={setCurrentEvent}
          commonLeadData={commonLeadData}
        />
        {applyModal}
      </>
    );
  }

  if (eventType === EVENT_TYPE.MEETING) {
    return (
      <>
        <DodOnline
          eventData={eventData}
          requestLeadData={requestLeadData}
          greetingsImgs={greetingsImgs}
          statistic={props.statisticBlock}
          suggestEvents={suggestEvents}
          leadData={leadData}
          setCurrentEvent={setCurrentEvent}
          commonLeadData={commonLeadData}
        />
        {applyModal}
      </>
    );
  }

  if (subTitleEnum === 'MASTER_KLASS' && city !== 'ONLINE') {
    return (
      <>
        <MkOffline
          eventData={eventData}
          requestLeadData={requestLeadData}
          greetingsImgs={greetingsImgs}
          statistic={props.statisticBlock}
          suggestEvents={suggestEvents}
          leadData={leadData}
          setCurrentEvent={setCurrentEvent}
          commonLeadData={commonLeadData}
        />
        {applyModal}
      </>
    );
  }
  if (subTitleEnum === 'MASTER_KLASS' && city === 'ONLINE') {
    return (
      <>
        <MkOnline
          eventData={eventData}
          requestLeadData={requestLeadData}
          greetingsImgs={greetingsImgs}
          statistic={props.statisticBlock}
          suggestEvents={suggestEvents}
          setCurrentEvent={setCurrentEvent}
          commonLeadData={commonLeadData}
        />
        {applyModal}
      </>
    );
  }
  if (eventType === EVENT_TYPE.MEETING && eventData.city === 'ONLINE') {
    return (
      <SpecificEvent
        eventData={eventData}
        extraLeadData={extraLeadData}
        ymSubmitEventTypesExtended={ymSubmitEventTypesExtended}
        greetingsImgs={greetingsImgs}
        requestLeadData={requestLeadData}
        motivate={props.motivate}
        reviews={props.reviews}
        youWillKnow={props.youWillKnow}
        stats={stats}
        myTargetMetrics={myTargetMetrics}
        leadData={leadData}
        questionsData={data.questions}
      />
    );
  }
  return (
    <BasicEvent
      description={description}
      loading={loading}
      eventData={eventData}
      ymSubmitEventTypesExtended={ymSubmitEventTypesExtended}
      myTargetMetrics={myTargetMetrics}
      stats={stats}
      extraLeadData={extraLeadData}
      leadData={leadData}
      questionsData={data.questions}
      descriptionData={data.description}
    />
  );
}

export default EventDetail;
