import { Grid } from '@mui/material';
import React from 'react';
import Section from '../../shared/Section/Section';
import EventSpeakerBlock from '../EventSpeakerBlock/EventSpeakerBlock';
import styles from './styles';

function EventSpeakersBlock({ speakers }) {
  const notEmptySpeakers = speakers?.filter((person) => person.name) || [];
  return (
    <Section title="Спикер" bg="dod-speaker" customStyles={styles.container}>
      <Grid container>
        {notEmptySpeakers.map((speaker, index) => (
          <EventSpeakerBlock {...speaker} key={index} />
        ))}
      </Grid>
    </Section>
  );
}

export default EventSpeakersBlock;
