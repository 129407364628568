import React from 'react';

export default function SvgImage() {
  return (
    <svg
      width="605"
      height="578"
      viewBox="0 0 605 578"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="97" y="86" width="448" height="408" rx="24" fill="white" />
      <mask
        id="mask0_2278_9925"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="605"
        height="578"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M121 0C107.745 0 97 10.7452 97 24V35.4706L77.5 24L11 73L0 323V578H77.5L88.5 519.5L93 482.5V457L97 443.5V470C97 483.255 107.745 494 121 494H581C594.255 494 605 483.255 605 470V24C605 10.7452 594.255 0 581 0H121Z"
          fill="#D9D9D9"
        />
      </mask>
      <g mask="url(#mask0_2278_9925)">
        <g clipPath="url(#clip0_2278_9925)">
          <path
            d="M319.795 316.207C305.086 375.759 246.731 485.94 223.666 488.718C204.553 491.016 132.689 441.376 109.598 418.239C96.6508 405.281 146.357 327.535 158.637 338.649C175.375 353.798 215.551 385.698 222.331 388.984C226.87 391.201 273.079 330.687 291.846 306.482C317.473 273.38 325.615 292.616 319.795 316.207Z"
            fill="#FAF3E9"
          />
          <path
            d="M55.112 384.736C67.8189 400.312 106.26 422.033 117.552 423.689L163.094 345.943C158.903 334.989 112.693 290.104 92.6186 285.803C72.5439 281.502 41.9246 368.572 55.112 384.736Z"
            fill="#FAF3E9"
          />
          <path
            d="M82.4761 451.053L55.7402 444.749L93.4958 284.359L120.232 290.663L82.4761 451.053Z"
            fill="#C7C7C7"
          />
          <path
            d="M88.6157 273.112C92.2195 275.17 95.9034 276.853 99.6674 278.162C100.575 278.509 101.509 278.776 102.417 279.044C103.245 279.311 104.099 279.525 104.926 279.738C105.487 279.925 106.101 280.086 106.662 280.192L132.849 286.177C130.474 285.643 128.124 284.975 125.829 284.147C123.88 283.479 121.958 282.677 120.063 281.795C118.301 280.994 116.512 280.086 114.75 279.097C71.2905 254.411 57.3023 184.733 83.5436 123.524C103.752 76.3689 141.899 47.9688 178.258 48.7169C182.769 48.7971 187.254 49.3314 191.686 50.3466L165.498 44.3353C165.444 44.3086 165.391 44.3086 165.284 44.3086C164.163 44.0414 163.042 43.8277 161.894 43.6407C160.906 43.4537 159.945 43.2934 158.957 43.2132C158.504 43.1598 158.05 43.0796 157.596 43.0529C156.848 42.9461 156.101 42.8926 155.353 42.8659C154.926 42.8392 154.473 42.8125 154.072 42.7858C131.728 41.6369 108.557 51.6558 89.2296 70.3843C88.2419 71.3462 87.2542 72.308 86.3199 73.2965C74.8677 85.1054 64.8837 100.04 57.4091 117.566C31.1411 178.748 45.1026 248.426 88.6157 273.112Z"
            fill="#263238"
          />
          <path
            d="M254.578 220.801C227.91 282.998 174.653 317.356 127.723 306.616L101.562 300.604C148.439 311.398 201.722 277.013 228.417 214.789C259.117 143.001 242.699 61.2472 191.738 32.2326C187.494 29.8548 183.169 27.851 178.791 26.3281C176.068 25.3663 173.345 24.5915 170.569 23.9503L196.73 29.9616C199.506 30.6028 202.256 31.4043 204.979 32.3662C209.357 33.889 213.681 35.8661 217.899 38.2706C268.914 67.2585 285.331 149.039 254.578 220.801Z"
            fill="#263238"
          />
          <path
            d="M43.7668 109.765C70.4886 47.3543 124.039 12.9162 171.103 24.0572C172.144 24.2976 173.211 24.5915 174.226 24.8854C174.52 24.9388 174.786 25.0457 175.107 25.1259C176.361 25.4999 177.589 25.9007 178.817 26.3281C181.006 27.1296 183.169 27.9846 185.331 28.9464C187.493 29.9616 189.656 31.0036 191.738 32.2326C242.726 61.2472 259.116 143.028 228.444 214.789C218.914 237.045 205.966 255.72 191.124 270.067C188.855 272.284 186.532 274.368 184.156 276.372C181.807 278.376 179.378 280.246 176.922 282.089C174.52 283.853 172.037 285.536 169.528 287.085C168.3 287.86 167.018 288.608 165.737 289.356C161.973 291.52 158.102 293.444 154.231 295.074C154.231 295.074 154.231 295.1 154.205 295.1C151.642 296.196 149.026 297.184 146.41 298.039C134.53 301.94 122.411 303.409 110.585 302.1C109.704 302.047 108.876 301.94 107.995 301.78C106.58 301.593 105.166 301.352 103.751 301.058C103.617 301.058 103.511 301.032 103.43 301.005C100.04 300.337 96.7032 299.402 93.3931 298.253C88.9884 296.704 84.6638 294.753 80.4726 292.349C29.3782 263.334 12.9607 181.58 43.7668 109.765ZM88.6146 273.112C92.2185 275.17 95.9024 276.853 99.6664 278.162C100.574 278.509 101.508 278.776 102.416 279.044C103.244 279.311 104.098 279.525 104.925 279.738C109.917 281.021 114.989 281.715 120.061 281.795C156.42 282.597 194.568 254.197 214.776 207.041C240.99 145.86 226.975 76.1819 183.516 51.4153C181.781 50.4001 180.019 49.4917 178.23 48.7169C176.335 47.8353 174.439 47.0337 172.491 46.3658C170.168 45.5376 167.846 44.8697 165.47 44.3353C165.416 44.3086 165.363 44.3086 165.256 44.3086C164.135 44.0415 163.014 43.8277 161.866 43.6407C160.878 43.4537 159.917 43.2934 158.93 43.2132C158.476 43.1598 158.022 43.0796 157.568 43.0529C156.821 42.9461 156.073 42.8926 155.326 42.8659C154.899 42.8392 154.445 42.8125 154.044 42.7858C131.701 41.6369 108.529 51.6558 89.2019 70.3843C88.2142 71.3462 87.2265 72.308 86.2922 73.2965C74.84 85.1054 64.856 100.04 57.3814 117.566C31.1401 178.748 45.1016 248.426 88.6146 273.112Z"
            fill="#455A64"
          />
          <path
            opacity="0.4"
            d="M114.776 279.124C116.538 280.139 118.326 281.047 120.088 281.822C156.447 282.624 194.594 254.224 214.802 207.068C241.017 145.886 227.002 76.2086 183.542 51.442C181.807 50.4268 180.045 49.5184 178.257 48.7436C141.898 47.9688 103.751 76.3689 83.569 123.578C57.3277 184.76 71.2893 254.437 114.776 279.124Z"
            fill="white"
          />
          <g opacity="0.3">
            <path
              d="M125.081 68.0065L225.748 169.825C227.163 161.436 227.91 153.1 227.99 144.925L141.578 57.5334C135.945 60.3654 130.393 63.8654 125.081 68.0065Z"
              fill="#EBEBEB"
            />
            <path
              d="M165.978 49.5985L223.426 107.708C216.832 83.0482 203.191 62.6364 183.517 51.442C181.781 50.4268 180.019 49.5184 178.231 48.7436C174.2 48.6367 170.062 48.9306 165.978 49.5985Z"
              fill="#EBEBEB"
            />
            <path
              d="M113.815 77.9185L221.85 187.191C222.864 183.557 223.772 179.924 224.52 176.317L120.809 71.453C118.433 73.4568 116.111 75.6208 113.815 77.9185Z"
              fill="#EBEBEB"
            />
            <path
              d="M71.2628 203.461C71.8768 209.125 72.8378 214.629 74.1192 219.972L134.157 280.673C137.76 280.059 141.391 279.151 144.941 277.948L71.2628 203.461Z"
              fill="#EBEBEB"
            />
            <path
              d="M72.6238 160.447L173.451 262.426C183.355 254.785 192.512 244.98 200.467 233.304L86.2117 117.727C85.3041 119.624 84.4231 121.574 83.5689 123.551C78.3633 135.654 74.7328 148.104 72.6238 160.447Z"
              fill="#EBEBEB"
            />
          </g>
          <path
            d="M72.6248 523.771L32.1817 514.259L63.1213 382.785L103.564 392.323L72.6248 523.771Z"
            fill="#263238"
          />
          <path
            d="M114.189 296.917C118.273 306.776 104.338 305.948 104.338 305.948C104.338 305.948 124.092 311.986 124.386 323.768C124.626 335.122 107.835 333.519 106.634 333.439C107.675 333.76 125.694 339.798 124.76 351.66C123.772 364.003 107.194 359.488 106.127 359.194C107.008 359.809 118.62 368.198 115.844 377.041C111.092 392.243 80.8464 371.618 68.2997 374.557C68.2997 374.557 60.8784 390.026 60.825 390.694C60.825 390.694 41.6045 378.057 38.588 360.878C37.013 351.821 45.1283 348.561 45.1283 348.561C45.1283 348.561 35.0375 343.458 35.4914 334.107C35.7316 329.298 43.6333 325.397 43.6333 325.397C43.6333 325.397 35.7316 320.989 36.6392 312.68C37.3333 306.054 45.155 303.169 45.155 303.169C45.155 303.169 39.5223 295.394 42.4587 288.875C48.305 275.624 105.433 275.838 114.189 296.917Z"
            fill="#FAF3E9"
          />
          <path
            d="M46.3019 302.501C46.115 302.528 46.0616 302.261 46.2485 302.207C65.0419 297.692 87.092 298.494 105.085 305.921C105.672 306.161 105.538 307.23 104.844 307.096C84.8229 302.955 66.7503 300.925 46.3019 302.501Z"
            fill="#263238"
          />
          <path
            d="M43.3124 325.264C43.0988 325.291 43.0454 324.917 43.2857 324.863C62.693 320.321 87.4928 321.898 107.22 332.905C107.888 333.279 108.128 334.294 107.327 333.92C93.6594 327.588 61.732 323.741 43.3124 325.264Z"
            fill="#263238"
          />
          <path
            d="M107.193 360.397C87.8656 350.592 67.3371 347.947 45.8742 348.294C45.6073 348.294 45.6073 347.893 45.8475 347.84C56.312 344.821 70.0066 346.023 80.6046 347.866C90.7487 349.63 99.5314 353.263 107.54 359.729C107.86 359.969 107.62 360.61 107.193 360.397Z"
            fill="#263238"
          />
          <path
            d="M104.337 305.948C108.021 307.47 111.598 309.287 114.909 311.451C118.165 313.615 121.315 316.26 122.89 319.573C123.664 321.23 123.878 323.02 123.371 324.703C122.837 326.413 121.903 328.042 120.621 329.218C118.085 331.649 114.081 332.344 110.637 331.97L101.961 331.035L109.783 334.935C113.2 336.645 116.564 338.916 119.233 341.641C121.903 344.34 123.825 347.733 123.558 351.153C123.344 354.733 121.209 357.565 117.738 358.259C114.348 359.114 110.237 358.553 106.686 357.484L96.7558 354.546L105.138 360.557C107.407 362.187 109.623 364.217 111.411 366.381C113.173 368.572 114.615 371.083 114.828 373.595C115.042 376.079 113.894 378.698 111.812 379.847C109.65 381.102 106.606 381.156 103.75 380.942C97.9037 380.434 92.0842 378.644 86.2113 377.148C83.2748 376.373 80.3383 375.599 77.3485 374.984C74.3853 374.423 71.262 373.782 68.2721 374.503C71.2887 374.102 74.2518 375.064 77.1349 375.812C80.018 376.667 82.8743 377.656 85.7574 378.644C91.4969 380.621 97.263 382.545 103.456 383.453C106.526 383.801 109.89 384.041 113.173 382.438C116.537 380.755 118.245 376.908 117.978 373.381C117.711 369.801 115.95 366.809 113.974 364.297C111.945 361.786 109.703 359.649 107.087 357.698L105.539 360.744C109.73 362.026 113.974 362.748 118.566 361.786C120.808 361.278 123.131 359.996 124.652 358.019C126.174 356.015 126.868 353.691 127.001 351.34C127.268 346.477 124.545 342.256 121.529 339.29C118.406 336.245 114.908 333.947 111.038 332.077L110.183 335.042C114.588 335.363 119.153 334.241 122.356 330.981C123.931 329.352 124.892 327.348 125.453 325.21C126.014 323.02 125.587 320.615 124.572 318.691C122.463 314.818 118.886 312.493 115.416 310.489C111.919 308.566 108.155 307.07 104.337 305.948Z"
            fill="#263238"
          />
          <path
            d="M163.068 345.943C163.068 345.943 115.284 255.105 94.8356 286.124C88.7491 295.341 74.8143 340.439 83.8105 343.405C95.4229 347.252 104.766 329.512 108.851 317.463C108.851 317.463 120.036 344.554 141.232 342.389C154.526 341 163.068 345.943 163.068 345.943Z"
            fill="#FAF3E9"
          />
          <path
            d="M96.7034 283.639C93.7669 286.845 91.7381 290.799 89.8695 294.7C88.0008 298.627 86.5059 302.742 85.1711 306.883C82.6084 315.218 80.3393 323.554 79.2448 332.371C79.0046 334.588 78.8444 336.859 79.2715 339.317C79.4851 340.519 79.8855 341.855 80.713 343.004C81.1134 343.592 81.5139 344.046 82.208 344.607C82.8487 345.061 83.4893 345.382 84.13 345.595C86.7461 346.504 89.4423 346.103 91.7381 345.195C94.0072 344.206 95.9293 342.79 97.6378 341.187C101.001 337.954 103.377 334.107 105.379 330.153C107.408 326.199 108.93 322.085 110.345 317.943L107.515 317.997C108.93 321.123 110.612 323.928 112.507 326.68C114.402 329.405 116.565 331.943 118.994 334.214C121.423 336.458 124.199 338.382 127.163 339.825C130.126 341.294 133.356 342.149 136.586 342.389C133.409 341.722 130.393 340.546 127.643 338.89C124.893 337.26 122.491 335.149 120.329 332.852C118.14 330.554 116.218 328.016 114.562 325.317C112.907 322.646 111.412 319.76 110.318 316.901L108.93 313.268L107.488 316.955C105.94 320.909 104.392 324.89 102.336 328.577C100.334 332.264 98.0916 335.871 95.1551 338.596C92.3254 341.267 88.4813 343.271 85.3313 342.202C82.4749 341.481 82.3414 336.752 82.6885 332.718C83.0355 328.55 83.8364 324.329 84.6372 320.108C85.4381 315.886 86.319 311.692 87.3868 307.524C89.6559 299.295 91.9517 290.879 96.7034 283.639Z"
            fill="#263238"
          />
          <path
            d="M176.495 348.321L127.723 436.567C127.723 436.567 212.987 498.336 227.91 492.699C254.952 482.44 305.566 368.679 318.326 328.844C331.941 286.444 320.115 265.391 285.171 311.238C256.821 348.428 223.105 386.579 221.877 386.659C220.675 386.713 176.495 348.321 176.495 348.321Z"
            fill="#4520AB"
          />
          <path
            d="M214.644 380.434C222.492 387.221 229.379 394.915 236.107 402.663C239.444 406.51 242.54 410.518 245.637 414.552C248.68 418.506 251.67 422.674 254.046 427.029C254.126 427.189 253.859 427.323 253.752 427.216C250.148 423.636 246.838 419.708 243.608 415.808C240.351 411.907 237.014 408.06 233.757 404.159C227.271 396.331 220.437 388.77 214.19 380.755C214.03 380.515 214.404 380.221 214.644 380.434Z"
            fill="#263238"
          />
          <path
            d="M190.645 368.572C187.681 373.034 185.065 377.763 182.342 382.385C179.646 387.007 176.977 391.682 174.36 396.358C169.155 405.735 164.003 415.14 158.984 424.624C157.596 427.216 156.235 429.807 154.9 432.426C153.485 435.231 152.39 438.223 150.949 441.002C150.842 441.215 151.162 441.402 151.296 441.189C152.604 438.918 154.259 436.807 155.621 434.563C157.035 432.212 158.397 429.861 159.758 427.483C162.401 422.808 165.044 418.132 167.66 413.457C172.866 404.079 178.044 394.675 182.956 385.137C185.733 379.74 188.669 374.396 191.125 368.839C191.285 368.492 190.858 368.278 190.645 368.572Z"
            fill="#263238"
          />
          <path
            opacity="0.1"
            d="M206.608 486.608C216.405 491.39 224.28 494.062 227.91 492.672C254.926 482.413 305.54 368.679 318.354 328.817C325.321 307.043 325.615 290.906 319.501 286.524L206.608 486.608Z"
            fill="black"
          />
          <path
            d="M325.242 277.227C325.242 277.227 322.039 249.682 321.131 249.147C320.224 248.64 306.956 271.696 279.38 303.944C278.606 304.825 298.414 313.001 298.414 313.001L325.242 277.227Z"
            fill="#5C34CA"
          />
          <path
            d="M309.279 283.586C309.279 283.586 294.623 322.646 278.366 373.328C265.739 412.709 190.005 711.35 190.032 713.808C190.085 718.671 357.25 717.896 364.084 715.705C370.918 713.514 369.45 520.618 368.542 474.318C367.634 428.017 356.022 336.752 330.902 285.937C325.643 275.277 309.279 283.586 309.279 283.586Z"
            fill="#5C34CA"
          />
          <path
            d="M457.143 477.978C457.143 477.978 317.714 754.231 313.95 769.941C307.036 798.929 338.643 1087.82 338.643 1087.82L196.305 1088.54C196.305 1088.54 159.866 809.642 176.23 746.35C186.401 706.996 237.976 568.976 277.324 509.825C282.316 502.317 290.138 477.978 290.138 477.978H457.143Z"
            fill="#FFC727"
          />
          <path
            d="M457.143 477.978C457.143 477.978 317.714 754.231 313.95 769.941C307.036 798.929 338.643 1087.82 338.643 1087.82L196.305 1088.54C196.305 1088.54 159.866 809.642 176.23 746.35C186.401 706.996 237.976 568.976 277.324 509.825C282.316 502.317 290.138 477.978 290.138 477.978H457.143Z"
            fill="#263238"
          />
          <path
            d="M310.693 1041.97C306.182 974.94 300.389 892.838 296.892 826.153C296.171 805.341 294.116 784.207 295.691 763.448C304.58 738.201 317.607 714.263 328.9 689.897C339.364 668.283 350.042 646.749 360.667 625.215L424.762 496.092C408.024 527.992 374.949 592.113 358.745 624.253C342.541 656.447 326.497 688.748 310.987 721.289C304.767 735.075 297.586 748.407 293.155 762.967C291.553 784.101 293.742 805.18 294.516 826.287C298.574 898.262 304.447 970.131 310.693 1041.97Z"
            fill="#263238"
          />
          <path
            opacity="0.2"
            d="M318.888 757.945C344.248 701.706 457.115 478.005 457.115 478.005H327.19L318.888 757.945Z"
            fill="black"
          />
          <path
            d="M528.23 1072.24L664.723 1041.33C664.723 1041.33 512.961 806.73 509.944 787.654C507.595 772.88 468.514 609.292 475.695 581.586C479.512 566.919 459.17 509.103 448.839 477.978H290.11C290.11 477.978 298.786 569.991 315.417 589.895L340.51 603.681C340.51 603.681 374.039 743.464 381.274 795.723C388.588 848.489 528.23 1072.24 528.23 1072.24Z"
            fill="#FFC727"
          />
          <path
            d="M528.23 1072.24L664.723 1041.33C664.723 1041.33 512.961 806.73 509.944 787.654C507.595 772.88 468.514 609.292 475.695 581.586C479.512 566.919 459.17 509.103 448.839 477.978H290.11C290.11 477.978 298.786 569.991 315.417 589.895L340.51 603.681C340.51 603.681 374.039 743.464 381.274 795.723C388.588 848.489 528.23 1072.24 528.23 1072.24Z"
            fill="#263238"
          />
          <path
            d="M432.289 537.263C429.592 536.461 425.455 536.916 413.655 534.591C410.399 533.95 407.195 532.908 404.072 531.759C400.948 530.637 397.798 529.381 395.449 526.924C391.525 522.782 388.909 516.611 386.88 511.348C384.638 505.577 382.475 499.004 381.835 492.806C381.808 492.432 381.247 492.539 381.247 492.913C381.434 496.466 381.354 499.993 381.835 503.573C382.289 506.913 383.143 510.225 384.131 513.458C385.946 519.496 388.162 525.988 392.806 530.504C397.665 535.232 405.06 536.916 411.52 538.198C415.257 538.946 430.073 540.389 432.315 538.519C432.716 538.225 432.796 537.423 432.289 537.263Z"
            fill="#263238"
          />
          <path
            d="M435.04 486.18C440.432 509.05 445.718 531.973 449.829 555.083C452.231 566.678 451.884 578.487 453.699 590.082C455.248 601.758 457.01 613.379 458.772 625.001C467.528 680.145 476.55 735.289 487.415 790.059C487.976 792.944 488.937 796.043 490.272 798.661C495.504 809.348 501.644 819.34 507.677 829.439C532.13 869.622 558.104 908.789 584.078 947.982L623.561 1006.39L585.2 947.234C559.679 907.8 534.159 868.312 509.786 828.157C503.806 818.085 497.693 807.986 492.594 797.539C491.286 794.894 490.512 792.383 489.951 789.471C477.084 723.213 465.605 656.527 455.301 589.842C454.554 584.044 453.539 578.193 453.459 572.422C450.389 543.114 441.82 514.741 435.04 486.18Z"
            fill="#263238"
          />
          <path
            d="M305.701 493.02C305.728 492.539 306.555 492.432 306.555 492.94C306.449 509.183 307.49 523.45 308.851 538.625C309.492 545.679 315.151 584.605 327.351 581.747C329.406 581.266 330.448 579.262 330.768 576.189C331.142 572.476 329.753 567.934 329.139 564.247C327.778 556.098 326.71 547.923 325.989 539.694C324.681 524.332 324.628 508.97 326.497 493.661C326.55 493.153 327.271 493.207 327.271 493.714C327.244 514.981 327.805 536.221 330.981 557.274C332.503 567.4 337.602 582.628 327.031 584.205C316.246 585.781 309.278 560.266 307.97 553.266C304.313 533.763 304.18 512.737 305.701 493.02Z"
            fill="#263238"
          />
          <path
            d="M460.345 515.088L282.075 501.382C281.674 500.981 278.417 483.535 279.138 477.043C280.847 461.734 291.418 449.845 294.007 427.349C299.773 377.335 301.135 317.516 311.172 285.589C315.337 272.364 348.866 264.43 365.283 278.803C444.942 348.561 456.848 459.143 464.402 498.283C466.351 508.355 460.638 514.66 460.345 515.088Z"
            fill="#E9D9FF"
          />
          <path
            d="M355.06 272.071C355.06 272.071 360.239 379.232 358.023 395.877C352.31 438.784 310.426 745.361 311.146 747.739C312.588 752.388 495.45 717.522 501.376 713.488C507.302 709.454 476.363 499.939 462.401 455.803C448.439 411.64 421.504 325.05 371.264 280.7C362.348 272.819 355.06 272.071 355.06 272.071Z"
            fill="#4520AB"
          />
          <path
            opacity="0.1"
            d="M381.805 530.485L446.121 579.752L435.688 593.394L371.372 544.127L381.805 530.485Z"
            fill="black"
          />
          <path
            d="M390.537 286.898C437.627 309.154 513.495 360.397 521.61 377.148C528.791 391.976 520.382 465.608 509.998 492.913C506.127 503.145 428.951 469.215 433.303 458.368C438.535 445.357 453.805 397.854 451.963 394.648C449.934 391.121 394.488 345.088 368.834 312.68C345.609 283.318 372.732 278.483 390.537 286.898Z"
            fill="#FAF3E9"
          />
          <path
            d="M428.791 468.921C407.168 482.52 403.698 489.306 379.352 491.096C363.388 492.245 357.275 502.958 372.518 509.611C397.478 520.511 429.058 508.863 438.855 501.649L428.791 468.921Z"
            fill="#D3766A"
          />
          <path
            d="M428.791 468.921C407.168 482.52 403.698 489.306 379.352 491.096C363.388 492.245 357.275 502.958 372.518 509.611C397.478 520.511 429.058 508.863 438.855 501.649L428.791 468.921Z"
            fill="#FAF3E9"
          />
          <path
            d="M433.33 458.394C421.157 466.009 387.788 493.634 383.784 549.98C382.822 563.339 390.938 583.056 400.415 561.816C407.142 546.721 406.848 535.099 412.374 527.03C412.374 527.03 425.668 550.888 439.096 551.316C445.102 551.503 461.466 551.663 474.227 549.953C474.227 549.953 474.6 547.068 474.547 543.354C474.547 543.354 507.195 526.229 509.998 492.966L433.33 458.394Z"
            fill="#FAF3E9"
          />
          <path
            d="M424.628 473.356C420.837 482.52 413.149 502.103 412.428 520.298C411.627 540.522 415.525 579.663 430.42 580.117C441.365 580.437 441.179 565.369 441.179 565.369C441.179 565.369 446.197 581.212 456.128 579.342C465.177 577.632 464.35 565.984 464.35 565.984C464.35 565.984 469.048 575.067 477.457 571.754C487.575 567.747 476.069 549.366 482.823 536.461C484.398 533.522 424.628 473.356 424.628 473.356Z"
            fill="#FAF3E9"
          />
          <path
            d="M433.918 517.599C431.782 526.817 432.263 548.965 440.832 565.155C441.019 565.529 441.419 565.583 441.312 565.209C435.653 545.385 434.211 525.935 434.105 517.626C434.105 517.492 433.944 517.492 433.918 517.599Z"
            fill="#263238"
          />
          <path
            d="M454.286 518.988C453.245 527.11 454.793 549.953 464.03 566.037C464.244 566.384 464.617 566.411 464.511 566.064C457.757 545.599 455.087 527.271 454.473 518.988C454.473 518.882 454.313 518.882 454.286 518.988Z"
            fill="#263238"
          />
          <path
            d="M525.241 488.665L433.25 448.002L449.107 395.743C428.151 372.152 375.322 325.371 363.282 306.883C349.16 285.189 365.658 274.235 389.897 284.628C445.343 308.379 521.077 365.152 527.564 378.778C536.854 398.281 525.241 488.665 525.241 488.665Z"
            fill="#4520AB"
          />
          <path
            d="M521.077 463.765C515.872 460.372 463.896 436.594 450.495 433.441C450.148 433.361 449.881 433.735 450.202 433.868C456.155 436.487 514.777 462.562 520.917 464.032C521.131 464.139 521.237 463.872 521.077 463.765Z"
            fill="#263238"
          />
          <path
            d="M373.159 319.333C395.262 344.313 424.84 370.923 447.451 395.53C447.504 395.61 447.851 396.037 447.878 396.117C447.958 396.331 447.771 395.77 447.825 395.823C443.5 413.323 437.707 430.475 433.142 447.948L433.116 448.082L433.223 448.109L435.625 448.83L433.303 447.868L433.383 448.028C439.523 430.769 446.116 413.564 450.388 395.743C450.441 395.61 450.174 394.835 450.121 394.862C448.946 393.072 447.398 391.549 446.01 390.026C422.758 365.82 397.104 342.897 373.159 319.333Z"
            fill="#263238"
          />
          <path
            d="M373.106 321.978C375.108 324.623 377.858 326.921 381.168 327.588C378.712 325.291 376.283 323.207 373.106 321.978Z"
            fill="#263238"
          />
          <path
            d="M330.448 312.066C339.231 311.478 355.542 293.124 361.068 278.563C361.441 277.574 358.665 267.983 355.862 256.174C354.18 249.014 352.472 240.972 351.457 233.545L306.449 251.285C306.449 251.285 313.177 266.246 314.885 279.204C315.019 280.299 315.045 281.315 314.992 282.303C314.992 282.437 314.965 282.624 314.992 282.811C314.832 288.742 316.7 312.974 330.448 312.066Z"
            fill="#FAF3E9"
          />
          <path
            d="M355.381 254.117C347.906 269.479 335.36 285.215 314.991 285.803C314.938 284.547 314.911 283.559 314.938 282.838C314.938 282.651 314.964 282.464 314.938 282.33C315.018 281.341 314.991 280.299 314.831 279.231C313.122 266.246 306.422 251.312 306.422 251.312L351.43 233.572C352.364 240.277 353.859 247.491 355.381 254.117Z"
            fill="#263238"
          />
          <path
            d="M314.405 276.773L325.243 304.024L359.146 269.719L368.676 280.807C368.676 280.807 335.467 335.39 335.253 332.932L324.122 309.448L307.891 325.718L309.333 284.761L314.405 276.773Z"
            fill="#E9D9FF"
          />
          <path
            d="M307.437 305.387C307.891 298.627 308.184 291.814 308.958 285.082C308.985 284.841 309.359 284.734 309.412 285.028C310.747 291.547 308.825 317.383 308.825 323.581C308.852 323.554 308.878 323.527 308.905 323.501C309.572 322.806 323.934 309.18 324.095 309.421C326.524 313.161 334.693 330.26 335.226 331.81C340.485 323.127 360.613 291.627 366.833 283.425C367.02 283.185 367.367 283.399 367.234 283.666C362.589 292.83 335.947 335.283 335.547 335.149C335.28 335.363 325.109 314.283 323.908 310.329C321.478 313.295 306.983 328.657 306.93 327.642C306.636 320.829 306.983 312.146 307.437 305.387Z"
            fill="#263238"
          />
          <path
            d="M380.18 200.87C380.233 218.53 357.569 262.666 342.94 269.613C321.717 279.685 294.008 272.872 287.227 248.934C280.66 225.743 311.199 168.756 326.896 163.172C350.041 154.943 380.126 174.767 380.18 200.87Z"
            fill="#FAF3E9"
          />
          <path
            d="M365.312 240.438C357.97 239.503 356.662 232.342 356.689 227.507C356.742 222.698 368.675 198.278 368.088 190.771C367.474 183.263 362.081 176.985 355.995 176.29C349.908 175.622 337.442 177.787 333.598 175.729C329.754 173.672 334.933 166.619 332.13 165.443C326.016 162.852 313.203 175.248 309.279 181.5C309.279 181.5 320.677 151.524 353.592 163.065C382.984 173.378 382.236 195.393 381.088 205.091C378.899 223.659 365.312 240.438 365.312 240.438Z"
            fill="#263238"
          />
          <path
            d="M356.368 234.079C356.368 234.079 371.718 222.484 377.324 228.415C382.93 234.373 367.981 253.582 359.011 253.956C350.068 254.33 349.534 246.048 349.534 246.048L356.368 234.079Z"
            fill="#FAF3E9"
          />
          <path
            d="M372.893 233.812C373.026 233.839 372.999 234.026 372.866 234.052C366.539 234.507 361.894 238.755 358.077 243.457C360.426 241.934 363.042 241.533 365.178 244.419C365.285 244.579 365.071 244.766 364.938 244.659C362.802 243.43 361.013 243.27 358.878 244.632C357.356 245.567 356.075 246.983 354.793 248.239C354.366 248.667 353.539 248.105 353.939 247.571C353.966 247.544 353.993 247.491 354.019 247.464C356.208 240.251 364.991 232.049 372.893 233.812Z"
            fill="#263238"
          />
          <path
            d="M324.868 216.205C324.948 216.099 325.028 216.473 324.975 216.579C323.373 219.518 322.038 223.152 324.227 225.503C324.308 225.583 324.201 225.717 324.121 225.663C321.051 223.526 322.893 218.717 324.868 216.205Z"
            fill="#263238"
          />
          <path
            d="M329.112 214.442C334.078 216.499 329.593 226.304 324.975 224.408C320.436 222.511 324.948 212.705 329.112 214.442Z"
            fill="#263238"
          />
          <path
            d="M308.129 208.671C308.156 208.538 307.836 208.698 307.782 208.832C306.474 211.904 304.472 215.217 301.269 214.976C301.162 214.976 301.108 215.137 301.215 215.163C304.766 216.232 307.382 211.797 308.129 208.671Z"
            fill="#263238"
          />
          <path
            d="M306.874 204.263C302.176 201.645 297.291 211.263 301.642 213.694C305.94 216.072 310.799 206.454 306.874 204.263Z"
            fill="#263238"
          />
          <path
            d="M335.279 211.717C333.89 211.129 332.689 210.354 331.354 209.66C329.859 208.858 328.578 208.351 327.777 206.774C327.323 205.919 327.777 204.61 328.551 204.103C330.367 202.927 332.449 203.461 334.264 204.37C336.266 205.358 337.548 206.614 338.482 208.618C339.363 210.568 337.121 212.465 335.279 211.717Z"
            fill="#263238"
          />
          <path
            d="M304.179 198.813C305.113 200.015 306.154 200.95 307.195 202.045C308.37 203.274 309.171 204.37 310.906 204.771C311.84 204.984 313.015 204.21 313.282 203.328C313.949 201.271 312.881 199.401 311.547 197.878C310.078 196.194 308.53 195.286 306.341 194.939C304.205 194.565 302.951 197.236 304.179 198.813Z"
            fill="#263238"
          />
          <path
            d="M316.299 245.834C315.258 246.209 314.083 246.85 312.935 246.449C311.841 246.048 310.826 245.006 310.025 244.178C309.945 244.098 309.812 244.151 309.812 244.285C310.052 245.808 311.387 247.304 312.908 247.705C314.403 248.105 315.605 247.224 316.539 246.102C316.646 245.941 316.459 245.754 316.299 245.834Z"
            fill="#263238"
          />
          <path
            d="M314.562 239.182C311.199 238.568 302.63 234.159 302.416 233.999C302.096 233.785 302.016 233.411 302.122 233.037V233.01C302.176 232.904 305.326 226.625 305.326 226.625C304.285 226.652 299.026 226.598 299.666 225.396C304.952 215.591 310.852 204.664 318.193 196.007C318.433 195.714 318.887 196.034 318.7 196.355C313.975 206 307.595 214.736 302.71 224.327C304.338 224.434 307.061 224.514 308.262 225.102C308.556 225.343 304.125 232.85 304.098 232.904C307.782 234.987 309.063 235.468 314.669 238.354C315.71 238.888 315.63 239.369 314.562 239.182Z"
            fill="#263238"
          />
          <path
            d="M298.334 185.828C298.334 185.828 306.049 166.432 313.283 159.592C320.491 152.753 332.477 143.963 340.592 144.604C344.303 144.898 357.25 158.443 359.172 158.924C364.377 160.233 376.497 159.218 379.994 162.237C387.709 168.863 384.906 202.099 384.906 202.099C384.906 202.099 351.831 194.805 337.976 191.653C324.148 188.473 298.334 185.828 298.334 185.828Z"
            fill="#4520AB"
          />
          <path
            d="M298.335 185.828C298.335 185.828 324.122 188.473 338.004 191.653C351.832 194.805 384.907 202.072 384.907 202.072C384.907 202.072 385.201 198.653 385.334 193.763C379.488 192.027 356.744 185.401 342.889 183.077C337.843 182.222 310.187 178.909 301.805 177.92C299.696 182.382 298.335 185.828 298.335 185.828Z"
            fill="#E9D9FF"
          />
          <path
            d="M399.588 216.526C399.588 216.526 349.188 201.164 332.289 198.973C315.391 196.782 279.754 193.389 279.754 193.389L298.36 185.802C298.36 185.802 322.466 188.179 332.236 189.542C341.98 190.905 384.932 202.046 384.932 202.046L399.588 216.526Z"
            fill="#4520AB"
          />
          <path
            d="M332.584 327.027L324.148 309.795L311.228 322.325L313.417 335.71L325.323 337.981L332.584 327.027Z"
            fill="#29EDFF"
          />
          <path
            d="M325.322 337.981C325.322 337.981 322.332 423.502 291.66 457.994C261.441 491.951 213.363 485.512 213.363 485.512L173.267 467.184L205.888 443.273C205.888 443.273 242.22 442.872 265.151 424.785C287.655 407.045 313.416 335.71 313.416 335.71L325.322 337.981Z"
            fill="#29EDFF"
          />
          <path
            d="M379.139 280.647C379.139 280.647 364.75 249.949 363.389 249.361C362.054 248.8 354.179 274.555 331.808 310.65C331.194 311.638 359.064 320.669 359.064 320.669L379.139 280.647Z"
            fill="#5C34CA"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_2278_9925">
          <rect
            width="657.768"
            height="1148"
            fill="white"
            transform="matrix(-1 0 0 1 686 22)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
