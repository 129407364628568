import React from 'react';
import EventMkOfflineInfoBlock from '../../../widgets/EventMkOfflineInfoBlock/EventMkOfflineInfoBlock';
import { mkOfflineData as data } from '../model';
import { Grid } from '@mui/material';
import { useInView } from 'react-intersection-observer';
import MkOfflineSlidingGroup from '../../../widgets/MkOfflineSlidingGroup/MkOfflineSlidingGroup';

export default function MkOffline({
  eventData,
  greetingsImgs,
  statistic,
  suggestEvents,
  leadData = { leadData },
  setCurrentEvent,
  commonLeadData,
}) {
  const [hideBlockRef, hideInView] = useInView({
    threshold: 0.1,
    delay: 1,
  });
  return (
    <>
      <EventMkOfflineInfoBlock
        externalHide={hideInView}
        eventData={eventData}
        greetingsImgs={greetingsImgs}
        data={data.mkOfflineIntroduce}
      />
      <MkOfflineSlidingGroup
        outsideRef={hideBlockRef}
        greetingsImgs={greetingsImgs}
        data={data}
        eventData={eventData}
        statistic={statistic}
        leadData={leadData}
        events={suggestEvents}
        setCurrentEvent={setCurrentEvent}
        commonLeadData={commonLeadData}
      />
    </>
  );
}
