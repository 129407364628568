const styles = {
  wrapper: (theme) => ({
    mt: 3,
    [theme.breakpoints.down('lg')]: { height: '56px' },
    [theme.breakpoints.down('sm')]: {
      padding: '0 20px',
    },
  }),
  filterGroup: (theme) => ({
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      width: '100%',
      overflowX: 'scroll',
      overflowY: 'hidden',
      height: 'fit-content',
      width: {
        xs: 'calc(100% + 16px*2)',
        md: '100%',
      },
      marginLeft: '-16px',
      padding: '0 20px',
      '::-webkit-scrollbar': {
        display: 'none',
      },
    },
    '&::after': {
      content: '""',
      display: { xs: 'none', xl: 'flex' },
      position: 'absolute',
      height: '29px',
      width: '2px',
      backgroundColor: 'kit.text.h',
      right: '12px',
      top: '50%',
      transform: 'translate(50%, -50%)',
    },

    '&:last-child': {
      // marginRight: 0,
      '&::after': {
        display: 'none',
      },
    },
  }),
  filterWrapper: {
    whiteSpace: 'nowrap',
  },
  filters: {
    marginBottom: {
      xs: '17px',
      lg: '32px',
    },
    paddingBottom: {
      xs: '10px',
      md: '0',
    },
    ' -ms-overflow-style': 'none',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  filter: (theme) => ({
    height: { xs: 28, md: 32 },
    backgroundColor: '#C0AAFE',
    color: 'kit.text.h',
    fontSize: 16,
    p: theme.spacing(0.75, 2.25),
    [theme.breakpoints.down('sm')]: {
      p: theme.spacing(0.75, 1.25),
    },
    '& .MuiChip-label': {
      p: 0,
    },
    '&.active': {
      backgroundColor: 'kit.background.purple',
      color: 'kit.text.orange',
    },
    '&.active-inverse': {
      backgroundColor: 'kit.text.orange',
      color: 'kit.background.purple',
    },
    '&:not(:last-child)': {
      marginRight: { xs: 1, lg: 3 },
    },
  }),
};

export default styles;
