export const styles = {
  mainContainer: (theme) => ({
    display: 'block',
    position: 'relative',
    padding: '32px 0',
    [theme.breakpoints.down('sm')]: {
      padding: '32px 16px',
    },
  }),
  container: (theme) => ({
    color: 'kit.text.main',
    backgroundColor: 'kit.background.gray',
    borderRadius: '16px',
    padding: '24px 16px',
    [theme.breakpoints.up('md')]: {
      padding: '48px 70px 48px 30px',
    },
    [theme.breakpoints.up('lg')]: {
      padding: '48px 99px 48px 30px',
    },
    [theme.breakpoints.up('xl')]: {
      padding: '0',
      '::before': {
        borderRadius: '24px',
        margin: 'auto',
        top: 0,
        right: 0,
        bottom: 0,
        left: '50%',
        transform: 'translateX(-50%)',
        backgroundColor: 'kit.background.gray',
        content: "''",
        position: 'absolute',
        width: 'calc(100% + 72px)',
        height: '100%',
      },
    },
    [theme.breakpoints.up('xxl')]: {
      '::before': {
        left: '50%',
        transform: 'translateX(-50%)',
      },
    },
  }),
  imgContainer: (theme) => ({
    [theme.breakpoints.up('md')]: {
      marginTop: 0,
    },
    [theme.breakpoints.up('xl')]: {
      position: 'relative',
      paddingLeft: '64px',
    },
  }),
  imgBox: (theme) => ({
    maxWidth: '100%',
    overflow: 'hidden',
    borderRadius: '8px',
    lineHeight: 0,
    [theme.breakpoints.up('xl')]: {
      position: 'relative',
    },
    [theme.breakpoints.down('lg')]: {
      maxWidth: '567px',
    },
    height: {
      md: '338px',
    },
    img: {
      width: '100%',
      height: {
        xs: 'auto',
        md: '100%',
      },
      objectFit: {
        md: 'cover',
      },
    },
  }),
  content: {
    position: 'relative',
    paddingRight: {
      md: '62px',
    },
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: {
      xs: '14px',
      md: '18px',
    },
    lineHeight: 1.4,
    '*': {
      display: 'block',
      marginBottom: {
        xs: '12px',
        md: '16px',
      },
      '&:last-child': {
        marginBottom: {
          xs: '32px',
          xl: 0,
        },
      },
    },
    h1: {
      fontFamily: 'RF Rostin',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: {
        xs: '22px',
        md: '34px',
      },
      lineHeight: '130%',
      letterSpacing: '-0.06em',
      color: 'kit.text.main',
      marginBottom: {
        xs: '16px',
        md: '26px',
      },
    },
  },
};
