import { Box } from '@mui/material';
import React from 'react';
import VideoReviewCard from '../Cards/VideoReviewCard';
import SwiperComponent from '../SwiperComponent/SwiperComponent';
import Section from '../Section/Section';

export default function EventReviews({ reviews, previewsImg }) {
  return (
    <Section title={reviews.title} bg={'event-review'}>
      <Box>
        <SwiperComponent
          slidesPerView={1.2}
          spaceBetween={4}
          breakpoints={{
            375: {
              slidesPerView: 1.3,
              spaceBetween: 12,
            },
            400: {
              slidesPerView: 1.4,
              spaceBetween: 4,
            },
            535: {
              slidesPerView: 1.5,
              spaceBetween: 8,
            },
            700: {
              slidesPerView: 2,
              spaceBetween: 16,
            },
            1010: {
              slidesPerView: 3,
              spaceBetween: 24,
            },
            1410: {
              slidesPerView: 4,
              spaceBetween: 24,
            },
          }}
          centeredSlides={false}
          slides={reviews.list.map(
            (review) =>
              function () {
                return (
                  <Box
                    sx={{
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                    key={review.fullName}
                  >
                    <VideoReviewCard
                      {...review}
                      height="100%"
                      onlyVideo
                      image={previewsImg[`previews/${review.video}`]}
                    />
                  </Box>
                );
              },
          )}
        />
      </Box>
    </Section>
  );
}
