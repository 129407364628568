import React, { useState } from 'react';
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { IconButton, Link } from 'gatsby-theme-material-ui';
import { FillVkIcon } from '../../kit/icons/FillVkIcon';
import { FillTelegramIcon } from '../../kit/icons/FillTelegramIcon';
import { FillYouTubeIcon } from '../../kit/icons/FillYouTubeIcon';
import { TickSuccessIcon } from '../../kit/icons/TickSuccessIcon';
import { sendYandexMetrikaEvent } from '../../helpers/yandexMetrika';
import {
  getRoistatFromCookie,
  getUtmFromCookie,
} from '../../helpers/utmCookie';
import { EMAIL_REGEX } from '../../shared/Forms/tools';
import styles from './styles';
import { apiMyLead } from '../../app/constants/urls';
import { sendLeadData } from '../../helpers/amoLead';

const errorText = 'Что-то пошло не так';

export default function SubscribeFeature({ leadData, ymSubmitEventType }) {
  const isMd = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleSubscribeClick = async (data) => {
    let clientId = '';

    try {
      /*eslint-disable */
      clientId = ga && ga.getAll()[0].get('clientId');
      /* eslint-enable */
    } catch (e) {}

    try {
      setIsError(false);
      setIsLoading(true);
      await sendLeadData(data, {
        pipelineType: 'subscribeNew',
      });
      // await fetch( apiMyLead, {
      //   method: 'POST',
      //   headers: {
      //     'Content-Type': 'application/json',
      //   },
      //   body: JSON.stringify({
      //     ...data,
      //     ...leadData,
      //     pipelineType: 'subscribeNew',
      //     clientId,
      //     utm: getUtmFromCookie(),
      //     roistatVisit: getRoistatFromCookie(),
      //   }),
      // });

      sendYandexMetrikaEvent('eventsSubscribe');
      sendYandexMetrikaEvent(ymSubmitEventType);
      setIsSubscribed(true);
    } catch (error) {
      setIsError(true);
      console.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {!isSubscribed && (
        <Box>
          <Box
            onSubmit={handleSubmit(handleSubscribeClick)}
            sx={styles.textFieldBlock}
            component="form"
          >
            <Box sx={styles.textFieldWrapper}>
              <TextField
                size="small"
                placeholder="Email"
                label="Email"
                sx={styles.input}
                helperText={
                  (isError && errorText) ||
                  (errors.email && errors.email.message)
                }
                {...register('email', {
                  required: 'Введите адрес почты',
                  pattern: {
                    value: EMAIL_REGEX,
                    message: 'Некорректно введенный email',
                  },
                })}
              />
            </Box>
            <Button
              variant="kitPrimary"
              size="medium"
              type="submit"
              disabled={isLoading}
            >
              Подписаться
            </Button>
          </Box>
          <Box sx={styles.agreement}>
            <Typography variant="mobileP3" align={isMd ? 'center' : 'left'}>
              Подписываясь, вы принимаете условия&nbsp;
              <Link
                href="https://elbrusboot.camp/privacy-policy/"
                variant="kitSecondary"
                sx={styles.agreementLink}
              >
                Соглашения
              </Link>
            </Typography>
          </Box>
        </Box>
      )}
      {isSubscribed && (
        <Grid container sx={{ mt: 3 }}>
          <Grid item xs={12} md={2}>
            <Box sx={styles.subscribeMessage}>
              <Typography color="kit.text.main" variant="desktopP2">
                Вы успешно подписались на нашу рассылку!
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={2.5} sx={styles.successButton}>
            <Button
              variant="kitPrimary"
              size="large"
              disabled
              sx={styles.subscribed}
            >
              <TickSuccessIcon
                sx={{ width: 12.67, height: 10, color: 'kit.text.main' }}
              />
            </Button>
          </Grid>

          <Grid
            item
            xs={12}
            md={3}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Box sx={styles.subscribeMessage}>
              <Typography
                color="kit.text.main"
                component="div"
                variant="desktopP2"
                sx={{
                  fontSize: 16,
                  textAlign: {
                    xs: 'center',
                    md: 'left',
                  },
                }}
              >
                Хотим ещё больше дать вам пользы. Подпишитесь на наш канал и
                соцсети.
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={2.5}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Link href="https://vk.com/elbrusbootcamp" target="_blank">
              <IconButton>
                <FillVkIcon sx={{ width: 40, height: 40 }} />
              </IconButton>
            </Link>
            <Link href="https://t.me/elbrusbootcamp1" target="_blank">
              <IconButton>
                <FillTelegramIcon sx={{ width: 40, height: 40 }} />
              </IconButton>
            </Link>

            <Link
              href="https://www.youtube.com/c/ElbrusCodingBootcamp"
              target="_blank"
            >
              <IconButton>
                <FillYouTubeIcon sx={{ width: 40, height: 40 }} />
              </IconButton>
            </Link>
          </Grid>
        </Grid>
      )}
    </>
  );
}
