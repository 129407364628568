import React from 'react';
import { SvgIcon } from '@mui/material';

export default function PointA(props) {
  return (
    <SvgIcon sx={{ width: 32, height: 44 }} viewBox="0 0 32 44" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.0312 25.2859C30.9003 22.6676 32 19.4622 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 19.4586 1.09735 22.6608 2.96287 25.2776L14.0819 42.6104C14.9785 44.0081 17.0209 44.0081 17.9175 42.6104L29.0312 25.2859Z"
        fill="#FFBC5B"
      />
      <path
        d="M17.9839 20.4323H14.0239L13.2439 23.8523H10.8439L14.1639 9.85229H17.8439L21.1639 23.8523H18.7639L17.9839 20.4323ZM17.4639 18.1923L15.9839 11.7723L14.5239 18.1923H17.4639Z"
        fill="#4520AB"
      />
    </SvgIcon>
  );
}
