import { Box, Grid, Typography } from '@mui/material';
import React from 'react';

export default function PlatesList({ list }) {
  return (
    <Grid
      container
      rowSpacing={2}
      columnSpacing={3}
      sx={{
        mt: { xs: 1, lg: 0 },
      }}
    >
      {list.map((item, index, list) => {
        const isLastItem = index === list.length - 1;
        return (
          <Grid item xs={12} md={6} key={item}>
            <Box
              sx={{
                display: 'flex',
                minHeight: '92px',

                padding: { xs: 2, lg: 3 },
                alignItems: 'center',
                gap: 2,
                flexShrink: '0',
                borderRadius: 6,
                background: isLastItem ? '#FFBBF0' : '#E2E5FF',
              }}
            >
              {isLastItem && (
                <Box height={44}>
                  <svg
                    width="44"
                    height="44"
                    viewBox="0 0 44 44"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      width="44"
                      height="44"
                      rx="14"
                      fill="white"
                      fillOpacity="0.8"
                    />
                    <path
                      d="M21.9998 7.61536C14.0555 7.61536 7.61523 14.0556 7.61523 22C7.61523 29.9443 14.0555 36.3846 21.9998 36.3846C29.9442 36.3846 36.3845 29.9443 36.3845 22C36.3845 14.0556 29.9442 7.61536 21.9998 7.61536ZM21.9998 29.3543C21.2055 29.3543 20.5614 28.7101 20.5614 27.9158C20.5614 27.1215 21.2055 26.4773 21.9998 26.4773C22.7942 26.4773 23.4383 27.1215 23.4383 27.9158C23.4383 28.7101 22.7942 29.3543 21.9998 29.3543ZM23.4383 24.2535H20.5614V14.646H23.4383V24.2535Z"
                      fill="#4520AB"
                    />
                  </svg>
                </Box>
              )}
              <Typography
                variant="desktopP2"
                color={'kit.text.main'}
                fontSize={16}
              >
                {item}
              </Typography>
            </Box>
          </Grid>
        );
      })}
    </Grid>
  );
}
