const styles = {
  container: (theme) => ({
    padding: '32px',
    backgroundColor: '#FFF',
    marginBottom: '16px',
    boxShadow: '0px 36px 56px rgba(61, 33, 136, 0.04)',
    borderRadius: '24px',
    display: 'flex',
    gap: 0,
    alignItems: 'flex-start',
    [theme.breakpoints.down('lg')]: {
      padding: '28px',
    },
    [theme.breakpoints.down('md')]: {
      padding: '24px',
      flexDirection: 'column',
    },
  }),
  speakerPhoto: (theme) => ({
    display: 'inline-block',
    width: '265px',
    height: '265px',
    borderRadius: '20px',
    boxShadow: 'none',
    [theme.breakpoints.down('md')]: {
      borderRadius: '12px',
      width: '160px',
      height: '160px',
    },
  }),
  image: {
    height: '100%',
  },
  speakerInfo: (theme) => ({
    width: '100%',
    marginLeft: '40px',
    maxWidth: '620px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    flexWrap: 'nowrap',
    padding: '0px',
    gap: '10px',
    [theme.breakpoints.down('lg')]: {
      marginLeft: '14px',
      maxWidth: '620px',
      gap: '0',
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: '520px',
      marginLeft: '0',
    },
  }),
  speakerName: (theme) => ({
    fontSize: '34px',
    color: '#000',
    lineHeight: '44px',
    letterSpacing: '-0.06em',
    [theme.breakpoints.down('md')]: {
      marginTop: '24px',
      fontSize: '22px',
      lineHeight: '100%',
    },
  }),
  speakerDescription: (theme) => ({
    margin: '32px 0 48px',
    fontFamily: 'Inter',
    fontSize: '16px',
    color: '#000000',
    lineHeight: '22px',
    [theme.breakpoints.down('lg')]: {
      margin: '24px 0 32px',
    },
    [theme.breakpoints.down('md')]: {
      margin: '16px 0 32px',
      fontSize: '14px',
    },
  }),
  speakerJobsContainer: (theme) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    fontSize: '16px',
    width: '100%',
    gap: '20px',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      gap: '7px',
    },
  }),
  speakerJob: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    gap: '16px',
  },
  speakerJobHeader: {
    color: 'kit.link.main',
    fontFamily: 'Inter',
  },
  speakerJobTitle: {
    fontFamily: 'RF Rostin',
    textTransform: 'uppercase',
    fontWeight: '700',
    flex: 1,
    margin: '6px 0',
    color: 'kit.link.main',
  },
  companyLogo: {
    height: '31px',
    width: 'auto',
  },
  arrowBlock: (theme) => ({
    display: 'flex',
    alignSelf: 'center',
    [theme.breakpoints.down('md')]: {
      alignSelf: 'start',
    },
  }),
  arrow: (theme) => ({
    alignSelf: 'start',
    width: '58px',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  }),
  arrowDown: (theme) => ({
    marginLeft: '4px',
    marginTop: '-10px',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  }),
};

export default styles;
