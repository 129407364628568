import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import Section from '../../../shared/Section/Section';
import ContactsModal from '../../../shared/Modals/ContactsModal';
import { parseDate, generateTags, generateProgram } from '../../../helpers/lib';
import { EVENT_FORMAT_AMO } from '../../../app/constants/events';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import ModalBlock from '../../../shared/ModalBlock/ModalBlock';
import EventRequestModal from '../../../shared/Modals/EventRequestModal';
import { data } from '../../../pages-fsd/EventsPage/ui/AllEvents';
import { useBackgroundsQuery } from '../../../app/queries';
import styles from './styles';
import EventCountdownTimer from '../../../shared/EventCountdownTimer/EventCountdownTimer';
import MailIcon from '../../../shared/MailIcon/MailIcon';
import IconsBlock from './IconsBlock';
import { getMetrik } from '../../../pages-fsd/EventsPage/model/metriks';

const formData = {
  title: 'Запись на мероприятие',
  sentContent: {
    title: 'Вы успешно записаны на мероприятие!',
  },
  titleStyle: {
    textAlign: 'center',
    lineHeight: '32px',
    marginTop: {
      xs: '24px',
      md: '10px',
    },
  },
};

const cities = {
  ONLINE: 'Онлайн',
  MSK: 'Москва',
  SPB: 'Санкт-Петербург',
};

const mailInfoBlock = (
  <Box sx={styles.mailInfoBlock}>
    <MailIcon bgColor="#F8F6FC" size={36} />
    <Typography
      variant="desktopP1"
      color={'kit.text.main'}
      letterSpacing={-1.26}
      lineHeight={'normal'}
      fontFamily={'RF Rostin'}
    >
      После регистрации на мероприятие, вы получите письмо на почту
    </Typography>
  </Box>
);

export function EventMkSubscribeModule({
  outsideRef,
  city,
  amoCity,
  title,
  titleEnum,
  subTitleEnum,
  date,
  startTime,
  duration,
  fullLocation,
  location,
  tags,
  extraLeadData,
  requestLeadData,
  ymSubmitEventTypes = [],
  eventType,
  program,
}) {
  const isSm = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [startDate, setStartDate] = useState(null);
  const [leadData, setLeadData] = useState({});
  const [subscribeModalIsOpen, setSubscribeModalIsOpen] = useState(false);
  const { bgImgs } = useBackgroundsQuery();
  const desktopBg = getImage(bgImgs[`backgrounds/mk-subscribe-desktop`]);
  const mobileBg = getImage(bgImgs[`backgrounds/mk-subscribe-mobile`]);
  const isAdvanced = titleEnum.includes('ADVANCED');
  const ymEvent = getMetrik(
    program,
    subTitleEnum,
    city,
    `${isAdvanced ? 'Pro' : 'Beg'}BottomSignUp`,
  );
  useEffect(() => {
    if (date) {
      setStartDate(() => parseDate(date));
      setLeadData((prev) => ({
        ...prev,
        date,
      }));
    }
  }, [date]);
  useEffect(() => {
    setStartDate(() => parseDate(date));
    setLeadData((prev) => ({
      ...prev,
      eventName: title || '',
      program: generateProgram(title),
    }));
  }, [title]);

  useEffect(() => {
    setLeadData((prev) => ({
      ...prev,
      eventName: title,
      tags: generateTags(city, title, tags),
      format: EVENT_FORMAT_AMO[city],
    }));
  }, [city]);

  return (
    <>
      <Box sx={styles.wrapper}>
        <Box sx={styles.imgContainer}>
          <GatsbyImage
            style={{ height: '100%' }}
            objectFit="cover"
            image={isSm ? mobileBg : desktopBg}
            alt=""
            loading="lazy"
          />
        </Box>
        <Box sx={styles.contentContainer}>
          <Grid
            container
            color={'kit.text.main'}
            justifyContent="space-between"
          >
            <Grid item xs={12} md={6} sx={styles.firstBlock}>
              <Typography component="h2" variant="desktopH2">
                Записывайся скорее!
              </Typography>
              <EventCountdownTimer
                timestamp={new Date(date).getTime()}
                date={date}
                overTimerHandler={() => {}}
              />
              <IconsBlock
                startDate={startDate}
                startTime={startTime}
                duration={duration}
                city={cities[city]}
                fullLocation={fullLocation}
                location={location}
              />
              {!isSm && mailInfoBlock}
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              lg={6}
              sx={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              <Box ref={outsideRef} sx={styles.form}>
                <ContactsModal
                  leadData={{
                    ...extraLeadData,
                    ...leadData,
                    titleEnum,
                    subTitleEnum,
                  }}
                  formData={formData}
                  buttonLabel="Записаться"
                  ymSubmitEventTypes={[ymEvent]}
                />
              </Box>
            </Grid>
            {isSm && mailInfoBlock}
          </Grid>
          <ModalBlock
            isOpen={subscribeModalIsOpen}
            handleClose={() => setSubscribeModalIsOpen(false)}
          >
            {/* <EventRequestModal
              extraLeadData={{
                eventName: title,
                event: eventType,
                eventLocation: location,
                format: amoCity,
                date,
                tags: Array.isArray(tags) ? [...tags] : [tags],
                ...requestLeadData,
                ...extraLeadData,
              }}
              location={location}
              startTime={startTime}
              date={parseDate(date)}
              formData={{
                title: title,
                subtitle: eventType,
              }}
              ymSpecialEventType="eventsRegistrationDod"
              ymSubmitEventTypes={[...ymSubmitEventTypes, ymEvent]}
            /> */}
          </ModalBlock>
        </Box>
      </Box>
    </>
  );
}

export default function EventMkOnlineSubscribe({ bg, customStyles, ...props }) {
  return (
    <Section
      bg={bg}
      withoutBg={!bg}
      theme={'default'}
      customStyles={{
        padding: {
          xs: '32px 0 32px',
          lg: '52px 0 0px',
        },
        ...customStyles,
      }}
    >
      <EventMkSubscribeModule {...props} />
    </Section>
  );
}
