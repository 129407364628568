import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';
import {
  Box,
  Grid,
  Chip,
  Tabs,
  Tab,
  Typography,
  useMediaQuery,
  CircularProgress,
} from '@mui/material';
import EventCard from '../../../shared/EventCard/EventCard';
import Section from '../../../shared/Section/Section';
import ModalBlock from '../../../shared/ModalBlock/ModalBlock';
import EventRequestModal from '../../../shared/Modals/EventRequestModal';
import styles from './allEventsStyles';
import Subscribe from '../../../widgets/Subscribe/Subscribe';
import Questions from '../../../widgets/Questions/Questions';
import useScrollToAnchor from '../../../helpers/useScrollToAnchor';
import { useEventsQuery } from '../../../app/queries/events.query';
import { Button } from 'gatsby-theme-material-ui';

const GRID_START_WIDTH = 4.8;
export const data = {
  questions: {
    message: 'Остались вопросы?',
    actions: {
      first: {
        title: 'Получить консультацию',
      },
      second: {
        title: 'Посмотреть Программы',
        link: '/#program',
      },
    },
  },
  ymEvents: {
    ymAnyEventsRegistration: 'eventsRegistration',
    MEETING: 'eventsRegistrationDod',
    MASTER_KLASS: 'eventsRegistrationMk',
    ymEventAnnouncements: 'eventsSubscribe',
    consultationFormSubmit: 'eventsBottomConsultation',
  },
};

const mainData = {
  leadPage: 'Бесплатные мероприятия',
};

const leadData = {
  questions: {
    ...mainData,
    eventName: 'Оставьте ваши контакты для связи',
    leadAction: 'Получить консультацию',
    leadBlock: 'Остались вопросы',
  },
  subscribe: {
    ...mainData,
    eventName: 'Анонсы событий',
    leadAction: 'Подписка новости',
    leadBlock: 'Анонсы событий',
  },
};

const programTabs = [
  {
    value: 'ALL',
    label: 'Все мероприятия',
  },
  {
    value: 'JS',
    label: 'Веб-разработка  ',
  },
  {
    value: 'DS',
    label: 'Data Science',
  },
  {
    value: 'DESIGN',
    label: 'UX / UI дизайн  ',
  },
];

const filtersList = [
  {
    type: 'location',
    list: [
      {
        location: 'Онлайн',
        filterKey: 'ONLINE',
      },
      {
        location: 'Москва',
        filterKey: 'MSK',
      },
      {
        location: 'Петербург',
        filterKey: 'SPB',
      },
    ],
  },
  {
    type: 'eventType',
    list: [
      {
        eventType: 'мастер-классы',
        filterKey: 'MK',
      },
      {
        eventType: 'знакомство с IT',
        filterKey: 'DOD',
      },
      {
        eventType: 'презентация проектов',
        filterKey: 'PROM',
      },
    ],
  },
];

const EVENT_CITY = {
  ONLINE: 'Онлайн',
  MSK: 'Москва',
  SPB: 'Петербург',
};

const EVENT_TYPES = {
  PRESENTATION_OF_PROJECTS: 'презентация проектов',
  MASTER_KLASS: 'мастер-классы',
  MEETING: 'знакомство с IT',
};
const URL_EVENT_TYPES = {
  PROM: 'PRESENTATION_OF_PROJECTS',
  MK: 'MASTER_KLASS',
  DOD: 'MEETING',
};

const getFilterKeyByEventType = (eventType) => {
  const entry = Object.entries(URL_EVENT_TYPES).find(
    ([key, val]) => val === eventType,
  );
  return entry ? entry[0] : undefined;
};

const createFiltersList = (events) => {
  const programs = ['ALL'];
  const filters = [
    {
      type: 'location',
      createLabel(item) {
        return EVENT_CITY[item.city];
      },
      list: [],
    },
    {
      type: 'eventType',
      createLabel(item) {
        return EVENT_TYPES[item.subTitleEnum];
      },
      createFilterKey(item) {
        return getFilterKeyByEventType(item.subTitleEnum);
      },
      list: [],
    },
  ];

  events.forEach((event) => {
    if (event.program in programTabs) {
      programs.push(event.program);
    }
    filters.forEach((filter) => {
      const field = filter.type === 'location' ? 'city' : 'subTitleEnum';
      const filterKey = filter.createFilterKey
        ? filter.createFilterKey(event)
        : event[field];

      if (!filter.list.find((el) => el.filterKey === filterKey)) {
        filter.list.push({
          [filter.type]: filter.createLabel
            ? filter.createLabel(event)
            : event.subTitleEnum,
          filterKey: filterKey,
        });
      }
    });
  });
  const programsOrder = ['ALL', 'JS', 'DS', 'DESIGN'];
  programs.sort((a, b) => {
    return (
      programsOrder.indexOf(a.filterKey) - programsOrder.indexOf(b.filterKey)
    );
  });
  const filtersOrder = ['ONLINE', 'MSK', 'SPB', 'MK', 'DOD', 'PROM'];
  filters.forEach((filter) => {
    filter.list.sort((a, b) => {
      return (
        filtersOrder.indexOf(a.filterKey) - filtersOrder.indexOf(b.filterKey)
      );
    });
  });

  return { filters, programs };
};

export default function AllEvents({
  events,
  requestLeadData,
  titleH1 = false,
  isLoading,
}) {
  const location = useLocation();
  const images = useEventsQuery();
  useScrollToAnchor();
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const locationFilters = {};
    const typeFilters = {};
    for (const [key, value] of searchParams.entries()) {
      if (key === 'program') {
        setactiveProgramTab(value);
      }
      if (key in EVENT_CITY) {
        locationFilters[key] = value;
      }
      if (key in URL_EVENT_TYPES) {
        typeFilters[key] = value;
      }
    }
    setActiveLocationFilters(Object.keys(locationFilters));
    setActiveTypeFilters(Object.keys(typeFilters));
  }, [location]);
  const searchParams = new URLSearchParams(location.search);
  const isSm = useMediaQuery((t) => t.breakpoints.down('sm'));
  const [activeProgramTab, setactiveProgramTab] = useState('ALL');
  const [activeLocationFilters, setActiveLocationFilters] = useState([]);
  const [activeTypeFilters, setActiveTypeFilters] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [currentEvent, setCurrentEvent] = useState(null);
  const [cutList, setCutList] = useState(true);
  const [uncatButton, setUncatButton] = useState(false);

  useEffect(() => {
    const newList = events.filter(
      (event) =>
        (activeProgramTab === 'ALL' || event.program === activeProgramTab) &&
        (!activeLocationFilters.length ||
          activeLocationFilters.some((filter) => filter === event.city)) &&
        (!activeTypeFilters.length ||
          activeTypeFilters.some(
            (filter) => event.subTitleEnum === URL_EVENT_TYPES[filter],
          )),
    );
    const cutLength = isSm ? 4 : Infinity;
    setCutList(newList.length > cutLength);

    if (!uncatButton && newList.length > cutLength) {
      newList.length = cutLength;
    }

    setFilteredEvents(() => newList);
  }, [
    events,
    activeProgramTab,
    activeLocationFilters,
    activeTypeFilters,
    uncatButton,
  ]);
  const handleFilter = (item, program) => {
    if (program) {
      if (program === 'ALL') {
        searchParams.delete(item);
      } else {
        searchParams.set(item, program);
      }
    } else {
      if (!searchParams.get(item)) {
        searchParams.set(item, true);
      } else {
        searchParams.delete(item);
      }
    }
    navigate(`?${searchParams.toString()}`);
  };

  const handleTabChange = (_, program) => {
    handleFilter('program', program);
    setactiveProgramTab(program);
  };
  const onClearFilters = () => {
    setactiveProgramTab(() => 'ALL');
    setActiveLocationFilters(() => []);
    setActiveTypeFilters(() => []);
    navigate('');
  };
  return (
    <>
      <Section
        flexGrow
        title={isSm ? 'Все мероприятия' : 'Бесплатные мероприятия'}
        titleH1={titleH1}
        theme="medium"
        customStyles={{
          overflow: 'hidden',
          paddingTop: { xs: 4, lg: 4 },
          paddingBottom: { xs: 5, lg: 0 },
        }}
        titleSx={{ marginBottom: -1.5 }}
      >
        <Tabs
          value={activeProgramTab}
          onChange={handleTabChange}
          aria-label="Выбор типа событий"
        >
          {programTabs.map((programTab) => (
            <Tab
              key={programTab.label}
              label={programTab.label}
              value={programTab.value}
            />
          ))}
        </Tabs>
        <Box sx={styles.wrapper}>
          <Box sx={styles.filters}>
            <Grid
              container
              columnSpacing={2}
              rowGap={1}
              sx={{
                width: {
                  xs: '130%',
                  md: 'auto',
                },
              }}
            >
              {filtersList.map((filter, index) => (
                <Grid
                  key={filter.type}
                  item
                  xs={12}
                  xl={GRID_START_WIDTH + (12 - GRID_START_WIDTH * 2) * index}
                  sx={styles.filterGroup}
                >
                  {filter.type === 'location' && (
                    <Typography
                      component={'span'}
                      variant="desktopTitle"
                      color={'kit.text.h'}
                      mr={{ xs: 1, lg: 3 }}
                      fontSize={{ xs: 14, lg: 22 }}
                      fontFamily={{ xs: 'Inter', md: 'RF Rostin' }}
                      letterSpacing={-1}
                    >
                      Локация:
                    </Typography>
                  )}
                  {filter.list.map(
                    ({ [filter.type]: filterLabel, filterKey }) => (
                      <Chip
                        className={
                          filter.type === 'location' &&
                          activeLocationFilters.includes(filterKey)
                            ? 'active'
                            : filter.type === 'eventType' &&
                                activeTypeFilters.includes(filterKey)
                              ? 'active-inverse'
                              : ''
                        }
                        key={filterKey + filterLabel}
                        label={filterLabel}
                        sx={styles.filter}
                        onClick={() => handleFilter(filterKey)}
                      />
                    ),
                  )}
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
        {isLoading && (
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              py: 5,
            }}
          >
            <CircularProgress />
          </Box>
        )}

        {!!filteredEvents.length && !isLoading && (
          <Grid container rowGap={{ xs: 3, lg: 5 }} mt={5}>
            {filteredEvents.map((event, index) => {
              const imageType = Object.entries(URL_EVENT_TYPES).find(
                (entry) => entry[1] === event.subTitleEnum,
              )[0];
              const image = images[`events/${imageType}`];

              return (
                <EventCard
                  key={index}
                  {...event}
                  startGrid={GRID_START_WIDTH}
                  image={image}
                  clickHandler={() => setCurrentEvent(event)}
                />
              );
            })}
          </Grid>
        )}
        {!uncatButton && cutList && (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
            <Button variant="outlined" onClick={() => setUncatButton(true)}>
              Показать еще
            </Button>
          </Box>
        )}
        <ModalBlock
          isOpen={!!currentEvent}
          handleClose={() => setCurrentEvent(null)}
        >
          {!!currentEvent && (
            <EventRequestModal
              buttonLabel="записаться"
              extraLeadData={{
                ...requestLeadData,
                eventName: currentEvent.title,
                event: currentEvent.eventType,
                eventLocation: currentEvent.location,
                format: currentEvent.amoCity,
                date: currentEvent.amoDate,
                tags: Array.isArray(currentEvent.tags)
                  ? [...currentEvent.tags]
                  : [currentEvent.tags],
                subTitleEnum: currentEvent.subTitleEnum,
                titleEnum: currentEvent.titleEnum,
                city: currentEvent.city,
              }}
              location={currentEvent.location}
              startTime={currentEvent.startTime}
              date={currentEvent.date}
              formData={{
                title: currentEvent.title,
                subtitle: 'запись на мероприятие',
                titleStyle: {
                  width: '100%',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box !important',
                  WebkitLineClamp: '2',
                  lineClamp: '2',
                  maxLines: '2',
                  WebkitBoxOrient: 'vertical',
                  lineHeight: {
                    xs: '25px',
                    xl: '30px',
                  },
                },
              }}
              ymSubmitEventType={data.ymEvents.ymAnyEventsRegistration}
              ymSpecialEventType={data.ymEvents[currentEvent.filterKey]}
            />
          )}
        </ModalBlock>
      </Section>
      {!filteredEvents.length && !isLoading && (
        <Section
          bg={'events-not-found'}
          position={'bottom'}
          customStyles={{ height: { xs: 680, lg: 400 }, padding: { xs: 0 } }}
        >
          <Typography
            variant="desktopH3"
            mt={{ xs: 2, lg: 5 }}
            color={'kit.text.main'}
            fontSize={{ xs: 42, lg: 34 }}
            lineHeight={{ xs: 1.25, lg: 1 }}
          >
            Нет подходящих мероприятий
          </Typography>
          <Typography
            variant="desktopP1"
            maxWidth={762}
            mt={{ xs: 3, lg: 4.25 }}
            color={'kit.text.main'}
            fontSize={'20px !important'}
            fontFamily={'RF Rostin'}
            letterSpacing={-1.24}
          >
            Похоже, мы не смогли найти для вас мероприятие по выбранным
            параметрам. У нас есть много других событий, вы точно найдете что-то
            интересное
            {isSm ? '!' : ' - сбросьте фильтр или посмотрите все мероприятия!'}
          </Typography>
          <Box mt={{ xs: 3.5, lg: 7.5 }}>
            <Button
              variant="kitPrimary"
              size="large"
              onClick={onClearFilters}
              fullWidth={isSm}
            >
              смотреть мероприятия
            </Button>
          </Box>
        </Section>
      )}

      {!!filteredEvents.length && (
        <Subscribe
          leadData={leadData.subscribe}
          ymSubmitEventType={[data.ymEvents.ymEventAnnouncements]}
        />
      )}
      <Questions
        {...data.questions}
        extraLeadData={leadData.questions}
        ymEvents={data.ymEvents}
        anchor={'questions'}
      />
    </>
  );
}
