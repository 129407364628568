const styles = {
  top: {
    display: {
      xs: 'flex',
      md: 'block',
    },
    lineHeight: {
      md: 0,
    },
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
    marginBottom: '24px',
    '& *': {
      order: 1,
    },
  },
  type: {
    textTransform: 'lowercase',
    order: 0,
    fontSize: {
      xs: '14px',
      md: '18px',
    },
    marginTop: {
      md: '16px',
    },
  },
  title: {
    color: 'kit.text.h',
    marginBottom: '32px',
    fontSize: {
      xs: '22px',
      md: '60px',
    },
    lineHeight: {
      xs: '26px',
      md: '72px',
    },
    fontWeight: 700,
  },
  title_dark: {
    color: 'kit.text.accent',
  },
  infoItemWrapper: {
    marginBottom: '16px',
    '&:last-child': {
      marginBottom: {
        xs: '40px',
        md: '16px',
      },
    },
  },

  form: {
    display: 'flex',
    alignItems: 'center',
    minHeight: '525px',
    marginRight: {
      xs: '0',
      md: '40px',
    },
    width: {
      xs: 'calc(100% + 16px*2)',
      sm: '100%',
    },
    maxWidth: {
      sm: '439px',
    },
    marginLeft: {
      xs: '-16px',
      sm: 0,
    },
    backgroundColor: 'kit.background.gray',
    padding: {
      xs: '0 16px 34px 16px',
      md: '12px 48px 48px 48px',
    },
    borderRadius: '16px',
    h2: {
      marginBottom: '24px',
    },
    button: {
      paddingTop: '16px',
      paddingBottom: '16px',
      paddingLeft: '32px',
      paddingRight: '32px',
      borderRadius: '20px',
      fontSize: '16px',
    },
  },
};

export default styles;
