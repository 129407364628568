const styles = {
  container: {
    padding: {
      xs: '22px 20px 32px 20px',
      lg: '28px 22px 40px 22px',
    },
    boxShadow: '0px 36px 56px rgba(61, 33, 136, 0.04)',
    borderRadius: {
      xs: '16px',
      lg: '24px',
    },
  },
  top: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: {
      xs: '22px',
      lg: '38px',
    },
  },
  info: {
    paddingLeft: {
      xs: '21px',
      lg: '28px',
    },
  },
  name: {
    marginBottom: '12px',
    color: 'kit.text.main',
  },
  title: {
    color: 'kit.text.black',
    opacity: '0.6',
  },
  avatar: {
    width: {
      xs: '114px',
      lg: '152px',
    },
    height: {
      xs: '114px',
      lg: '152px',
    },
    borderRadius: '50%',
    overflow: 'hidden',
    img: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  },
};

export default styles;
