import React from 'react';
import Section from '../../../shared/Section/Section';
import { Grid, Skeleton } from '@mui/material';

export default function EventSkeleton() {
  return (
    <Section>
      <Grid container spacing={1}>
        <Grid item xs={12} lg={7.5}>
          <Skeleton width={90} height={40} />
          <Skeleton height={240} />
          <Skeleton height={240} />
        </Grid>
        <Grid item xs={12} lg={4.5}>
          <Skeleton
            height={540}
            sx={{ transform: { xs: 1, lg: 'none' }, ml: { xs: 0, lg: 3 } }}
          />
        </Grid>
        <Grid item xs={12}>
          <Skeleton height={240} />
        </Grid>
      </Grid>
    </Section>
  );
}
