export const styles = {
  root: (isAnswer) => ({
    display: 'flex',
    flexDirection: isAnswer ? 'row-reverse' : 'row',
    my: { xs: 0, lg: 3 },
    pt: {
      xs: 0,
      lg: isAnswer ? 5 : 0,
    },
  }),
  message: (isAnswer) => ({
    maxWidth: { xs: isAnswer ? 256 : 'auto', sm: isAnswer ? 317 : 'auto' },
    ml: { xs: 0, lg: isAnswer ? 4 : 1 },
    my: 1,
    backgroundColor: isAnswer ? 'kit.background.purple' : '#dedede',
    borderRadius: {
      xs: isAnswer
        ? '25.3846px 25.3846px 4.23077px 25.3846px'
        : '25.3846px 25.3846px 25.3846px 4.23077px',
      lg: isAnswer ? '30px 30px 5px 30px' : '30px 30px 30px 5px',
    },

    p: 3,
    py: { xs: 1.5, lg: 3 },
    height: 'fit-content',
  }),
  messageText: {
    fontSize: { sm: 16 },
  },
};
