import { Box, Typography } from '@mui/material';
import React from 'react';

const eventTypes = {
  MEETING: {
    title: 'день открытых дверей',
    color: 'kit.text.h',
    backgroundColor: 'kit.accent',
  },
  MASTER_KLASS: {
    title: 'мастер-класс',
    color: 'kit.text.h',
    backgroundColor: 'kit.background.turquoise',
  },
  PRESENTATION_OF_PROJECTS: {
    title: 'презентация проектов',
    color: 'kit.text.mainInverse',
    backgroundColor: 'kit.background.purple',
  },
};
export default function EventTypeLabel({ type }) {
  const { title, ...styles } = eventTypes[type] || {};
  return (
    <Box
      sx={{
        position: 'absolute',
        top: 16,
        left: 0,
        p: (t) => t.spacing(0.5, 1.25, 0.5, 2.5),
        borderRadius: (t) => t.spacing(0, 0.5, 0.5, 0),
        ...styles,
      }}
    >
      <Typography variant="desktopP3Helper">{title}</Typography>
    </Box>
  );
}
