import React, { useState } from 'react';
import ModalForm from '../ModalBlock/ModalForm/ModalForm';
import ContactsForm from '../Forms/SpecificForms/ContactsForm';

function ContactsModal({
  leadData,
  formData,
  buttonLabel,
  ymSubmitEventTypes = [],
  myTargetMetric,
}) {
  const [isFormSent, setIsFormSent] = useState(false);
  const [isError, setIsError] = useState(false);

  return (
    <ModalForm {...formData} isSent={isFormSent} isError={isError}>
      <ContactsForm
        setIsSent={setIsFormSent}
        setIsError={setIsError}
        extraLeadData={leadData}
        buttonLabel={buttonLabel}
        ymSubmitEventTypes={ymSubmitEventTypes}
        myTargetMetric={myTargetMetric}
      />
    </ModalForm>
  );
}

export default ContactsModal;
