import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';
import PlatesListOffline from '../../shared/PlatesList/PlatesListOffline';
import ContactsModal from '../../shared/Modals/ContactsModal';
import { Section } from '../../shared';
import { EventStatisticModule } from '../../features/EventStatistic/ui/EventStatistic';
import { SubscribeModule } from '../Subscribe/Subscribe';
import { EventMkSubscribeModule } from '../EventMkOnlineSubscribe/ui/EventMkOnlineSubscribe';
import { CanBeInterestModule } from '../CanBeInterest/ui/CanBeInterest';
import SuggestEventCard from '../../shared/SuggestEventCard/SuggestEventCard';
import Markdown from '../../shared/Markdown/Markdown';
import { getProgram } from '../../helpers/lib';
import { getMetrik } from '../../pages-fsd/EventsPage/model/metriks';

const formData = {
  title: 'Запись на мероприятие',
  sentContent: {
    title: 'Вы успешно записались на мероприятие!',
    message: 'скоро с вами свяжется куратор',
  },
};

export default function MkOfflineSlidingGroup({
  outsideRef,
  eventData,
  data,
  greetingsImgs,
  statistic,
  leadData,
  events,
  setCurrentEvent,
  commonLeadData,
}) {
  const isSm = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const { subTitleEnum, titleEnum, mainSection, city } = eventData || {};
  const isAdvanced = titleEnum.includes('ADVANCED');
  const program = getProgram(titleEnum);
  const {
    requestLeadData,
    beginners: { [program]: beginners },
    advanced: { [program]: advanced },
  } = data?.mkOfflineIntroduce || {};
  const ymEvent = getMetrik(program, subTitleEnum, city, 'FlyMod');
  return (
    <Section>
      <Grid container>
        <Grid item xs={12} lg={7}>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
            }}
          >
            <Typography
              variant="desktopH2"
              color={'kit.text.main'}
              component={'div'}
            >
              Что будет на занятии
            </Typography>
            <Box sx={{ mt: { xs: 1, lg: 2 }, width: '100%' }}>
              <Markdown>{mainSection}</Markdown>
            </Box>
            {isSm && (
              <GatsbyImage
                style={{ marginTop: 24 }}
                image={getImage(
                  greetingsImgs['greetings/mk-offline-introduce-mobile'],
                )}
                alt="offline mk introduce"
              />
            )}
            <Box
              sx={{
                width: { xs: '100%', lg: 872 },
                mt: { xs: 2, lg: 4 },
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
              }}
            >
              <PlatesListOffline
                list={isAdvanced ? advanced.list : beginners.list}
              />
            </Box>
          </Box>
          <Box mt={{ xs: 6.25, lg: 17.5 }}>
            <EventStatisticModule {...statistic} description="" />
          </Box>

          {isSm && (
            <Box mt={{ xs: 2, lg: 5 }}>
              <EventMkSubscribeModule
                outsideRef={outsideRef}
                {...eventData}
                showSubscribe
                eventData={eventData}
              />
            </Box>
          )}
          {!isSm && (
            <Box mt={{ xs: 6.25, lg: 17.5 }}>
              <Typography variant="desktopH2" color={'kit.text.main'}>
                {data.canBeInterest.title}
              </Typography>

              <Grid container columnSpacing={3} mt={4}>
                <Grid item xs={6} sx={{}}>
                  {events[0] && (
                    <SuggestEventCard
                      {...events[0]}
                      clickHandler={() => setCurrentEvent(events[0])}
                    />
                  )}
                </Grid>
                <Grid item xs={6} sx={{}}>
                  {events[1] && (
                    <SuggestEventCard
                      {...events[1]}
                      clickHandler={() => setCurrentEvent(events[1])}
                    />
                  )}
                </Grid>
              </Grid>
            </Box>
          )}
          {isSm && (
            <Box mt={{ xs: 6.25, lg: 17.5 }}>
              <CanBeInterestModule
                leadData={data.canBeInterest.leadData}
                title={data.canBeInterest.title}
                description={data.canBeInterest.description}
                events={events}
              />
            </Box>
          )}
          {!isSm && (
            <Box mt={{ xs: 2, lg: 17.5 }}>
              <SubscribeModule
                leadData={leadData.subscribe}
                ymSubmitEventType={`event_emailEvents_${eventData.urlSlug}`}
              />
            </Box>
          )}
        </Grid>
        {!isSm && (
          <Grid item xs={12} lg={4} sx={{ position: 'relative', ml: 'auto' }}>
            <Box
              sx={{
                ml: 'auto',
                position: 'sticky',
                top: 68,
                backgroundColor: 'kit.background.gray',
                p: 4,
                mx: 1,
                borderRadius: 4,
              }}
            >
              <ContactsModal
                leadData={{
                  ...commonLeadData,
                  titleEnum,
                  subTitleEnum,
                }}
                formData={formData}
                buttonLabel="Зарегистрироваться"
                ymSubmitEventTypes={[ymEvent]}
                ym
              />
            </Box>
          </Grid>
        )}
      </Grid>
    </Section>
  );
}
