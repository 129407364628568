import React, { useEffect, useState } from 'react';
import { Box, Grid, useMediaQuery } from '@mui/material';

import { usePreviewsQuery } from '../../../app/queries/previews.query';

import EventReviews from '../../../shared/EventReviews/EventReviews';
import Section from '../../../shared/Section/Section';

import EventIntroduce from '../../../widgets/EventIntroduce/EventIntroduce';
import Motivate from '../../../widgets/Motivate/Motivate';
import RunningLine from '../../../widgets/RunningLine/RunningLine';
import EventSpeakersBlock from '../../../widgets/EventSpeakersBlock/EventSpeakersBlock';
import { ProvideInformation } from '../../../widgets/ProvideInformation/ProvideInformation';
import { useEcbWorksQuery } from '../../../app/queries/howecbworks.query';
import Statistic from '../../../shared/Statistic/Statistic';
import HowDoesBootcampWork from '../../../widgets/HowDoesBootcampWork';
import Subscribe from '../../../widgets/Subscribe/Subscribe';
import Questions from '../../../widgets/Questions/Questions';

function SpecificEvent({
  eventData,
  extraLeadData,
  ymSubmitEventTypesExtended,
  greetingsImgs,
  motivate,
  reviews,
  youWillKnow,
  requestLeadData,
  stats,
  myTargetMetrics,
  questionsData,
  leadData,
}) {
  const isSm = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const { ecbWorksImgs } = useEcbWorksQuery();
  const { previewsImg } = usePreviewsQuery();

  const [youWillKnowData, setYouWillKnowData] = useState(youWillKnow);
  const {
    title,
    subTitle,
    titleEnum,
    city,
    subTitleEnum,
    amoCity,
    eventType,
    date,
    startTime,
    duration,
    fullLocation,
    speakers,
    location,
    tags,
    mainSection,
  } = eventData;

  useEffect(() => {
    if (mainSection) {
      const [rawTitle] = mainSection.split('\n');
      const blockTitle = rawTitle.slice(1).trim();
      const list = mainSection
        .split('\n')
        .join('')
        .split('q. ')
        .reduce((acc, el) => {
          const [q, a] = el.split('a. ');
          return q && a
            ? [
                ...acc,
                {
                  period: q,
                  description: a,
                  width: { sx: '100%', md: '312px' },
                  backgroundColor: 'kit.background.lightpurple',
                  withCircleIcon: true,
                },
              ]
            : acc;
        }, []);

      setYouWillKnowData((prevState) => ({ ...prevState, blockTitle, list }));
    }
  }, [mainSection]);

  const getImagePath = () => {
    const path = 'greetings/event';

    if (
      `${path}-${city?.toLowerCase()}-${titleEnum?.toLowerCase()}` in
      greetingsImgs
    ) {
      return `${path}-${city?.toLowerCase()}-${titleEnum?.toLowerCase()}`;
    }
    if (`${path}-${city?.toLowerCase()}` in greetingsImgs) {
      return `${path}-${city?.toLowerCase()}`;
    }
    if (`${path}-${city?.toLowerCase()}` in greetingsImgs) {
      return `${path}-${city?.toLowerCase()}`;
    }
    return path;
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Box key="infoTop" sx={{ paddingTop: '12px' }}>
        <EventIntroduce
          type={eventType}
          title={title}
          subTitle={subTitle}
          titleEnum={titleEnum}
          subTitleEnum={subTitleEnum}
          city={city}
          amoCity={amoCity}
          date={date}
          startTime={startTime}
          duration={duration}
          fullLocation={fullLocation}
          location={location}
          tags={tags}
          image={
            isSm
              ? greetingsImgs[`${getImagePath()}-mobile`]
              : greetingsImgs[`${getImagePath()}`]
          }
          eventData={eventData}
          extraLeadData={{
            ...extraLeadData,
            ...requestLeadData,
            leadBlock: 'Мероприятие топ',
            leadPage: 'Мероприятие',
            event: eventType,
          }}
          ymSubmitEventTypes={[...ymSubmitEventTypesExtended, 'eventTopForm']}
          myTargetEvent={myTargetMetrics['TopFormSent']}
        />
      </Box>
      <EventSpeakersBlock speakers={speakers} />
      <HowDoesBootcampWork
        title="Что будет на встрече?"
        images={ecbWorksImgs}
        image="howBootcampWorks/2hours"
        pointsLists={[
          {
            title: 'Общение с выпускником буткемпа:',
            list: [
              'Детали обучения в буткемпе: почему формат подходит не всем',
              'Его личный путь трудоустройства в IT с нуля',
              'Тренды IT-сферы: карьера, зарплаты, компании',
              'Всё, что вас интересует в формате Q/A с действующим программистом крупной компании',
            ],
          },
          {
            title: 'Куратор Эльбруса расскажет:',
            list: [
              'Про формат буткемпа и подробную программу обучения',
              'О поступлении в буткемп и техническом интервью: материалы, задачи',
              'О преподавателях, кураторах, одногруппниках',
              'Статистику исследования эффективности буткемпа: реальные цифры независимых экспертов',
            ],
          },
        ]}
      />
      <Section>
        <Grid container>
          <Grid item xs={12} xl={9}>
            <Statistic statistic={stats} />
          </Grid>
        </Grid>
      </Section>
      <ProvideInformation
        {...youWillKnowData}
        title={title}
        date={date}
        startTime={startTime}
        location={location}
        amoCity={amoCity}
        tags={tags}
        extraLeadData={{
          ...extraLeadData,
          ...requestLeadData,
          leadBlock: 'Структура программы',
          leadPage: 'Мероприятие',
          event: eventType,
        }}
        eventType={eventType}
        ymSubmitEventTypes={[...ymSubmitEventTypesExtended, 'eventMiddleForm']}
        ymSpecialEventType="eventsRegistrationDod"
        myTargetEventTypes={[myTargetMetrics['AboutFormSent']]}
      />
      {titleEnum !== 'DOD_SPEC' && <Motivate motivate={motivate} />}
      {titleEnum !== 'DOD_SPEC' && (
        <RunningLine text="количество мест ограничено /" />
      )}
      {titleEnum !== 'DOD_SPEC' && (
        <EventReviews reviews={reviews} previewsImg={previewsImg} />
      )}
      <Box key="infoBottom" sx={{ width: '100%', overflowX: 'hidden' }}>
        <EventIntroduce
          showSubscribe
          bg="event"
          theme="dark"
          type={eventType}
          title={title}
          titleEnum={titleEnum}
          subTitleEnum={subTitleEnum}
          city={city}
          date={date}
          startTime={startTime}
          duration={duration}
          fullLocation={fullLocation}
          location={location}
          tags={tags}
          image={greetingsImgs['greetings/event-phone']}
          eventData={eventData}
          extraLeadData={{
            ...extraLeadData,
            ...requestLeadData,
            leadBlock: 'Мероприятие боттом',
            leadPage: 'Мероприятие',
            event: eventType,
          }}
          ymSubmitEventTypes={[
            ...ymSubmitEventTypesExtended,
            'eventBottomForm',
          ]}
          myTargetEvent={myTargetMetrics['SignUpFormSent']}
        />
      </Box>
      <Subscribe
        leadData={leadData.subscribe}
        ymSubmitEventType={`event_emailEvents_${eventData.urlSlug}`}
        myTargetEvent={myTargetMetrics['']}
      />
      <Questions
        {...questionsData}
        extraLeadData={leadData.questions}
        ymEvents={{ consultationFormSubmit: 'eventBottomConsultation' }}
        myTargetEvents={{
          mainProgrammsBottomClick: myTargetMetrics['BottomProgram'],
          consultationFormSubmit: myTargetMetrics['BottomConsultation'],
        }}
      />
    </Box>
  );
}

export default SpecificEvent;
