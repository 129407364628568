const styles = {
  runningLineText: {
    fontFamily: 'RF Rostin',
    backgroundColor: 'kit.background.purple',
    color: '#FFF',
    fontWeight: '700',
    fontSize: '34px',
    textTransform: 'uppercase',
    marginLeft: '-1px',
    padding: '5px 0 11px',
    lineHeight: '130%',
    letterSpacing: '-0.03em',
    wordSpacing: '2px',
  },
};

export default styles;
