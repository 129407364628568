import React, { useState, useEffect } from 'react';
import { Router } from '@reach/router';
import { Box } from '@mui/material';
import Layout from '../shared/Layout/Layout';
import AllEvents from '../pages-fsd/EventsPage/ui/AllEvents';
import { getAllEvents, getStats } from '../api/strapi';
import EventDetail from '../pages-fsd/EventsPage/ui/EventDetail';
import HeadMaker from '../HeadMaker';
import data from '../pages-fsd/EventsPage/model/events.data';
import { useGreetingsQuery } from '../app/queries/greetings.query';

const mergeSEOData = (eventSEO, dataSEO) => {
  const { _id, meta, ...seo } = eventSEO;
  const eventMeta = meta.map(({ _id, ...metaData }) => ({ ...metaData }));
  dataSEO?.meta?.forEach(({ property: dataProperty, ...rest }) => {
    const eventHasProperty = eventMeta.some(
      ({ property: eventProperty }) => eventProperty === dataProperty,
    );
    if (!eventHasProperty) {
      eventMeta.push({ property: dataProperty, ...rest });
    }
  });

  return {
    meta: eventMeta,
    ...seo,
  };
};

const extractSEOForPage = (props) => {
  const {
    location: { pathname },
    pageContext: { events } = {},
  } = props;

  if (Array.isArray(events) && events.length) {
    const pathnameSlug = pathname.replace('/events/', '');
    const event = events.find(
      ({ url_slug: urlSlug }) => `${urlSlug}/` === pathnameSlug,
    );

    if (event && event.seo) {
      const { id, ...eventSeo } = event.seo;
      return mergeSEOData(eventSeo, data.seo);
    }
  }
  return data.seo;
};

export function Head(props) {
  const seo = extractSEOForPage(props);
  return <HeadMaker {...props} seo={seo} />;
}

const styles = {
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  '&>div': {
    width: '100%',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
};

const mainData = {
  leadPage: 'Бесплатные мероприятия',
};

const leadData = {
  question: {
    ...mainData,
    eventName: 'Оставьте ваши контакты для связи',
    leadAction: 'Получить консультацию',
    leadBlock: 'Остались вопросы',
  },
  subscribe: {
    ...mainData,
    eventName: 'Анонсы событий',
    leadAction: 'Подписка новости',
    leadBlock: 'Анонсы событий',
  },
  events: {
    ...mainData,
    leadAction: 'Регистрация на мероприятие',
    leadBlock: 'Бесплатные мероприятия',
  },
};

function EventsPage() {
  const [events, setEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [stats, setStats] = useState([]);
  const { greetingsImgs } = useGreetingsQuery();

  useEffect(() => {
    const ac = new AbortController();
    const getData = async () => {
      setIsLoading(true);
      try {
        const events = await getAllEvents(ac);
        setEvents(events);
        const allStats = await getStats(ac);
        setStats(() => {
          const statsList =
            allStats.length > 4 ? allStats.slice(0, 4) : allStats;
          return statsList.map((item) => ({
            id: item.id,
            value: item.statTitle,
            description: item.statSubTitle,
          }));
        });
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    };
    getData();
    return () => {
      ac.abort();
    };
  }, []);

  return (
    <Layout leadPage="Events">
      <Box sx={styles}>
        <Router>
          <EventDetail
            path="events/:id"
            stats={stats}
            greetingsImgs={greetingsImgs}
            requestLeadData={leadData.events}
            events={events}
            {...data}
          />
          <AllEvents
            path="/events"
            events={events}
            requestLeadData={leadData.events}
            titleH1
            isLoading={isLoading}
          />
        </Router>
      </Box>
    </Layout>
  );
}

export default EventsPage;
