import React from 'react';
import { Box, Grid } from '@mui/material';
import { oneEvent } from '../../../app/constants/urls';
import Description from '../../../widgets/Description';
import Section from '../../../shared/Section/Section';
import StepsMd from '../../../shared/markdownComponents/StepsMd/StepsMd';
import CareerDescriptionMd from '../../../shared/markdownComponents/CareerDescriptionMd/CareerDescriptionMd';
import Speakers from '../../../widgets/Speakers/Speakers';
import Statistic from '../../../shared/Statistic/Statistic';
import EventInfoBlock from '../../../widgets/EventInfo/EventInfo';
import Subscribe from '../../../widgets/Subscribe/Subscribe';
import Questions from '../../../widgets/Questions/Questions';

export default function BasicEvent({
  loading,
  eventData,
  extraLeadData,
  leadData,
  ymSubmitEventTypesExtended,
  myTargetMetrics,
  stats,
  questionsData,
  descriptionData,
}) {
  const {
    title,
    titleEnum,
    subTitleEnum,
    city,
    speakers,
    mainSection,
    description,
    img,
    eventType,
    date,
    startTime,
    duration,
    fullLocation,
    location,
    tags,
    speakersList,
  } = eventData || {};
  return (
    <Box>
      <Box key="infoTop">
        <EventInfoBlock
          bg="prepare"
          loading={loading}
          type={eventType}
          title={title}
          titleEnum={titleEnum}
          subTitleEnum={subTitleEnum}
          city={city}
          date={date}
          startTime={startTime}
          duration={duration}
          fullLocation={fullLocation}
          location={location}
          tags={tags}
          customStyles={{ backgroundColor: 'kit.background.white' }}
          eventData={eventData}
          extraLeadData={{
            ...extraLeadData,
            leadBlock: 'Мероприятие топ',
            event: eventType,
          }}
          myTargetMetric={myTargetMetrics['TopFormSent']}
          ymSubmitEventTypes={[...ymSubmitEventTypesExtended, 'eventTopForm']}
          {...eventData}
        />
      </Box>
      <Box key="description">
        <Section bg="description">
          {description && (
            <CareerDescriptionMd markdown={description} image={img} />
          )}
          {mainSection && (
            <Box
              sx={{
                marginTop: {
                  xs: '48px',
                  lg: '112px',
                },
              }}
            >
              <StepsMd markdown={mainSection} />
            </Box>
          )}
        </Section>
      </Box>
      {!!speakersList?.length && (
        <Section theme="medium" bg="speakers">
          <Speakers list={speakersList} />
        </Section>
      )}
      <Box key="about">
        <Description
          {...descriptionData}
          data={{
            ...descriptionData.data,
            myTargetEvent: myTargetMetrics['Interview'],
          }}
        />
      </Box>
      <Section>
        <Grid container>
          <Grid item xs={12} xl={9}>
            <Statistic statistic={stats} />
          </Grid>
        </Grid>
      </Section>
      <Box key="infoBottom">
        <EventInfoBlock
          theme="medium"
          bg="prepare"
          type={eventType}
          title={title}
          titleEnum={titleEnum}
          subTitleEnum={subTitleEnum}
          city={city}
          date={date}
          startTime={startTime}
          duration={duration}
          fullLocation={fullLocation}
          location={location}
          tags={tags}
          eventData={eventData}
          extraLeadData={{
            ...extraLeadData,
            leadBlock: 'Мероприятие боттом',
            event: eventType,
          }}
          ymSubmitEventTypes={[
            ...ymSubmitEventTypesExtended,
            'eventBottomForm',
          ]}
          customStyles={{
            paddingBottom: { xs: '40px  !important', lg: '56px' },
          }}
          myTargetMetric={myTargetMetrics['SignUpFormSent']}
        />
      </Box>
      <Subscribe
        leadData={leadData.subscribe}
        ymSubmitEventType={`event_emailEvents_${eventData.urlSlug}`}
        myTargetEvent={myTargetMetrics['']}
      />
      <Questions
        {...questionsData}
        extraLeadData={leadData.questions}
        ymEvents={{ consultationFormSubmit: 'eventBottomConsultation' }}
        myTargetEvents={{
          mainProgrammsBottomClick: myTargetMetrics['BottomProgram'],
          consultationFormSubmit: myTargetMetrics['BottomConsultation'],
        }}
      />
    </Box>
  );
}
