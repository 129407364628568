import { Box, Card, CardMedia, Grid, Typography } from '@mui/material';
import React from 'react';
import ArrowDownIcon from '../../kit/icons/ArrowDownIcon';
import ArrowIcon from '../../kit/icons/ArrowIcon';
import PointA from '../../kit/icons/pointA';
import PointB from '../../kit/icons/pointB';
import styles from './styles';
import Markdown from '../../shared/Markdown/Markdown';

function EventSpeakerBlock({
  photo,
  name,
  description,
  previousJob,
  currentJob,
  companyLogo,
}) {
  return (
    <Grid item xs={12} lg={10} sx={styles.container}>
      <Card sx={styles.speakerPhoto}>
        <CardMedia
          component="img"
          sx={styles.image}
          image={photo}
          alt={name}
          loading="lazy"
        />
      </Card>
      <Box container sx={styles.speakerInfo}>
        <Typography component="h2" variant="desktopH4" sx={styles.speakerName}>
          {name}
        </Typography>
        <Box sx={styles.speakerDescription}>
          <Markdown>{description}</Markdown>
        </Box>
        {currentJob && (
          <Box sx={styles.speakerJobsContainer}>
            <Box sx={styles.speakerJob}>
              <Box>
                <PointA />
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Box sx={styles.speakerJobHeader}>до Эльбруса</Box>
                <Box sx={styles.speakerJobTitle}>{previousJob}</Box>
              </Box>
            </Box>
            <Box sx={styles.arrowBlock}>
              <ArrowIcon sx={styles.arrow} />
              <ArrowDownIcon sx={styles.arrowDown} />
            </Box>
            <Box sx={styles.speakerJob}>
              <Box>
                <PointB />
              </Box>
              <Box>
                <Box sx={styles.speakerJobHeader}>после Эльбруса</Box>
                <Box sx={styles.speakerJobTitle}>{currentJob}</Box>
                {companyLogo && (
                  <CardMedia
                    component="img"
                    sx={styles.companyLogo}
                    image={companyLogo.url}
                    alt="current company"
                    loading="lazy"
                  />
                )}
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Grid>
  );
}

export default EventSpeakerBlock;
