import React from 'react';
import {
  Box,
  Button,
  Grid,
  Paper,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { Link } from 'gatsby-theme-material-ui';
import Location from '../Location/Location';
import { styles } from './styles';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import MarketingLabel from './MarketingLabel';
import EventTypeLabel from './EventTypeLabel';

export default function EventCard(props) {
  const {
    title,
    location,
    format: eventFormat,
    shortDescription,
    isLoading,
    date,
    startTime,
    url_slug,
    external_url,
    clickHandler,
    startGrid,
    image,
    marketingLabel,
    subTitleEnum,
    program,
  } = props;
  const isMobile = useMediaQuery((t) => t.breakpoints.down('sm'));
  if (isMobile) {
    return (
      <Paper
        elevation={0}
        sx={{
          width: { xs: '100%' },
          height: 424,
          maxWidth: { xs: '100%', sm: 340 },
          p: (t) => t.spacing(2, 2, 4),
          position: 'relative',
          background: styles.backgroundGenerate(program),
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <EventTypeLabel type={subTitleEnum} />
        {marketingLabel && (
          <Box
            sx={{
              position: 'absolute',
              top: 16,
              right: 10,
            }}
          >
            <MarketingLabel label={marketingLabel} />
          </Box>
        )}
        <Grid
          container
          columnGap={3}
          sx={{ width: '100%', mt: 6.5, minHeight: 207 }}
        >
          <Grid
            item
            xs={3.25}
            sx={{
              position: 'relative',
              '&::after': {
                content: '""',
                display: 'flex',
                position: 'absolute',
                height: '100%',
                width: '2px',
                backgroundColor: 'kit.text.h',
                right: '-14px',
                top: '50%',
                transform: 'translate(50%, -50%)',
              },
            }}
          >
            <Box
              sx={{
                width: '100%',
                height: '100%',
                color: 'kit.infographic.darkPurple',
              }}
            >
              <Box
                sx={{
                  maxWidth: '100%',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Typography variant="desktopH4" sx={{ fontSize: 34 }}>
                  {date[1]}
                </Typography>
                <Typography variant="desktopH4" sx={{ fontSize: 18 }}>
                  {date[2]}
                </Typography>
                <Typography variant="desktopP3Helper" mt={1}>
                  {date[0]}
                </Typography>
                <Typography variant="desktopH5" mt={1.5}>
                  {startTime}
                </Typography>
                <Typography variant="desktopP3Helper" mt={0.5}>
                  по Мск
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={7}>
            <Typography
              variant="desktopH4"
              sx={{
                maxWidth: 600,
                fontSize: 24,
                color: 'kit.text.main',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box !important',
                WebkitLineClamp: '3',
                lineClamp: '3',
                maxLines: '3',
                WebkitBoxOrient: 'vertical',
              }}
            >
              {title}
            </Typography>
            <Typography
              variant="desktopP3"
              sx={{
                fontSize: 14,
                mt: 1.5,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box !important',
                WebkitLineClamp: '5',
                lineClamp: '5',
                maxLines: '5',
                WebkitBoxOrient: 'vertical',
              }}
            >
              {shortDescription}
            </Typography>
          </Grid>
        </Grid>

        <Box
          sx={{
            mt: 5,
            width: '100%',
            pl: 0.5,
          }}
        >
          <Location location={location} />
          <Box
            sx={{
              display: 'flex',
              gap: 3.5,
              mt: 2,
              alignItems: 'center',
            }}
          >
            {!!clickHandler && (
              <Button
                variant="kitPrimary"
                size="medium"
                disabled={isLoading}
                onClick={clickHandler}
                sx={{ marginRight: '10px' }}
              >
                записаться
              </Button>
            )}
            <Link
              variant="kitPrimary"
              href={external_url || `/events/${url_slug}/`}
              target={external_url ? '_blank' : '_self'}
              sx={styles.link}
            >
              Подробнее
            </Link>
          </Box>
        </Box>
      </Paper>
    );
  }
  return (
    <Grid
      container
      sx={{
        position: 'relative',
        background: styles.backgroundGenerate(program),
        height: 200,
      }}
    >
      {marketingLabel && (
        <Box
          sx={{
            position: 'absolute',
            top: 16,
            right: 20,
          }}
        >
          <MarketingLabel label={marketingLabel} />
        </Box>
      )}
      <Grid
        item
        xs={12}
        sm={12}
        md={7}
        xl={startGrid}
        sx={{
          position: 'relative',
          display: 'flex',
          '&::after': {
            content: '""',
            display: { xs: 'none', lg: 'flex' },
            position: 'absolute',
            height: 'calc(100% - 2 * 16px)',
            width: '2px',
            backgroundColor: 'kit.text.h',
            right: '22px',
            top: '50%',
            transform: 'translate(50%, -50%)',
          },
        }}
      >
        <Box sx={{ minWidth: 280, height: 200, overflow: 'hidden' }}>
          <GatsbyImage
            image={getImage(image)}
            alt="event"
            style={{
              width: '100%',
              height: '100%',
            }}
          />
          <EventTypeLabel type={subTitleEnum} />
        </Box>
        <Box
          sx={{
            width: '100%',
            height: '100%',
            py: 3,
            pl: 5,
            color: 'kit.infographic.darkPurple',
          }}
        >
          <Box
            sx={{
              width: 171,
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end',
            }}
          >
            <Typography
              variant="desktopH4"
              sx={{ fontWeight: 700, fontSize: 24 }}
            >
              {date[1]} {date[2]}
            </Typography>
            <Typography variant="desktopP3Helper">{date[0]}</Typography>
            <Typography variant="desktopH5" mt={1.5}>
              {startTime}
            </Typography>
            <Typography variant="desktopP3Helper">по Мск</Typography>
            <Box
              sx={{
                mt: 'auto',
              }}
            >
              <Location location={location} />
            </Box>
          </Box>
        </Box>
      </Grid>

      <Grid item xs={12} sm={12} md={5} xl={11 - startGrid}>
        <Box
          sx={{
            width: '100%',
            height: '100%',
            py: 3,
            pl: 0.5,
          }}
        >
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography
              variant="desktopH4"
              sx={{ maxWidth: 600, fontSize: 24, color: 'kit.text.main' }}
            >
              {title}
            </Typography>
            <Typography
              variant="desktopP3"
              sx={{
                mt: 1,
                color: 'kit.text.main',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box !important',
                WebkitLineClamp: '2',
                lineClamp: '2',
                maxLines: '2',
                WebkitBoxOrient: 'vertical',
              }}
            >
              {shortDescription}
            </Typography>

            <Box
              sx={{
                display: 'flex',
                gap: 0.5,
                mt: 'auto',
                alignItems: 'center',
              }}
            >
              {!!clickHandler && (
                <Button
                  variant="kitPrimary"
                  size="medium"
                  disabled={isLoading}
                  onClick={clickHandler}
                  sx={{ marginRight: '10px' }}
                >
                  записаться
                </Button>
              )}
              <Link
                variant="kitPrimary"
                href={external_url || `/events/${url_slug}/`}
                target={external_url ? '_blank' : '_self'}
                sx={styles.link}
              >
                Подробнее
              </Link>
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
