import React, { useEffect, useState } from 'react';
import { Box, Chip, Grid, Typography, useMediaQuery } from '@mui/material';
import { parseDate, generateTags, generateProgram } from '../../helpers/lib';
import { EVENT_FORMAT_AMO } from '../../app/constants/events';
import styles from './styles';
import EventDetailItem from '../../shared/DetailItem/EventDetailItem';
import { Button } from 'gatsby-theme-material-ui';
import { useInView } from 'react-intersection-observer';
import ModalBlock from '../../shared/ModalBlock/ModalBlock';
import EventRequestModal from '../../shared/Modals/EventRequestModal';
import Section from '../../shared/Section/Section';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import OfflineDetailItem from '../../shared/DetailItem/OfflineDetailItem';
import { getMetrik } from '../../pages-fsd/EventsPage/model/metriks';

const EVENT_LOCATIONS = {
  MSK: 'Москва',
  SPB: 'Санкт−Петербург',
  ONLINE: 'Онлайн',
};

function EventDodOnlineInfoBlock({
  externalHide,
  eventData,
  data,
  greetingsImgs,
}) {
  const {
    city,
    amoCity,
    title,
    date,
    startTime,
    duration,
    location,
    fullLocation,
    subTitleEnum,
    titleEnum,
    tags,
    description,
    format,
    program,
  } = eventData || {};

  const isSm = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [leadData, setLeadData] = useState({});
  const [showButton, setShowButton] = useState(false);
  const [hideBlockRef, hideInView] = useInView({
    threshold: 1,
    delay: 1,
  });
  const ymEvents = getMetrik(program, subTitleEnum, city, 'TopSignUp');
  useEffect(() => {
    setShowButton(!hideInView && !externalHide);
  }, [hideInView, externalHide]);
  useEffect(() => {
    if (date) {
      setStartDate(() => parseDate(date));
      setLeadData((prev) => ({
        ...prev,
        date,
      }));
    }
  }, [date]);
  useEffect(() => {
    setStartDate(() => parseDate(date));
    setLeadData((prev) => ({
      ...prev,
      eventName: title || '',
      program: generateProgram(title),
    }));
  }, [title]);

  useEffect(() => {
    setLeadData((prev) => ({
      ...prev,
      eventName: title,
      tags: generateTags(city, title, tags),
      format: EVENT_FORMAT_AMO[city],
    }));
  }, [city]);

  const applyButton = (
    <Button
      onClick={() => {
        setIsModalOpen(true);
      }}
      variant="kitPrimary"
      size="medium"
      sx={{
        width: { xs: '100%', lg: 'fit-content' },
        maxWidth: 330,
        mx: 'auto',
      }}
    >
      Записаться
    </Button>
  );

  return (
    <Section
      bg={'dod-introduce'}
      theme={'default'}
      customStyles={{
        paddingTop: { xs: 4, lg: 10 },
      }}
    >
      <Grid container color={'kit.text.main'} justifyContent="space-between">
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            maxWidth: {
              xs: '100%',
              md: '688px',
            },
          }}
        >
          <Box sx={styles.top}>
            <Chip size="small" variant="orange" label={EVENT_LOCATIONS[city]} />
            <Chip size="small" variant="orange" label="бесплатно" />
            {format === 'online' && (
              <Chip size="small" variant="orange" label="ЖИВОЕ ОБЩЕНИЕ" />
            )}
          </Box>
          <Typography
            component="h2"
            variant="desktopH1"
            sx={{
              ...styles.title,
              maxWidth: 632,
            }}
          >
            {Array.isArray(title)
              ? title.map((item, index) => (
                  <Typography
                    key={index}
                    component="span"
                    sx={{ display: 'block' }}
                  >
                    {item}
                  </Typography>
                ))
              : title}
          </Typography>
          <Typography
            variant="desktopH4"
            sx={{
              mt: { xs: 1.75, lg: 3 },
              fontFamily: 'RF Rostin',
              fontSize: 18,
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: 'normal',
              letterSpacing: '-1.26px',
              maxWidth: 550,
            }}
          >
            {description}
          </Typography>
          <Box
            sx={{
              mt: { xs: 3, lg: 4 },
              display: 'flex',
              gap: { xs: 1.5, lg: 4 },
              alignItems: 'center',
              flexWrap: 'wrap',
            }}
          >
            <Box sx={styles.infoItemWrapper}>
              {!!startDate && !!startDate[1] && !!startDate[2] && (
                <EventDetailItem
                  icon="calendar"
                  item={`${startDate[1]} ${startDate[2]}`}
                  description={startDate[0]}
                />
              )}
            </Box>

            <Box sx={styles.infoItemWrapper}>
              {!!startTime && !!duration && (
                <EventDetailItem
                  icon="clock"
                  item={startTime}
                  description={duration}
                />
              )}
            </Box>

            <Box sx={styles.infoItemWrapper}>
              {!!location && (
                <OfflineDetailItem
                  icon="location"
                  item={fullLocation.split(',')[0]}
                  description={fullLocation
                    .split(',')
                    .slice(1, Infinity)
                    .join(', ')}
                />
              )}
            </Box>
          </Box>
          <Box ref={hideBlockRef} sx={{ mt: { xs: 4, lg: 6 } }}>
            {applyButton}
          </Box>
        </Grid>
        <Box
          sx={{
            zIndex: 3,
            width: '100%',
            position: 'fixed',
            left: 0,
            bottom: 0,
            p: 1,
            backgroundColor: 'white',
            display: { xs: 'flex', md: 'none' },
            opacity: showButton ? 1 : 0,
            visibility: showButton ? 'visible' : 'hidden',
            transition: 'opacity 0.5s ease-out, visibility 0.15s ease-out',
          }}
        >
          {applyButton}
        </Box>

        {!isSm && (
          <Grid item xs={12} md={6} lg={5}>
            <GatsbyImage
              style={{
                marginLeft: format === 'online' ? -98 : -68,
                marginTop: format === 'online' ? 0 : -48,
              }}
              image={getImage(
                greetingsImgs[`greetings/dod-${format}-introduce`],
              )}
              alt="dod introduce"
            />
          </Grid>
        )}
      </Grid>
      <ModalBlock
        isOpen={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
      >
        <EventRequestModal
          extraLeadData={{
            eventName: title,
            titleEnum,
            subTitleEnum,
            eventLocation: location,
            format: amoCity,
            date,
            tags: Array.isArray(tags) ? [...tags] : [tags],
            subTitleEnum: subTitleEnum,
            titleEnum: titleEnum,
            city: city,
          }}
          location={location}
          startTime={startTime}
          date={parseDate(date)}
          formData={{
            title: 'Запись на мероприятие',
            sentContent: {
              title: 'Вы успешно записались на мероприятие!',
              message:
                'Скоро вам на почту придет ссылка для подключения в Zoom',
            },
            // subtitle: 'EVENT_TYPE[subTitleEnum]',
          }}
          ymSubmitEventTypes={[ymEvents]}
          // ymSpecialEventType={data.ymEvents[currentEvent.filterKey]}
        />
      </ModalBlock>
    </Section>
  );
}

export default EventDodOnlineInfoBlock;
