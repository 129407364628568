const styles = {
  programElementWrapper: (isSm, isLg) => ({
    display: 'flex',
    flexWrap: 'wrap',
    rowGap: '10px',
    gap: isSm ? '6px' : '10px',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginBottom: '48px',
    minHeight: isSm ? 'auto' : '200px',
    '& > *': {
      minHeight: isSm ? 'auto' : '200px',
      width: isLg ? '45%' : '23%',
      marginBottom: '6px',
      ...(isSm && {
        width: '100%',
      }),
    },
  }),
};

export default styles;
