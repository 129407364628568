import { Box, Typography } from '@mui/material';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';
import DodPlates from '../../shared/PlatesList/DodPlates';
import Markdown from '../../shared/Markdown/Markdown';

export default function DodBreaf({
  online,
  isMobile,
  image,
  description,
  data,
}) {
  return (
    <Box>
      <Typography variant="desktopH2" color={'kit.text.main'}>
        Что будет на встрече
      </Typography>
      <Box mt={{ xs: 1, lg: 2 }}>
        <Markdown>{description}</Markdown>
      </Box>
      {isMobile && (
        <GatsbyImage
          style={{ marginTop: 24 }}
          image={getImage(image)}
          alt="offline mk introduce"
        />
      )}
      <Box mt={{ xs: 2, lg: 4 }}>
        <DodPlates topics={data} online={online} />
      </Box>
    </Box>
  );
}
