import { Box } from '@mui/material';
import React from 'react';
import MkDetailItem from '../../../shared/DetailItem/MkDetailItem';
import styles from './styles';

export default function IconsBlock({
  startDate,
  duration,
  startTime,
  location,
  fullLocation,
  city,
}) {
  return (
    <Box sx={styles.iconsBlockWrapper}>
      <Box sx={styles.infoItemWrapper}>
        {!!startDate && !!startDate[1] && !!startDate[2] && (
          <MkDetailItem
            icon="calendar"
            item={`${startDate[1]} ${startDate[2]}`}
            description={startDate[0]}
          />
        )}
      </Box>
      <Box sx={styles.infoItemWrapper}>
        {!!startTime && !!duration && (
          <MkDetailItem icon="clock" item={startTime} description={duration} />
        )}
      </Box>
      {!!location && fullLocation === 'Online' && (
        <Box sx={styles.infoItemWrapper}>
          {!!startTime && !!duration && (
            <MkDetailItem icon="location" item={city} />
          )}
        </Box>
      )}
      <Box sx={styles.infoItemWrapper}>
        {!!location && fullLocation !== 'Online' && (
          <MkDetailItem
            icon="location"
            item={location === 'Online' ? 'Онлайн' : 'Кампус'}
            description={fullLocation}
          />
        )}
      </Box>
    </Box>
  );
}
