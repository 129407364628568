const styles = {
  top: {
    display: {
      xs: 'flex',
      md: 'block',
    },
    lineHeight: {
      md: 0,
    },
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
    marginBottom: '24px',
    '& *': {
      order: 1,
    },
  },
  firstBlock: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: {
      xs: '100%',
      md: '688px',
    },
  },
  subscribeButtonBlock: {
    mt: { xs: 4, lg: 5 },
    display: 'flex',
    justifyContent: {
      xs: 'center',
      sm: 'flex-start',
    },
  },
  subscribeImageBlock: {
    position: 'absolute',
    width: {
      xs: 148,
      md: 206,
    },
    height: {
      xs: 218,
      md: 304,
    },
    top: -40,
    right: {
      xs: -68,
      md: -88,
    },
    zIndex: 1,
  },
  type: {
    textTransform: 'lowercase',
    order: 0,
    fontSize: {
      xs: '14px',
      md: '18px',
    },
    marginTop: {
      md: '16px',
    },
  },
  title: {
    color: 'kit.text.h',
    marginBottom: '24px',
    fontSize: {
      xs: '22px',
      md: '34px',
    },
    lineHeight: {
      xs: '26px',
      md: '42px',
    },
    fontWeight: 400,
  },
  subTitle: {
    lineHeight: {
      xs: '21.6px',
      md: '28.6px',
    },
    marginBottom: '40px',
  },
  title_dark: {
    color: 'kit.text.accent',
  },
  infoItemWrapper: {
    // marginBottom: '16px',
    // '&:last-child': {
    //   marginBottom: {
    //     xs: '40px',
    //     md: '16px',
    //   },
    // },
  },

  form: {
    mt: { xs: 2, lg: 5 },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    marginRight: {
      xs: '0',
      md: '40px',
    },
    width: {
      xs: '100%',
    },
    maxWidth: {
      xs: '100%',
      sm: '439px',
    },
    marginLeft: {
      xs: '-16px',
      sm: 0,
    },
    backgroundColor: 'kit.background.gray',
    padding: {
      xs: '16px 16px 34px 16px',
      md: '22px 48px 48px 48px',
    },
    borderRadius: '16px',
    h2: {
      marginBottom: '24px',
    },
  },
  imageBlock: {
    ml: { xs: 0, md: -5 },
    mt: 4,
  },
  mailInfoBlock: {
    mt: { xs: 1, md: 'auto' },
    backgroundColor: 'white',
    p: '18px',
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    gap: 2,
    width: { xs: '100%', md: 414 },
  },
  wrapper: {
    borderRadius: 8,
    position: 'relative',
    overflow: { xs: 'visible', md: 'hidden' },
  },
  imgContainer: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    mx: -2,
  },
  contentContainer: {
    position: 'relative',
    px: { xs: 0, md: 4 },
    py: { xs: 2, md: 4 },
  },
  iconsBlockWrapper: {
    mt: {
      xs: 2,
      lg: 4,
    },
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: { xs: 'row', lg: 'column' },
    gap: 3,
  },
};

export default styles;
