import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import ModalForm from '../ModalBlock/ModalForm/ModalForm';
import ConsultationForm from '../Forms/SpecificForms/ConsultationForm';
import Dates from '../Dates/Dates';
import Location from '../Location/Location';
import ContactsForm from '../Forms/SpecificForms/ContactsForm';

const styles = {
  dateBlock: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: '24px',
    width: '100%',
    color: 'inherit',
    textAlign: 'left',
    color: 'kit.text.main',
    fontSize: '14px',
    lineHeight: '20px',
    marginTop: '19px',
  },
  wrapper: {},
};

const formDataMain = {
  sentContent: {
    title: 'Вы успешно записались на мероприятие!',
    message: 'Скоро с вами свяжется куратор',
  },
};

function EventRequestModal({
  extraLeadData,
  location,
  startTime,
  date,
  formData,
  ymSpecialEventType,
  ymSubmitEventTypes,
  myTargetEventTypes,
  buttonLabel,
}) {
  const [isFormSent, setIsFormSent] = useState(false);
  const [isError, setIsError] = useState(false);
  return (
    <ModalForm
      {...formDataMain}
      {...formData}
      isSent={isFormSent}
      isError={isError}
    >
      <Box sx={styles.dateBlock}>
        <Box sx={styles.wrapper}>
          <Typography
            component={'div'}
            sx={{
              marginLeft: '-4px',
            }}
          >
            <Location location={location} isLoading={false} />
          </Typography>
          {/* <Typography>{startTime} по Мск</Typography> */}
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '-8px' }}>
          <Typography
            variant="desktopH3"
            sx={{ fontWeight: 400, marginRight: '8px' }}
          >
            {date[1]}
          </Typography>
          <Box>
            <Typography>{date[2]}</Typography>
            {/* <Typography sx={{ opacity: 0.6 }}>{date[0]}</Typography> */}
          </Box>
        </Box>
      </Box>

      <ContactsForm
        setIsSent={setIsFormSent}
        setIsError={setIsError}
        extraLeadData={extraLeadData}
        buttonLabel={buttonLabel}
        ymSpecialEventType={ymSpecialEventType}
        ymSubmitEventTypes={ymSubmitEventTypes}
        myTargetEventTypes={myTargetEventTypes}
      />
    </ModalForm>
  );
}

export default EventRequestModal;
